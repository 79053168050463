import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Loader.style.scss';

class Loader extends Component {

  classes = () => classNames(
    // eslint-disable-next-line react/destructuring-assignment
    this.props.className,
    'segment-spinner',
    {
      // eslint-disable-next-line react/destructuring-assignment
      'segment-spinner--small': this.props.small,
      // eslint-disable-next-line react/destructuring-assignment
      'segment-spinner--bttn': this.props.bttn,
      // eslint-disable-next-line react/destructuring-assignment
      'segment-spinner--disabled': !this.props.active && this.props.disabled
    }
  );

  render() {
    return (
      <span className={this.classes()} />
    );
  }
}

Loader.propTypes = {
  /** Display Active. */
  active: PropTypes.bool,
  /** For Loaders in Button. */
  bttn: PropTypes.bool,
  /** Extend Styles. */
  className: PropTypes.string,
  /** Display Disabled. */
  disabled: PropTypes.bool,
  /** Small Loader. */
  small: PropTypes.bool
};

Loader.defaultProps = {
  active: false,
  bttn: false,
  className: null,
  disabled: true,
  small: false
};

export default Loader;
