import React, { useEffect } from 'react';
import { arrayOf, string, func } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { Button } from '@thd-olt-component-react/button';
import { useStoreId } from '@thd-nucleus/experience-context';
import { useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { dataModel } from './dataModel';
import './compare-tray.scss';

const CompareTray = ({ itemIds, onCompare, onRemove }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('compare.ready'); }, []);
  const storeId = useStoreId();

  const getTrayData = (products) => {
    return itemIds.map((itemId) => {
      let product = {};
      const temp = products.filter((prod) => prod.itemId === itemId);
      if (temp && temp.length) product = temp[0];
      const image = product?.media?.images.filter((img) => img.subType === 'PRIMARY');
      const url = image?.[0]?.url?.replace(/<SIZE>/, 100);
      return { itemId, url };
    });
  };

  const domains = ['media'];
  const { error, loading, data } = useProductDeprecated({
    domains,
    itemIds,
    skip: !(Array.isArray(itemIds) && !!itemIds.length),
    ssr: false
  }, 'products');

  if ((loading && !data) || (error && !data) || !data?.products) return null;

  const trayData = getTrayData(data?.products);

  return (
    <div className="compare-tray compare-tray--sticky" data-component="CompareTray">
      <div className="compare-tray__message">
        <span>Select 2 to 4 items to compare</span>
      </div>
      <div className="compare-tray__images">
        {trayData.map((item, index) => (
          <div className="compare-tray__image" key={`image-${index}`}>
            <div className="compare-tray__remove">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button onClick={() => { onRemove(item.itemId); }} type="button">
                <div className="compare-tray__remove-x" />
              </button>
            </div>
            <Image alt="product" src={item.url} height="1" width="1" stretchy />
          </div>
        ))}
      </div>
      <div className="compare-tray__compare-button" data-testid="compare-btn">
        <Button
          onClick={onCompare}
          dark
          outline
          disabled={itemIds?.length < 2 || itemIds?.length > 4}
        >
          Compare
        </Button>
      </div>
    </div>
  );
};

CompareTray.displayName = 'CompareTray';

CompareTray.propTypes = {
  itemIds: arrayOf(string),
  onCompare: func,
  onRemove: func
};

CompareTray.defaultProps = {
  itemIds: [],
  onCompare: () => {},
  onRemove: () => {}
};

CompareTray.dataModel = dataModel;

export { CompareTray };
