import React, { useState, useEffect } from 'react';
import { string, bool, arrayOf as propArrayOf } from 'prop-types';
import classNames from 'classnames';
import { Alert } from '@thd-olt-component-react/alert';
import { Button, LoadingButton, TextField } from '@one-thd/sui-atomic-components';
import { Formik } from 'formik';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import {
  useDataModel,
  params,
  arrayOf,
  string as stringType,
} from '@thd-nucleus/data-sources';
import { HdhomeSignupPlaceholder } from './HdhomeSignupPlaceholder';
import styles from './email-signup.module.scss';

const PIP_SOURCE_ID = '1381';
const PEP_SOURCE_ID = '1380';

const PRODUCT_COMPONENT_NAME = 'ProductPage';
const PEP_COMPONENT_NAME = 'PepPage';
const BROWSE_COMPONENT_NAME = 'BrowseAndSearchPage';

export const getSourceId = (path) => {
  const namesWithoutIndex = path.map((name) => name.split('[')[0]);

  const index = namesWithoutIndex.findIndex((componentName) => {
    return componentName === PRODUCT_COMPONENT_NAME
      || componentName === PEP_COMPONENT_NAME
      || componentName === BROWSE_COMPONENT_NAME;
  });

  const parentPage = namesWithoutIndex[index];

  switch (parentPage) {
  case (PRODUCT_COMPONENT_NAME): {
    return PIP_SOURCE_ID;
  }
  case (BROWSE_COMPONENT_NAME):
  case (PEP_COMPONENT_NAME): {
    return PEP_SOURCE_ID;
  }
  default: {
    return null;
  }
  }
};

const HdhomeSignupComponent = (props) => {

  const [state, setState] = useState({
    successState: false,
    errorState: false,
    errorValue: ''
  });
  const {
    successState,
    errorState,
    errorValue
  } = state;

  const {
    formSubmission,
    campaignId,
    subscriptionType
  } = props;

  const options = {
    variables: {
      sourceId: campaignId,
      subscriptionType: subscriptionType.length ? subscriptionType : ['Promotions']
    }
  };

  const [submitEmail, preferencesEmailResponse] = useDataModel('preferencesEmail', options);
  const { responseStatus } = preferencesEmailResponse?.data?.preferencesEmail || {};

  useEffect(() => {
    if (responseStatus === 'Success') {
      setState((previousState) => {
        return {
          ...previousState,
          successState: true
        };
      });
    }
    if (responseStatus === 'Error occurred') {
      setState((previousState) => {
        return {
          ...previousState,
          errorState: true,
          errorValue: responseStatus + ', please try again'
        };
      });
    }
  }, [responseStatus]);

  const buttonClass = classNames({
    [styles['email-form__button']]: true
  });

  const title = 'Get 10% off your purchase';
  const linkText = 'Sign Up';
  const link = 'http://pages.homedepot.com/styleanddecor';

  return (
    <Formik
      initialValues={{ email: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Please provide your email address, formatted as you@domain.com.';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Enter a valid email';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          if (values?.email) {
            submitEmail({
              variables: {
                emailId: values?.email,
              }
            });
          }
          setSubmitting(false);
        }, 400);
      }}
    >
      {(formikProps) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        } = formikProps;
        return (
          <div className={styles['email-signup']} data-component="HdhomeSignup">
            <div className={styles['hdhome-catalog-promo__item']}>
              <div className={styles['hdhome-catalog-promo__copy']}>
                <span className={styles['hdhome-catalog-promo__email-icon']} />
                <span className={styles['hdhome-catalog-promo__title-header']}>
                  <h3 className="hdhome-catalog-promo__title">{title}</h3>
                </span>
                <span className={styles['hdhome-catalog-promo__content']}>
                  <p className={styles['hdhome-catalog-promo__item-p']}>
                    <span className={styles['hdhome-catalog-promo__item-span']}>
                      Sign up for emails &amp; save 10% on select home decor.
                    </span>
                    <span className={styles['hdhome-catalog-promo__item-span']}> Valid on select items.</span>
                  </p>
                </span>
              </div>
              <div className={styles['hdhome-catalog-promo__action']}>
                {!formSubmission && (
                  <div className="hdhome-catalog-promo__link">
                    <Button variant="secondary" onClick={(evt) => { window.location = link; }}>{linkText}</Button>
                  </div>
                )}
                {formSubmission && (successState ? (
                  <div className="sui-pt-2 sui-px-3">
                    <Alert
                      type="success"
                      message="Thanks for signing up. A promo code will arrive in your inbox shortly."
                      inline
                    />
                  </div>
                ) : (
                  <form className={styles['email-form']} name="emailPromoBox" onSubmit={handleSubmit}>
                    <span className={styles['email-form__input']}>
                      <TextField
                        id="email"
                        status={errors.email && touched.email && 'error'}
                        placeholder="yourname@email.com"
                        onChange={handleChange}
                        value={values.email}
                        label="Email Address"
                        fullWidth
                      />
                    </span>
                    <span className={buttonClass}>
                      <LoadingButton type="submit" loading={isSubmitting}>Sign Up</LoadingButton>
                    </span>
                    {(errorState || errors.email) && (
                      <div className={styles['email-form__error-message']}>{errorValue || errors.email}</div>
                    )}
                  </form>
                ))}
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

HdhomeSignupComponent.propTypes = {
  campaignId: string,
  subscriptionType: propArrayOf(string),
  formSubmission: bool
};

HdhomeSignupComponent.defaultProps = {
  campaignId: null,
  subscriptionType: [],
  formSubmission: false
};

HdhomeSignupComponent.displayName = 'HdhomeSignup';
HdhomeSignupComponent.dataModel = {
  preferencesEmail: params({
    emailId: stringType().isRequired(),
    sourceId: stringType(),
    subscriptionType: arrayOf(stringType())
  }).mutation().shape({
    responseStatus: stringType().isRequired()
  })
};

const HydratedComponent = withHydrator({
  delay: 2000,
  id: 'hdhome-catalog-hydrator',
  placeholder: (
    <HdhomeSignupPlaceholder
      color="#E0E0E0"
      showLoadingAnimation
    />
  ),
  scrollBuffer: 750,
}, HdhomeSignupComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent);
const HdhomeSignup = withErrorBoundary(DynamicComponent);

export { HdhomeSignup };