import React from 'react';
import PropTypes from 'prop-types';
import { LinkItem } from '@thd-olt-component-react/list';

const isEventBusCreated = () => {
  return typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS;
};

const CategoryItems = ({
  categoryName,
  onClick,
  pageType,
  refinements = [],
  similarProduct
}) => {

  const publish = (opts) => {
    if ((isEventBusCreated())) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('category-list.click', opts);
    }
  };

  const onCategoryClick = (evt, { catName, refinement }) => {
    publish({
      pageType,
      category: catName,
      linkText: refinement.label
    });
    if (onClick) {
      onClick(evt, refinement);
    }
  };

  if (!refinements) {
    return null;
  }

  return refinements.map((refinement, refinementIndex) => {
    const { url, refinementKey = refinementIndex } = refinement;
    return (
      <LinkItem key={`category-${refinementKey}`} className="category-list__subcategory">
        <a
          href={url}
          onClick={(evt) => onCategoryClick(evt, {
            categoryName,
            refinement
          })}
        >
          {similarProduct && (
            <img
              src="https://assets.homedepot-static.com/images/v1/magnify-symbol.svg"
              alt="Search"
              width="1"
              height="1"
            />
          )}
          <span className="category-list__item-label">{refinement.label}</span>
        </a>
      </LinkItem>
    );
  });

};

CategoryItems.displayName = 'CategoryItems';

CategoryItems.propTypes = {
  categoryName: PropTypes.string,
  onClick: PropTypes.func,
  pageType: PropTypes.string,
  refinements: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    recordCount: PropTypes.string,
    refinementKey: PropTypes.string
  })),
  similarProduct: PropTypes.bool
};

CategoryItems.defaultProps = {
  categoryName: null,
  onClick: null,
  pageType: null,
  refinements: null,
  similarProduct: null
};

export { CategoryItems, isEventBusCreated };
