import React, { useContext } from 'react';
import {
  bool,
  func,
  node,
  number,
  string
} from 'prop-types';
import classnames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Loader } from '@thd-olt-component-react/core-ui';
import {
  LoadingButton, LoadingIconButton, Typography, Link
} from '@one-thd/sui-atomic-components';
import { OrderSample } from '@one-thd/sui-icons';

import { getCents, getDollars } from '../helpers';

export const OrderSampleContent = ({
  children,
  handleClick,
  link,
  loading,
  price,
  sampleAvailability,
  showTitle,
  showIconOnly
}) => {
  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';
  const samplesAreAvailable = sampleAvailability === 'Available';
  const buttonText = !samplesAreAvailable ? sampleAvailability : 'Order Sample';
  const textClasses = 'sui-text-sm sui-w-full sui-whitespace-pre-wrap md:sui-text-base';
  const wrapperClasses = classnames('sui-bg-primary sui-gap-2 sui-items-center', {
    'sui-flex sui-flex-wrap sui-justify-between': !link
  });

  const priceElement = () => {
    const subtext = 'Order Sample';
    return (
      <Typography component="span" variant="body-base" truncate>
        {
          price
            ? (
              <div className={textClasses}>
                {subtext + ' for '}
                <strong>
                  {price < 1
                    ? <>{getCents(price)}&cent;</>
                    : <>${getDollars(price)}</>}
                </strong>
              </div>
            )
            : <>{subtext}</>
        }
      </Typography>
    );
  };

  return showIconOnly ? (
    <div>
      <LoadingIconButton
        icon={OrderSample}
        aria-label="Order sample"
        onClick={handleClick}
        buttonSize="relaxed"
        iconSize="regular"
        disabled={!samplesAreAvailable}
        loading={loading}
        role="button"
        name="order-sample-button"
      />
    </div>
  )
    : (

      <div className={wrapperClasses}>
        <div className="sui-flex sui-flex-row sui-gap-2">
          <div className="sui-self-center">
            <OrderSample size={isMobile ? 'regular' : 'large'} />
          </div>
          <div className="sui-flex sui-flex-col sui-justify-center">
            {showTitle && (<Typography variant="h4" truncate>{children}</Typography>)}
            {samplesAreAvailable
              && (
                !link
                  ? priceElement()
                  : (
                    // eslint-disable-next-line
                    <Link
                      align="left"
                      component="button"
                      onClick={handleClick}
                    >
                      {
                        loading
                          ? <Loader active small />
                          : (priceElement())
                      }
                    </Link>
                  )
              )}
            {!samplesAreAvailable && link
              && (
                <div className={textClasses}>
                  Sample is {sampleAvailability}
                </div>
              )}
          </div>
        </div>
        {!link && (
          <LoadingButton
            variant="secondary"
            disabled={!samplesAreAvailable}
            onClick={handleClick}
            loading={loading}
          >
            {buttonText}
          </LoadingButton>
        )}
      </div>
    );
};

OrderSampleContent.displayName = 'OrderSampleContent';

OrderSampleContent.propTypes = {
  children: node.isRequired,
  handleClick: func.isRequired,
  link: bool,
  loading: bool,
  price: number,
  sampleAvailability: string,
  showTitle: bool.isRequired,
  showIconOnly: bool
};

OrderSampleContent.defaultProps = {
  link: false,
  loading: false,
  price: NaN,
  sampleAvailability: 'Available',
  showIconOnly: false
};