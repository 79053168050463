import React from 'react';

const CONTEXT_KEY = Symbol.for('@thd-olt-component-react/product-pod-group/Context');
const globalSymbols = Object.getOwnPropertySymbols(global);
const hasContext = (globalSymbols.indexOf(CONTEXT_KEY) > -1);

/* istanbul ignore next */
if (!hasContext) {
  global[CONTEXT_KEY] = React.createContext({
    handleProductPodExpansion: null,
    setOverlayState: null
  });
}

export const ProductPodGroupContext = global[CONTEXT_KEY];
