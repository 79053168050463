import React, { useEffect, useState } from 'react';
import {
 arrayOf, shape, string, bool, func, number
} from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { LifestyleImageWithHotspot } from './subcomponents/LifestyleImageWithHotspot';
import { HotspotDrawer } from './subcomponents/HotspotDrawer';
import { HOTSPOT_TYPE } from '../constants';
import { triggerAnalytics } from '../helpers';
import './product-hotspots.scss';

const MediaHotspots = ({ hotspots, imageUrl, openOverlay, onHotspotImageClick, mediaGalleryIndex }) => {
  const [openFirstTag, setOpenFirstTag] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [open, setOpen] = useState(false);

  const onShowFirstTagProduct = () => {
    setOpenFirstTag(true);
  };

  const toggleDrawer = (drawerOpen, index) => {
    setCurrentIndex(index);
    setOpen(drawerOpen);
    if (index === null) {
      setOpenFirstTag(false);
    }
    triggerAnalytics('product-hotspots.click');
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (LIFE_CYCLE_EVENT_BUS) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-hotspots.ready');
    }
  }, []);

  useEffect(() => {
    if (openFirstTag) {
      setCurrentIndex(0);
      setOpen(true);
    }
  }, [openFirstTag]);

  return (
    <>
      <LifestyleImageWithHotspot
        hotspots={hotspots}
        imageUrl={imageUrl}
        tagIndex={currentIndex}
        imgOnclick={() => {
          /* istanbul ignore else */
          if (openOverlay && onHotspotImageClick) {
            onHotspotImageClick();
          }
        }}
        onClickHandler={toggleDrawer}
        mediaGalleryIndex={mediaGalleryIndex}
        type={HOTSPOT_TYPE.PIP_HOTSPOTS}
        onShowFirstTagProduct={onShowFirstTagProduct}
      />
      {
        open && currentIndex >= 0 && hotspots[currentIndex] && (
          <HotspotDrawer
            hotspots={hotspots}
            imageUrl={imageUrl}
            open={open}
            mediaGalleryIndex={mediaGalleryIndex}
            currentIndex={currentIndex}
            onCloseDrawer={toggleDrawer}
            setCurrentIndex={setCurrentIndex}
          />
        )
      }
    </>

  );
};

MediaHotspots.displayName = 'MediaHotspots';

MediaHotspots.dataModel = extend(HotspotDrawer);

MediaHotspots.propTypes = {
  hotspots: arrayOf(shape({
    coordinate: shape({
      xCoordinate: string,
      yCoordinate: string
    }),
    omsIDs: arrayOf(string)
  })).isRequired,
  imageUrl: string.isRequired,
  openOverlay: bool,
  onHotspotImageClick: func,
  mediaGalleryIndex: number
};

MediaHotspots.defaultProps = {
  openOverlay: false,
  onHotspotImageClick: null,
  mediaGalleryIndex: 0
};

export { MediaHotspots };
