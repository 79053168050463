import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { ProductResultsContext } from './ProductResultsContext';
import { getBrowseSearchParam, updateQueryParams, getRatedValue } from '../product-results-helpers';
import '../styles/results-header.scss';

const ResultsHeader = ({ preamble, showShopAllLink, resultsRef }) => {
  const { data } = useContext(ProductResultsContext);

  if (!data) return null;

  const { metadata, searchReport, taxonomy } = data?.searchModel || {};
  const { contentType, h1Tag } = metadata || {};

  if (contentType !== 'categorypage' && (!searchReport || Object.keys(searchReport).length === 0)) {
    return null;
  }

  const { keyword, correctedKeyword, searchUrl } = searchReport || {};
  const keywordTerm = correctedKeyword || keyword;

  const { breadCrumbs = [] } = taxonomy || {};

  const bestRated = getRatedValue(breadCrumbs, '5');

  const highlyRated = getRatedValue(breadCrumbs, '4 & Up');

  const resultsKeyword = h1Tag || keywordTerm || getBrowseSearchParam(breadCrumbs);

  let skipSpellCheckUrl;

  if (correctedKeyword && searchUrl) {
    skipSpellCheckUrl = updateQueryParams(searchUrl,
      [{ paramName: 'skipSpellCheck', paramValue: 'true' }]);
  }

  const onShopAllClick = (event) => {
    event.preventDefault();
    let scrollUpto = resultsRef?.current?.parentElement?.previousSibling?.getBoundingClientRect().top;
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollUpto) {
      const headerEle = document.querySelector('.Header3-sticky-desktop');
      scrollUpto -= headerEle ? headerEle.clientHeight : 0;
      window.scroll({
        top: scrollUpto + scroll,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <div className="results-header" data-component="ResultsHeader">
        <div className="results-header__wrapper">
          {preamble && (
            <Typography variant="h1" weight="display" color="primary" uppercase height="none">
              {preamble}&nbsp;
            </Typography>
          )}
          <Typography variant="h1" weight="display" color="primary" uppercase height="none">
            {resultsKeyword}
          </Typography>
          {showShopAllLink && (
            <button type="button" className="results-header__shop-all" onClick={onShopAllClick}>Shop All</button>
          )}
        </div>
      </div>
      {bestRated && (
        <div className="results-header__best-rated-text">
          Shop products with the best ratings based on our customer reviews
        </div>
      )}
      {highlyRated && (
        <div className="results-header__top-rated-text">
          Shop highly rated products based on our customer reviews
        </div>
      )}
      {correctedKeyword && (
        <div className="results-header__autocorrected-title">
          Search Instead for
          <a href={skipSpellCheckUrl} className="results-header__autocorrected-link">
            {`  "${keyword}"`}
          </a>
        </div>
      )}
    </>
  );
};

ResultsHeader.defaultProps = {
  preamble: null,
  showShopAllLink: false,
  resultsRef: {}
};

ResultsHeader.propTypes = {
  preamble: PropTypes.node,
  showShopAllLink: PropTypes.bool,
  resultsRef: PropTypes.shape({
    current: PropTypes.node
  })
};

ResultsHeader.displayName = 'ResultsHeader';

export { ResultsHeader };
