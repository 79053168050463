import React from 'react';
import PropTypes from 'prop-types';
import { UnstyledList } from '@thd-olt-component-react/list';
import { CategoryItems } from '../CategoryItems/CategoryItems';

const areEqual = (prevProps, nextProps) => {
  return prevProps.productType === nextProps.productType;
};

const CategoryListContent = ({
  fadeClasses,
  faded,
  fadeRef,
  headerClasses,
  name,
  onClick,
  pageType,
  products,
  productType,
  refinements
}) => {

  return (
    <>
      <div>
        <div className={headerClasses}>Shop {name}</div>
        <UnstyledList className="category-list__list">
          <CategoryItems
            categoryName={name}
            onClick={onClick}
            pageType={pageType}
            refinements={refinements}
          />
        </UnstyledList>
        { products?.length > 0 && (
          <>
            <div className={headerClasses}>Shop {productType}</div>
            <UnstyledList className="category-list__list">
              <CategoryItems
                categoryName={productType}
                onClick={onClick}
                pageType={pageType}
                refinements={products}
                similarProduct
              />
            </UnstyledList>
          </>
        )}
      </div>
      {faded && <div ref={fadeRef} className={fadeClasses} data-testid="faded" />}
    </>
  );
};

CategoryListContent.displayName = 'CategoryListContent';

CategoryListContent.propTypes = {
  fadeClasses: PropTypes.string,
  faded: PropTypes.bool,
  fadeRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  headerClasses: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  pageType: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    recordCount: PropTypes.string,
    refinementKey: PropTypes.string
  })),
  productType: PropTypes.string,
  refinements: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    recordCount: PropTypes.string,
    refinementKey: PropTypes.string
  })),
};

CategoryListContent.defaultProps = {
  fadeClasses: null,
  faded: null,
  fadeRef: null,
  headerClasses: null,
  name: null,
  onClick: null,
  pageType: null,
  products: null,
  productType: null,
  refinements: null
};

export default React.memo(CategoryListContent, areEqual);
