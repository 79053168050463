import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import {
  ProductPod,
  PodFooter,
  PodHeader,
  PodSection,
  PodSpacer,
  PodRowToColumn,
  PodRow,
  PodTapper,
  ProductATC,
  ProductRating,
  ProductSample,
  ProductServiceAddOns,
  ProductIdentifier,
  ProductBadge,
  ProductSponsored,
  ProductImage,
  BundleDataModel
} from '@thd-olt-component-react/product-pod';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { FulfillmentPodShipping, FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';
import { Price, PriceClearanceDataModel } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { ProductSeeSimilarItems } from '@thd-olt-component-react/product-pod-group';
import { NonBuyableQuoteCenter } from '@thd-olt-component-react/non-buyable-quote-center';
import { OrderSample } from '@thd-olt-component-react/order-sample';
import { AisleBayPod } from '@thd-olt-component-react/aisle-bay';
import { useTheme } from '@thd-olt-component-react/theme-provider';

export const CustomProductPodResponsive = ({
  itemId,
  storeId,
  position,
  onHover,
  hoverDelay,
  url,
  samplesLoading,
  itemRecsIndex,
  hasInStoreFilter,
  parent,
  impProductPodData,
  showProjectQuantity,
  hasSameDayFilter,
  isMobileOrConsumerApp
}) => {
  const superSkuChangedItemIdsRef = useRef([]);
  const theme = useTheme(CustomProductPodResponsive);
  const {
    hideAddToListIcon
  } = theme.props;

  const maxLinesForProductDesc = isMobileOrConsumerApp ? 3 : 2;

  return (
    <ProductPod
      analyticsData={{ parent, position }}
      analyticImpressionData={impProductPodData}
      onHover={onHover}
      hoverDelay={hoverDelay}
      itemId={itemId}
      storeId={storeId}
      padding="sui-p-2 sm:sui-pt-2 sm:sui-px-4 sm:sui-pb-4"
      render={(pod) => (
        <>
          <a
            className="sui-top-0 sui-left-0 sui-absolute sui-size-full sui-z-10"
            href={url}
            aria-label="Link"
          />
          <div className="sui-relative sui-flex sui-flex-col sui-h-full">
            <PodSpacer padding={['BOTTOM']}>
              <PodHeader noPadding>
                <PodRow noPadding>
                  <ProductBadge itemId={pod.itemId} storeId={storeId} />
                  <ProductSponsored itemId={pod.itemId} position={position} />
                </PodRow>
                {
                  !hideAddToListIcon
                  && (
                    <PodTapper fitWidth zIndex>
                      <PodRow rowReverse noPadding>
                        <AddToList itemId={pod.itemId} showIconButton storeId={storeId} />
                      </PodRow>
                    </PodTapper>
                  )
                }
              </PodHeader>
            </PodSpacer>
            <PodSection
              id="Slot-2"
              display="sui-grid"
              gap="sui-gap-2"
              columnAlign
              noPadding
            >
              <PodRowToColumn
                noPadding
                gap="sui-gap-y-2 sui-gap-x-4 md:sui-gap-y-2 md:sui-gap-x-0"
              >
                <PodTapper zIndex>
                  <ProductImage
                    itemId={pod.itemId}
                    showSecondaryImage={pod.showSecondaryImage}
                  />
                </PodTapper>
                <PodSection
                  display="sui-block sui-w-full"
                  padding="sui-px-1"
                  grid="sui-col-start-1 sui-col-end-3 md:sui-order-2"
                  order="sui-order-3"
                  noPadding
                  columnAlign
                  zIndex
                  fitWidth
                  aPointer
                  pointerNone
                  buttonPointer
                >
                  <ProductSample itemId={pod.itemId} />
                  <SuperSkuMiniSwatches
                    storeId={storeId}
                    itemId={pod.itemId}
                    onChange={(clickData) => {
                      if (!superSkuChangedItemIdsRef.current.includes(clickData.itemId)) {
                        superSkuChangedItemIdsRef.current = [
                          ...superSkuChangedItemIdsRef.current,
                          clickData.itemId
                        ];
                      }
                      pod.onChange(clickData);
                    }}
                    onHover={pod.onSSKUHover}
                    componentImpressionData={{
                      name: 'ProductPod',
                      component: 'ProductPod',
                      position: position + 1,
                      type: 'product'
                    }}
                    isImpressionsRequired
                    hideComponentWhenEmpty
                  />
                  <ProductServiceAddOns itemId={pod.itemId} />
                </PodSection>
                <PodSection
                  display="sui-grid"
                  gap="sui-gap-2"
                  order="sui-order-2 md:sui-order-3"
                  padding="sui-mb-auto sui-px-1"
                  alignTop
                  noPadding
                  columnAlign
                >
                  <PodTapper fitWidth zIndex>
                    <PodSpacer margin="md:sui-mt-1">
                      <ProductHeader
                        brand="above"
                        itemId={pod.itemId}
                        brandMaxLine={1}
                        titleMaxLine={maxLinesForProductDesc}
                        disableShopThisCollection
                      />
                    </PodSpacer>
                  </PodTapper>
                  <PodTapper fitWidth zIndex>
                    <ProductRating itemId={pod.itemId} />
                  </PodTapper>
                  <ProductIdentifier itemId={pod.itemId} type="model" />
                </PodSection>
              </PodRowToColumn>
              <PodSection noPadding alignTop columnAlign padding="sui-px-1">
                <PodTapper zIndex pointerNone aPointer buttonPointer spanPointer>
                  <PodSpacer
                    padding={['T2', 'BOTTOM']}
                    disable={pod.productSubType === 'HDQC'}
                  >
                    <Price
                      clsRemediation={{ placeholders: true, preservePlaceholders: true }}
                      disableOuterSpacing
                      itemId={pod.itemId}
                      large={false}
                      storeId={storeId}
                      displayEachUom={false}
                      onSavingsCenterToggle={pod.onSavingsCenterToggle}
                      hideForProductTypes={['HDQC']}
                    />
                  </PodSpacer>
                </PodTapper>
              </PodSection>
            </PodSection>
            <PodFooter noPadding margin="sui-mt-auto">
              <PodSection display="sui-grid" padding="sui-px-1" columnAlign noPadding alignEnd>
                <PodTapper zIndex pointerNone aPointer buttonPointer>
                  <PodSpacer
                    padding={['B2']}
                    disable={pod.productSubType === 'HDQC' || pod.productSubType === 'SFI'}
                  >
                    <FulfillmentPodStore itemId={pod.itemId} storeId={storeId} />
                    <FulfillmentPodShipping
                      itemId={pod.itemId}
                      storeId={storeId}
                      hasSameDayFilter={hasSameDayFilter}
                    />
                  </PodSpacer>
                  {isMobileOrConsumerApp && (
                    <PodSpacer>
                      <AisleBayPod
                        itemId={pod.itemId}
                        clsRemediation={{
                          placeholders: true,
                          preservePlaceholders: false
                        }}
                        storeId={storeId}
                        padding="sui-mb-2"
                      />
                    </PodSpacer>
                  )}
                </PodTapper>
              </PodSection>
              <PodSection display="sui-grid" gap="sui-gap-0" columnAlign noPadding alignEnd>
                <PodTapper zIndex>
                  <PodRow noPadding>
                    <div className="sui-flex-1">
                      <ProductATC
                        hasInStoreFilter={hasInStoreFilter}
                        showProjectQuantity={showProjectQuantity}
                        storeId={storeId}
                        itemId={pod.itemId}
                        checkGeneric
                        outline
                      />
                      <ProductSeeSimilarItems
                        itemId={pod.itemId}
                        position={itemRecsIndex}
                        productPodRef={pod.ref}
                        storeId={storeId}
                      />
                      <PodSpacer disable={pod.productSubType !== 'HDQC'}>
                        <NonBuyableQuoteCenter
                          itemId={pod.itemId}
                          checkForProductSubType={['HDQC']}
                        />
                      </PodSpacer>
                    </div>
                    <div className="sui-flex sui-items-center sui-self-center">
                      <OrderSample
                        disableSampleCall={
                          samplesLoading
                          || (pod.itemId !== itemId && !superSkuChangedItemIdsRef.current.includes(pod.itemId))
                        }
                        itemId={pod.itemId}
                        showIconOnly
                        link
                      />
                    </div>
                  </PodRow>
                </PodTapper>
              </PodSection>
            </PodFooter>
          </div>
        </>
      )}
    />
  );
};

CustomProductPodResponsive.displayName = 'CustomProductPodResponsive';

CustomProductPodResponsive.themeProps = {
  hideAddToListIcon: PropTypes.bool
};

CustomProductPodResponsive.defaultThemeProps = {
  hideAddToListIcon: false
};

CustomProductPodResponsive.propTypes = {
  itemId: PropTypes.string.isRequired,
  itemRecsIndex: PropTypes.number.isRequired,
  storeId: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  onHover: PropTypes.func.isRequired,
  hoverDelay: PropTypes.number.isRequired,
  hasInStoreFilter: PropTypes.bool.isRequired,
  samplesLoading: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  impProductPodData: PropTypes.shape({}),
  parent: PropTypes.string,
  showProjectQuantity: PropTypes.bool,
  hasSameDayFilter: PropTypes.bool,
  isMobileOrConsumerApp: PropTypes.bool
};

CustomProductPodResponsive.defaultProps = {
  impProductPodData: null,
  parent: 'plp',
  showProjectQuantity: false,
  hasSameDayFilter: false,
  isMobileOrConsumerApp: false
};

CustomProductPodResponsive.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductServiceAddOns,
  AddToList,
  Price,
  SuperSkuMiniSwatches,
  ProductATC,
  ProductRating,
  ProductSample,
  ProductHeader,
  ProductIdentifier,
  ProductBadge,
  ProductSponsored,
  ProductSeeSimilarItems,
  FulfillmentPodStore,
  FulfillmentPodShipping,
  NonBuyableQuoteCenter,
  OrderSample,
  PriceClearanceDataModel,
  BundleDataModel
);
