import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@thd-olt-component-react/button';
import '../../styles/results-applied.scss';
import { filterCatStyleCheckAndReturnUrl } from '../../product-results-helpers';

const AppliedRefinement = ({
  index,
  dimensionName,
  deselectUrl,
  label,
  onChange,
  refinementKey,
  onDisplay,
  desktopSort
}) => {

  const handleOnChange = () => {
    if (onChange) {
      let deselectUrlRevised = filterCatStyleCheckAndReturnUrl(deselectUrl);
      let querySeparator = '?';
      if (desktopSort.length > 0) {
        const [sortedBy, orderedBy] = desktopSort.split(':');
        if (deselectUrlRevised.includes('?')) {
          querySeparator = '&';
        }
        deselectUrlRevised += `${querySeparator}sortorder=${orderedBy}&sortby=${sortedBy}`;
      }

      onChange({
        refinement: {
          label,
          url: deselectUrlRevised,
          refinementKey
        },
        dimension: {
          label: dimensionName
        },
        onDisplay
      });
    }
  };

  return (
    <Button
      data-remove-url={deselectUrl}
      className="results-applied__bubble sui-mt-2"
      filter
      key={`applied-dimension-${index + refinementKey}`}
      onClick={handleOnChange}
      value={dimensionName}
    >
      {`${dimensionName}: ${label}`}
    </Button>
  );
};

AppliedRefinement.propTypes = {
  index: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  dimensionName: PropTypes.string,
  deselectUrl: PropTypes.string,
  label: PropTypes.string,
  refinementKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDisplay: PropTypes.string,
  desktopSort: PropTypes.string.isRequired
};

AppliedRefinement.defaultProps = {
  index: null,
  dimensionName: null,
  deselectUrl: null,
  label: null,
  refinementKey: null,
  onDisplay: 'false'
};

AppliedRefinement.displayName = 'AppliedRefinement';

export { AppliedRefinement };
