import React from 'react';
import { string, arrayOf } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export const List = ({ list }) => {
  return (
    <ul className="sui-list-disc sui-pl-6">
      {list.map((listItem, listIndex) => {
        return (
          <li key={`list-${listIndex}`}>
            <Typography variant="body-base" height="normal">
              {listItem}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
};

List.propTypes = {
  list: arrayOf(string).isRequired
};
