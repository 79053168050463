import React, { useContext } from 'react';
import { string, bool } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ComponentPlaceholder } from '@thd-olt-component-react/loading-placeholder';

const HdhomeSignupPlaceholder = ({ color, showLoadingAnimation }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  return (
    <ComponentPlaceholder
      height={isMobile ? 130 : 75}
      rows={isMobile ? 4 : 3}
      color={color}
      showLoadingAnimation={showLoadingAnimation}
    />
  );
};

HdhomeSignupPlaceholder.propTypes = {
  color: string,
  showLoadingAnimation: bool
};

HdhomeSignupPlaceholder.defaultProps = {
  color: '#E0E0E0',
  showLoadingAnimation: false
};

export { HdhomeSignupPlaceholder };