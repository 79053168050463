import React, { useRef, useEffect, useState, useContext } from 'react';
import * as PropTypes from 'prop-types';
import { Card } from '@thd-olt-component-react/card';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  alias,
  arrayOf as arrayType,
  bool as boolType, customType,
  params,
  shape as shapeType,
  string as stringType,
  QueryProvider
} from '@thd-nucleus/data-sources';
import { Placeholder } from './Placeholder/Placeholder';
import './category-list.scss';
import CategoryListContent from './CategoryListContent/CategoryListContent';
import { useKPFAndRefinements } from '../hooks/useKPFAndRefinements';

const CategoryList = ({
  containerId,
  itemId,
  onClick,
  pageType,
  maxHeight,
  onSetRefinements,
  spinner,
  className,
  displayType,
  faded,
  ssr
}) => {

  const [atBottom, setAtBottom] = useState(false);
  const [containerMaxHeight, setContainerMaxHeight] = useState('none');
  const ref = useRef();
  const fadeRef = useRef();
  const { isServer } = useContext(ExperienceContext);
  const {
    products,
    productType,
    name,
    refinements,
    loading
  } = useKPFAndRefinements({ itemId, ssr });

  useEffect(() => {
    if (refinements && onSetRefinements) {
      onSetRefinements(refinements);
    }
  }, [refinements]);

  useEffect(() => {
    if (maxHeight) {
      setContainerMaxHeight(`${maxHeight}px`);
    }
  }, [maxHeight]);

  useEffect(() => {
    document.onreadystatechange = () => {
      if (containerId) {
        const container = document.getElementById(containerId);
        if (!container) return;
        const height = container.clientHeight;
        setContainerMaxHeight(`${height}px`);
      }
    };
  }, [containerId]);

  if (loading && isServer !== loading) {
    return (
      <div data-testid="category-loading" className="category-list__loader" data-component="CategoryListPlaceholder">
        <Placeholder spinner={spinner} />
      </div>
    );
  }

  const scrolledToBottom = () => {
    const scrollable = ref.current;
    const fade = fadeRef.current;
    const additionalHeight = (fade && fade.clientHeight) || 0;
    const bottom = scrollable && scrollable.scrollTop + additionalHeight >= scrollable.clientHeight;
    setAtBottom(bottom);
  };

  const classes = classNames('category-list',
    className, {
      'category-list--card': displayType === 'card'
    }
  );

  const fadeClasses = classNames('category-list__fade', {
    'category-list__fade--hidden': atBottom
  });

  const headerClasses = classNames('category-list__header', {
    'category-list__header--card-heading': displayType === 'card'
  });

  if (!refinements || refinements.length === 0) {
    return null;
  }

  return (
    <div
      data-testid="category-list"
      ref={ref}
      className={classes}
      onScroll={scrolledToBottom}
      data-component="CategoryList"
      style={{
        maxHeight: containerMaxHeight || 'none'
      }}
    >
      <QueryProvider cacheKey="category-list">
        {displayType === 'default' && (
          <CategoryListContent
            fadeClasses={fadeClasses}
            faded={faded}
            fadeRef={fadeRef}
            headerClasses={headerClasses}
            name={name}
            onClick={onClick}
            pageType={pageType}
            products={products}
            productType={productType}
            refinements={refinements}
          />
        )}
        {displayType === 'card' && (
          <Card noHide>
            <CategoryListContent
              fadeClasses={fadeClasses}
              faded={faded}
              fadeRef={fadeRef}
              headerClasses={headerClasses}
              name={name}
              onClick={onClick}
              pageType={pageType}
              products={products}
              productType={productType}
              refinements={refinements}
            />
            <Card.Content maxHeight={maxHeight}>
              <CategoryListContent
                fadeClasses={fadeClasses}
                faded={faded}
                fadeRef={fadeRef}
                headerClasses={headerClasses}
                name={name}
                onClick={onClick}
                pageType={pageType}
                products={products}
                productType={productType}
                refinements={refinements}
              />
            </Card.Content>
          </Card>
        )}
      </QueryProvider>
    </div>
  );
};

CategoryList.displayName = 'CategoryList';

CategoryList.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    keyProductFeatures: params().shape({
      keyProductFeaturesItems: arrayType(shapeType({
        features: arrayType(shapeType({
          name: stringType(),
          refinementId: stringType(),
          refinementUrl: stringType(),
          value: stringType()
        }))
      }))
    }),
    taxonomy: shapeType({
      breadCrumbs: arrayType(shapeType({
        label: stringType(),
        url: stringType()
      }))
    })
  }),
  categoryList: alias('searchModel').params({
    additionalSearchParams: customType('AdditionalParams').shape({
      plp: boolType()
    }),
    navParam: stringType(),
    storeId: stringType(),
    storefilter: customType('StoreFilter').enum(['ALL', 'IN_STORE', 'ONLINE'], 'ALL')
  }).shape({
    dimensions: arrayType({
      dimensionId: stringType(),
      refinements: arrayType(shapeType({
        label: stringType(),
        refinementKey: stringType(),
        url: stringType()
      }))
    })
  })
};

CategoryList.propTypes = {
  /** Id for element to be used as max height container */
  containerId: PropTypes.string,
  displayType: PropTypes.oneOf(['default', 'card']),
  /** Product Id used for KRP results */
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Additional classes */
  className: PropTypes.string,
  /** Faded at bottom */
  faded: PropTypes.bool,
  /** api key for kpf endpoint */
  onSetRefinements: PropTypes.func,
  /** Limits the height of CategoryList container */
  maxHeight: PropTypes.string,
  /** onClick */
  onClick: PropTypes.func,
  pageType: PropTypes.string,
  /** Use default loader instead of placeholder */
  spinner: PropTypes.bool,
  ssr: PropTypes.bool
};

CategoryList.defaultProps = {
  className: null,
  containerId: null,
  displayType: 'default',
  faded: false,
  maxHeight: null,
  onSetRefinements: null,
  onClick: null,
  pageType: 'pep',
  spinner: false,
  ssr: false
};

export { CategoryList };
