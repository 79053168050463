import React, { useContext } from 'react';
import { Rating } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames/bind';
import { ProductPodContext } from '../../ProductPodContext';
import { getCustomUrlWithAnalytics } from '../../utils';
import styles from './product-rating.module.scss';

const cx = classNames.bind(styles);

export const ProductRating = () => {
  const {
    onRatingClick,
    product,
    target,
    hosts,
    podType,
    hideRating,
    sponsoredValues
  } = useContext(ProductPodContext);

  if (hideRating) return null;

  const hasRatings = Boolean(
    typeof product?.reviews?.ratingsReviews?.averageRating === 'string'
    && typeof product.reviews.ratingsReviews.totalReviews === 'string'
    && Number(product.reviews.ratingsReviews.totalReviews) > 0
  );

  if (!hasRatings) {
    if (podType === 'sidebyside' || podType === 'simple') {
      return <div className={cx('ratings')} />;
    }
    return null;
  }

  const totalReviews = parseInt(product.reviews.ratingsReviews.totalReviews, 10);
  const averageRating = parseFloat(product.reviews.ratingsReviews.averageRating);
  const productURL = getCustomUrlWithAnalytics({
    customExperience: product.info?.globalCustomConfigurator?.customExperience,
    productType: product.identifiers?.productType,
    blindsHost: hosts?.customBlinds,
    canonicalUrl: product.identifiers?.canonicalUrl,
    info: product.info,
    sponsoredValues
  });

  return (
    <a
      href={`${productURL}#ratings-and-reviews`}
      onClick={onRatingClick}
      target={target}
      data-testid="product-pod__ratings-link"
    >
      <div className={cx('ratings')}>
        <Rating value={averageRating} noMargin />
        <span className={cx('ratings__count')}>({totalReviews})</span>
      </div>
    </a>
  );
};

ProductRating.displayName = 'ProductRating';
