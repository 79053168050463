/* global newrelic */
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';

export const getUniqueHotspots = (hotspots = []) => {
  const uniqueHotspots = new Map();

  hotspots.forEach((hotspot) => {
    if (!uniqueHotspots.has(hotspot.itemId)) {
      uniqueHotspots.set(hotspot.itemId, hotspot);
    }
  });

  return Array.from(uniqueHotspots.values());
};

export const imageStyle = (hsXPosition, hsYPosition) => {
  return {
    cursor: 'pointer',
    left: `${hsXPosition * 100}%`,
    top: `${hsYPosition * 100}%`
  };
};

export const triggerAnalytics = (name, value) => {
  LIFE_CYCLE_EVENT_BUS.trigger(name, value);
};

export const getProductInfo = (container, productPrice) => {
  const itemId = container?.itemId;
  const brandName = container?.identifiers?.brandName;
  const productLabel = container?.identifiers?.productLabel;
  const canonicalUrl = container?.identifiers?.canonicalUrl;
  const averageRating = container?.reviews?.ratingsReviews?.averageRating || 0;
  const totalReviews = container?.reviews?.ratingsReviews?.totalReviews || 0;
  const images = container?.media?.images;
  const [productImage] = images || [];

  return {
    itemId,
    averageRating,
    brandName,
    imageUrl: productImage?.url || '',
    productLabel,
    productUrl: canonicalUrl,
    totalReviews,
    price: productPrice ? `${productPrice}`.split('.') : ''
  };
};
export const isProductOutOfStockOnline = (product = {}) => {
  const isBODFS = podFulFillmentUtils.isBODFS(product);
  const isBOPIS = podFulFillmentUtils.isBOPIS(product);
  const isOutOfStock = podFulFillmentUtils.isOutOfStockOnline(product);
  const isOOS = isOutOfStock && !(isBOPIS || isBODFS);
  return isOOS;
};

export const getProductAvailability = (product) => {
  let unfulfillable = false;
  const productLoaded = product && Object.keys(product)?.length !== 0;
  if (productLoaded) {
    const isBODFS = podFulFillmentUtils.isBODFS(product);
    const isBOPIS = podFulFillmentUtils.isBOPIS(product);
    const isSTH = podFulFillmentUtils.isSTH(product) && !podFulFillmentUtils.getExcludedStateSth(product);
    const isBOSS = podFulFillmentUtils.isBOSS(product);
    const isOOS = isProductOutOfStockOnline(product);
    const isFulfillable = podFulFillmentUtils.isFulfillable(product);
    const noFulfillment = !isBODFS && !isSTH && !isBOPIS && !isBOSS;
    const isCurrentlyUnavailable = podFulFillmentUtils.isCurrentlyUnavailable(product);
    const isDiscontinued = podFulFillmentUtils.isDiscontinued(product);

    if (!isFulfillable
      || isOOS
      || noFulfillment
      || isCurrentlyUnavailable
      || isDiscontinued
    ) {
      unfulfillable = true;
    }
  }

  return {
    unfulfillable
  };
};

export const getFilteredProduct = (products, imageURL) => {
  const inStockProducts = (products || []).filter((product) => {
    return !getProductAvailability(product).unfulfillable;
  });
  if (!inStockProducts.length) {
    // Trigger a newrelic event when all the products are unfulfillable.
    if (typeof window !== 'undefined' && typeof window.newrelic !== 'undefined' && window.newrelic.addPageAction) {
      const omsIDs = products.map((product) => product.itemId).join();
      window.newrelic.addPageAction('PIPOOSHotspots', {
        omsIDs,
        imageURL
      });
    }
    return [products[0]];
  }
  return inStockProducts;
};
