export const hoist = (Component, ...dataModels) => {
  const model = dataModels.reduce((acc, cur) => {
    return {
      ...acc,
      ...cur.dataModel || {}
    };
  }, {});

  // eslint-disable-next-line no-param-reassign
  Component.dataModel = {
    ...(Component.dataModel || {}),
    ...model
  };

  return Component;
};
