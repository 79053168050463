import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UUID } from '../utils';

export const addListItemClass = (children, listItemClass) => {
  const childrenList = children.length ? children : [children];

  return childrenList.filter((ch) => ch).map((child) => {
    const childClasses = child.props ? child.props.className : '';
    const classes = classNames(listItemClass, childClasses);
    return React.cloneElement(child, { key: UUID(), className: classes });
  });
};

// @TODO: We need to rewrite this as a function.
// eslint-disable-next-line react/prefer-stateless-function
class List extends Component {
  render() {

    const { children, className } = this.props;

    if (!children) return null;
    if (children && children.length === 0) return null;

    const classes = classNames(
      className,
      'list',
      'list--type-square'
    );
    return (
      <ul className={classes}>
        {addListItemClass(children, 'list__item')}
      </ul>
    );
  }
}

List.propTypes = {
  /** The list items */
  children: PropTypes.oneOfType([
    // List Items are modified to add classes so can't set specific type
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  /** Additional class names */
  className: PropTypes.string
};

List.defaultProps = {
  className: null
};

export default List;
