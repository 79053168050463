import React, { useContext } from 'react';
import { Image } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { func, string as stringType } from 'prop-types';
import { THUMBS_DOWN } from '../../constants';

import './like-dislike.style.scss';

export const LikeDislike = (props) => {
  const {
    onLikeClick,
    onDislikeClick,
    thumbsUpColor
  } = props;

  const { channel } = useContext(ExperienceContext);
  const iconSize = channel === 'desktop' ? 37 : 34;

  return (
    <div className="like-dislike-wrapper">
      <div className="like-dislike-wrapper__thumb-container">
        <button type="button" onClick={onLikeClick}>
          <Image
            asset={thumbsUpColor}
            width={iconSize}
            height={iconSize}
            alt="up-thumb"
            title="Like"
          />
        </button>
      </div>
      <div className="like-dislike-wrapper__thumb-container">
        <button type="button" onClick={onDislikeClick}>
          <Image
            asset={THUMBS_DOWN}
            width={iconSize}
            height={iconSize}
            alt="down-thumb"
            title="Dislike"
          />
        </button>
      </div>
    </div>
  );
};

LikeDislike.propTypes = {
  onLikeClick: func.isRequired,
  onDislikeClick: func.isRequired,
  thumbsUpColor: stringType
};

LikeDislike.defaultProps = {
  thumbsUpColor: THUMBS_DOWN
};
