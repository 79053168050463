import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from '@thd-olt-component-react/grid';
import RadioGroup from './RadioGroup.component';
import './Radio.styles.scss';

/** THD Radio component. */
class Radio extends Component {
  static Group = RadioGroup;

  render() {
    const {
      children,
      checked,
      disabled,
      formName,
      id,
      onChange,
      value,
      className,
      forwardedRef
    } = this.props;

    const classes = classNames(
      className,
      'radio-btn__content-wrapper'
    );

    return (
      <div className={classes}>
        <input
          className="radio-btn__input"
          type="radio"
          disabled={disabled}
          defaultChecked={checked}
          ref={forwardedRef}
          id={id}
          name={formName}
          onChange={onChange}
          value={value}
        />
        { /* eslint-disable-next-line */ }
        <label className="radio-btn__label" htmlFor={id}>
          <span className="radio-btn" />
          {children}
        </label>
      </div>
    );
  }
}

Radio.propTypes = {
  /** Optional text to right of checkbox */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /** HTML attribute, if true then render checkbox as checked */
  checked: PropTypes.bool,
  /** HTML attribute, if true then make checkbox grey and unclickable */
  disabled: PropTypes.bool,
  /** Groups of inputs/labels require matching name, value does not matter */
  formName: PropTypes.string,
  /** Ref forwarded from parent */
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  /** ID is required for interacting if multiple checkboxes are on page at once */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  /** Action to perform for checkbox click */
  onChange: PropTypes.func.isRequired,
  /** Form value, useful for wrapper implementation */
  value: PropTypes.string.isRequired,
  className: PropTypes.string
};

Radio.defaultProps = {
  children: null,
  checked: false,
  disabled: false,
  formName: '',
  forwardedRef: null,
  className: null
};

export default Radio;
