export const hasInStoreFilter = (resultsData) => {
  const { searchModel } = resultsData || {};
  const { appliedDimensions } = searchModel || {};
  const getItFast = (appliedDimensions || [])
    .filter((dimension) => (dimension.label || '').toUpperCase() === 'GET IT FAST');
  if (!getItFast.length) {
    return false;
  }
  const storePickUp = getItFast
    .find((dimension) => dimension.refinements
      .find((refinement) => refinement.label === 'Pick Up Today'));
  return !!storePickUp;
};

// Leftovers from LayoutManager deprecation. Should be retired if/when possible.
export const shouldDisplayResultsAppliedDesktop = (ctx = {}) => {
  return !(ctx?.isDynamicBrandPage && ctx?.channel !== 'mobile');
};

export const shouldDisplayResultsAppliedMobile = (ctx = {}) => {
  return !(!ctx?.isDynamicBrandPage || ctx?.channel === 'mobile');
};

export const shouldDisplaySearchFeedback = (ctx = {}) => {
  return ctx?.isDebugMode;
};