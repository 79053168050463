import React from 'react';
import PropTypes from 'prop-types';
import { Col } from '@thd-olt-component-react/grid';
import { Image } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';

import '../../styles/refinement.scss';

const VisualRefinement = ({
  dimension,
  onChange,
  refinement,
  visualRefinementClassname,
  drawer
}) => {

  const {
    selected, imgUrl, url, label
  } = refinement || {};

  const constructImageURL = () => {
    const PRODUCT_CATALOG_IMAGE_PATH = 'https://images.homedepot-static.com/productImages';
    const SIZE = '100';

    if (Number(imgUrl) === 1 || !imgUrl) {
      return `https://images.homedepot-static.com/catalog/productImages/No_Image_${SIZE}.jpg`;
    }

    const cleanedImgUrl = imgUrl.trim();
    return `${PRODUCT_CATALOG_IMAGE_PATH}/${cleanedImgUrl}/svn/${cleanedImgUrl}_${SIZE}.jpg`;
  };

  const handleOnChangeLink = (event) => {
    event.preventDefault();
    if (onChange) {
      onChange({ refinement, dimension });
    }
  };

  const specialValueClassName = classNames({
    'refinement__link--special-value': label === 'Special Values'
  });

  const className = classNames('refinement__card', {
    'refinement__card--selected': selected,
  });

  return (
    <Col xs={drawer ? 4 : 6} fallback={4} className={visualRefinementClassname}>
      <div className={className}>
        <a
          type="button"
          href={url}
          onClick={(event) => handleOnChangeLink(event, refinement)}
        >
          <div className="refinement__image-wrapper">
            <Image
              alt={`visual-refinement-${label}`}
              className="refinement__image"
              id={imgUrl}
              src={constructImageURL()}
              lazy
              height="100"
              width="100"
            />
          </div>
          <span className={'refinement__card-label ' + specialValueClassName}>{label}</span>
        </a>
      </div>
    </Col>
  );
};

VisualRefinement.propTypes = {
  dimension: PropTypes.shape({}),
  refinement: PropTypes.shape({
    label: PropTypes.string,
    refinementKey: PropTypes.string,
    selected: PropTypes.bool,
    url: PropTypes.string,
    id: PropTypes.string,
    imgUrl: PropTypes.string
  }),
  /** HTML attribute, if true then render VisualRefinement as selected */
  visualRefinementClassname: PropTypes.string,
  /** Action to perform for VisualRefinement click */
  onChange: PropTypes.func.isRequired,
  drawer: PropTypes.bool
};

VisualRefinement.defaultProps = {
  visualRefinementClassname: null,
  dimension: null,
  refinement: null,
  drawer: null
};

VisualRefinement.displayName = 'VisualRefinement';

export { VisualRefinement };
