import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LinkItem, UnstyledList } from '@thd-olt-component-react/list';
import '../../styles/dimension.scss';

const NestedDimension = ({
  dimension,
  refinement
}) => {

  const [seeAll, setSeeAll] = useState(false);
  let { nestedRefinements } = refinement;
  const { nestedRefinementsLimit } = dimension;
  nestedRefinements = nestedRefinements || [];

  const handleSeeAll = (event) => {
    event.preventDefault();
    setSeeAll(!seeAll);
  };

  const linkClassNameNested = classNames('dimension__item dimension__item--link', {
    'dimension__item--hidden': !seeAll,
  });

  const getRefinementId = (nestedRef, nestedRefIndex) => {
    const { refinementKey } = nestedRef;
    return `${refinementKey}-${nestedRefIndex}`;
  };

  return (
    <>
      <UnstyledList className="dimension__nested-list">
        {nestedRefinements.map((nestedRef, nestedRefIndex) => {
          const nestId = getRefinementId(nestedRef, nestedRefIndex);
          return (
            <LinkItem
              className={linkClassNameNested}
              id={nestId}
              key={nestId}
            >
              <a href={nestedRef.url} data-refinementvalue={nestedRef.label}>
                {nestedRef.label}
              </a>
            </LinkItem>
          );
        })}
      </UnstyledList>
      {nestedRefinements.length > nestedRefinementsLimit && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className="list__link"
          href="#"
          onClick={(event) => handleSeeAll(event)}
        >
          {!seeAll ? '+ See More' : '- See Less'}
        </a>
      )}
    </>
  );
};

NestedDimension.propTypes = {
  dimension: PropTypes.shape({
    nestedRefinementsLimit: PropTypes.number
  }).isRequired,
  refinement: PropTypes.shape({
    label: PropTypes.string,
    refinementKey: PropTypes.string,
    nestedRefinements: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.number
      })
    ),
    url: PropTypes.string,
  }).isRequired
};

NestedDimension.displayName = 'NestedDimension';

export { NestedDimension };
