import React from 'react';
import { bool } from 'prop-types';
import { Loader } from '@thd-olt-component-react/loader';
import { TextBlock } from '@thd-olt-component-react/loading-placeholder';

const Placeholder = ({ spinner }) => {
  if (spinner) {
    return <Loader active />;
  }
  return (
    <TextBlock rows={20} color="#E0E0E0" className="show-loading-animation" />
  );
};

Placeholder.displayName = 'Placeholder';

Placeholder.propTypes = {
  spinner: bool
};

Placeholder.defaultProps = {
  spinner: false
};

export { Placeholder };
