import React from 'react';
import { ProductResultsContext } from './ProductResultsContext';

const ProductResults = (props) => {
  const { children = null, data, error, loading } = props; // eslint-disable-line

  const resp = {
    data,
    error,
    loading
  };
  return (
    <ProductResultsContext.Provider value={resp}>
      {children}
    </ProductResultsContext.Provider>
  );
};

export { ProductResults };
