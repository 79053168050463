import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import { Carousel } from '@thd-olt-component-react/carousel';
import { ProductFavorites } from '../product-favorites/ProductFavorites';
import { ProductPodContext } from '../../ProductPodContext';
import { primaryImageClass, getCustomUrlWithAnalytics, isBuildAndBuyProduct } from '../../utils';
import { PRODUCT_POD_CLICK, BUILD_AND_BUY, POD_IMAGE_SIZE } from '../../constants';

import './ProductImage.scss';

export const ProductImage = ({
  buyMoreSaveMore, dualImageSwipe, inCartQuantity, children
}) => {

  const {
    channel,
    onImageClick,
    onFavoriteChange,
    previewImgUrl,
    target = '_self',
    hosts,
    stfTopRight,
    publishAnalytics,
    hover,
    product: {
      media,
      identifiers: {
        canonicalUrl,
        productType,
        productLabel
      } = {},
      info
    } = {},
    product = {},
    sponsoredValues
  } = useContext(ProductPodContext);

  const {
    images
  } = media || {};

  const {
    augmentedReality,
    globalCustomConfigurator
  } = info || {};

  const customExperience = globalCustomConfigurator?.customExperience;
  const productURL = getCustomUrlWithAnalytics({
    customExperience,
    productType,
    blindsHost: hosts?.customBlinds,
    canonicalUrl,
    info,
    sponsoredValues
  });
  const imageUrl = (images?.find((img) => img.subType === 'PRIMARY') || {}).url;
  const secondaryImageUrl = (images?.find((img) => img.subType === 'SECONDARY') || {}).url;

  const secondaryImageClass = className({
    'product-pod--bmsm-image': buyMoreSaveMore,
    'product-pod--secondary-image': !buyMoreSaveMore
  });

  const noProductImage = 'https://images.thdstatic.com/catalog/productImages/No_Image_145.jpg';
  const isMobile = channel === 'mobile';
  const imageTarget = isMobile ? '' : target;
  const showARIcon = isMobile && augmentedReality;
  const onBuildAndBuyImageClick = () => publishAnalytics(PRODUCT_POD_CLICK, BUILD_AND_BUY);
  const checkOnClick = isBuildAndBuyProduct(product, hover) ? onBuildAndBuyImageClick : onImageClick;

  if (dualImageSwipe && imageUrl && secondaryImageUrl) {
    const childImages = [
      <Image
        key={imageUrl}
        stretchy
        src={imageUrl.replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
        width={POD_IMAGE_SIZE}
        height={POD_IMAGE_SIZE}
        alt={productLabel}
        title={productLabel}
        lazy
      />,
      <Image
        key={secondaryImageUrl}
        stretchy
        src={secondaryImageUrl.replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
        width={POD_IMAGE_SIZE}
        height={POD_IMAGE_SIZE}
        alt={productLabel}
        title={productLabel}
        lazy
      />
    ];
    if (showARIcon) {
      childImages.push(
        (
          <div className="augemented-reality-wrapper">
            <span className="augemented-reality-wrapper__icon" />
          </div>
        )
      );
    }
    return (
      <a
        href={productURL}
        onClick={checkOnClick}
        target={imageTarget}
      >
        <div className="product-pod__image-wrapper">
          <Carousel
            showArrows={false}
            showDots
            dotBelow
            itemWidthMobile="101%"
            itemClass="no-pad"
          >
            {childImages}
          </Carousel>
          {children}
        </div>
      </a>
    );
  }

  const imageWrapperClasses = className('product-pod__image-wrapper', {
    'product-pod__image-wrapper-overlay': inCartQuantity > 0,
  });

  return (
    <div>
      { stfTopRight
      && (
        <ProductFavorites
          showCircle
          onFavoriteChange={onFavoriteChange}
          position="topRight"
        />
      )}
      <a
        href={productURL}
        onClick={checkOnClick}
        target={imageTarget}
        className="product-pod--ie-fix"
      >
        <div
          className={imageWrapperClasses}
          data-quantity={inCartQuantity}
          data-testid="product-pod__image-wrapper"
        >
          {imageUrl && (
            <Image
              stretchy
              className={primaryImageClass({ secondaryImageUrl })}
              src={(previewImgUrl || imageUrl).replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
              width={POD_IMAGE_SIZE}
              height={POD_IMAGE_SIZE}
              alt={productLabel}
              title={productLabel}
              lazy
            />
          )}
          {secondaryImageUrl && (
            <Image
              stretchy
              className={secondaryImageClass}
              src={secondaryImageUrl.replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
              width={POD_IMAGE_SIZE}
              height={POD_IMAGE_SIZE}
              alt={productLabel}
              title={productLabel}
              lazy
            />
          )}
          {!imageUrl && !secondaryImageUrl && (
            <Image
              stretchy
              className={primaryImageClass({ secondaryImageUrl })}
              src={noProductImage}
              width={145}
              height={145}
              alt={productLabel}
              title={productLabel}
              lazy
            />
          )}
          {showARIcon && (
            <div className="augemented-reality-wrapper">
              <span className="augemented-reality-wrapper__icon" />
            </div>
          )}
          {children}
        </div>
      </a>
    </div>
  );
};

ProductImage.propTypes = {
  buyMoreSaveMore: PropTypes.bool,
  dualImageSwipe: PropTypes.bool,
  inCartQuantity: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};

ProductImage.defaultProps = {
  buyMoreSaveMore: false,
  dualImageSwipe: false,
  inCartQuantity: 0,
  children: null
};

ProductImage.displayName = 'ProductImage';
