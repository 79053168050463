// Analytics
export const ADD_TO_CART = 'Add to cart';
export const CHECK_NEARBY_STORE = 'check nearby store';
export const COMPARE = 'compare';
export const PRODUCT = 'product';
export const PRODUCT_DETAILS = 'product details';
export const PRODUCT_IMAGE = 'product image';
export const RATING = 'rating';
export const SAVE_TO_FAVORITE = 'save to favorite';
export const PRODUCT_POD_SWAP = 'product-pod.swap';
export const PRODUCT_POD_CLICK = 'product-pod.click';
export const PRODUCT_POD_ATC = 'product-pod.add-to-cart';
export const PRODUCT_POD_SIMILAR = 'product-pod.see-similar-items';
export const PRODUCT_POD_QUICK_VIEW = 'product-pod.quick-view';
export const PRODUCT_POD_IRG_ATC = 'product-pod-irg.add-to-cart';
export const BADGE_CLICKTHROUGH = 'badge.clickthrough';
export const PRODUCT_POD_COMPARE = 'product-pod.compare';
export const SERVICES_BUTTON_CLICK = 'services-button.click';
export const LIKE_CLICK = 'instant-recs-like.click';
export const DISLIKE_CLICK = 'instant-recs-dislike.click';
export const INSTANT_RECS_IMAGE_CLICK = 'instant-recs-image.click';
export const PRODUCT_POD_LOAD = 'product-pod.load';

// Fullfillment
export const FULFILLMENT_LOCATION_STH = 'DirectShip';
export const FULFILLMENT_METHOD_STH = 'ShipToHome';
export const FULFILLMENT_METHOD_BOSS = 'ShipToStore';
export const FULFILLMENT_METHOD_BOPIS = 'BOPIS';
export const FULFILLMENT_METHOD_BODFS = 'DeliverFromStore';
export const FULFILLMENT_METHOD_APPLIANCE = 'DirectDelivery';
export const NON_RETURNABLE_MESSAGE = 'Made to order, not returnable';
export const INVALID_SSKU = '11111111';
export const CONFIGURABLE_BLINDS = 'CONFIGURABLE_BLINDS';
export const MERCHANDISE = 'MERCHANDISE';
export const MAJOR_APPLIANCE = 'MAJOR_APPLIANCE';

// CTA Buttons
export const SCHEDULE_A_MEASURE = 'Schedule a Measure';
export const GET_ESTIMATE = 'Get Estimate';
export const REQUEST_A_QUOTE = 'Request a Quote';
export const SCHEDULE_A_CONSULTATION = 'Schedule a Consultation';
export const BUILD_AND_BUY = 'Build and Buy';
export const VIEW_DETAILS = 'View Details';
export const CHOOSE_YOUR_OPTIONS = 'Choose Your Options';

// CONSTANT URLS
export const SCHEDULE_A_MEASURE_URL = 'https://www.homedepot.com/services/i/schedule-a-measure/b76e9e302/form';

// PRODUCT SERVICE ADD ONS
export const DUAL_PATH_IDENTIFIER = 'SFI_Available';

// PRODUCT DETAILS
export const PRODUCT_RECORD_TYPE = 10000003;
export const MAX_KEYWORD_LENGTH = 27;
export const HOME_DEPOT_QUOTE_CENTER = 'HDQC';

// INSTANT RECS
export const THUMBS_UP_GRAY = 'thumbs-up-gray.svg';
export const THUMBS_UP_ORANGE = 'thumbs-up-orange.svg';
export const THUMBS_DOWN = 'thumbs-down-gray.svg';
export const HOME_DEPOT_DESIGN_CENTER = 'HDDC';

export const POD_IMAGE_SIZE = 300;