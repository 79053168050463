import React from 'react';
import classnames from 'classnames';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button
} from '@one-thd/sui-atomic-components';
import { useFitCompatibility } from '../FitCompatibilityProvider';
import {
  Text,
  Tip,
  List,
  Image
} from './drawerComponents';
import { DRAWER_MAPPING } from '../../constants';
import { track } from '../../fit-compatibility-analytics';

export const FitDrawer = () => {
  const {
    drawerPosition,
    drawerOpen,
    setDrawerOpen,
    drawerIndex,
    setDrawerIndex
  } = useFitCompatibility();

  const onDrawerClose = () => {
    setDrawerOpen(false);
    setDrawerIndex(0);
  };

  const { header, sections, footer } = DRAWER_MAPPING[drawerIndex];

  const buildSectionClassnames = ({
    subIndex,
    flexGroup = false,
    hasBorder = false,
    shiftLeft = false,
    marginOverride = null
  }) => {
    return classnames({
      'sui-mb-4': subIndex === 0,
      'sui-mb-2': subIndex === 1,
      'sui-mb-1': subIndex >= 2,
      'sui-ml-6': shiftLeft,
      'sui-flex sui-justify-center sui-gap-4': flexGroup,
      'sui-border-solid sui-border-1 sui-border-primary sui-rounded-md': hasBorder,
      '!sui-mb-0': marginOverride === 0,
      '!sui-mb-1': marginOverride === 1,
      '!sui-mb-2': marginOverride === 2,
      '!sui-mb-3': marginOverride === 3,
      '!sui-mb-4': marginOverride === 4,
      '!sui-mb-5': marginOverride === 5,
      '!sui-mb-6': marginOverride === 6,
      '!sui-mb-8': marginOverride === 8
    });
  };

  const buildDrawer = (drawerSections, subIndex = 0) => {
    return drawerSections.map((section, sectionIndex) => {
      if (Array.isArray(section)) return buildDrawer(section, subIndex + 1);
      const [[type, content], [_, modifiers = {}] = []] = Object.entries(section);
      const {
        flexGroup,
        hasBorder,
        shiftLeft,
        marginOverride
      } = modifiers;

      const sectionClassnames = buildSectionClassnames({
        subIndex,
        flexGroup,
        hasBorder,
        shiftLeft,
        marginOverride
      });

      switch (type) {
      case 'text': {
        const { string, isTitle, isTiny } = content;
        return (
          <div
            key={`section-${subIndex}.${sectionIndex}`}
            className={sectionClassnames}
          >
            <Text text={string} isTitle={isTitle} isTiny={isTiny} />
          </div>
        );
      }
      case 'list': {
        return (
          <div
            key={`section-${subIndex}.${sectionIndex}`}
            className={sectionClassnames}
          >
            <List list={content} />
          </div>
        );
      }
      case 'image': {
        const {
          src,
          alt,
          size,
          subHeader
        } = content;
        return (
          <div
            key={`section-${subIndex}.${sectionIndex}`}
            className={sectionClassnames}
          >
            <Image
              src={src}
              alt={alt}
              size={size}
              subHeader={subHeader}
            />
          </div>
        );
      }
      case 'tip': {
        const { string, sections: tipSections = [] } = content;
        return (
          <div
            key={`section-${subIndex}.${sectionIndex}`}
            className={sectionClassnames}
          >
            <Tip text={string}>
              <>{tipSections.length ? buildDrawer(tipSections, subIndex + 1) : null}</>
            </Tip>
          </div>
        );
      }
      case 'sections': {
        return (
          <div
            key={`section-${subIndex}.${sectionIndex}`}
            className={sectionClassnames}
          >
            {buildDrawer(content, subIndex + 1)}
          </div>
        );
      }
      default: {
        return null;
      }
      }
    });
  };

  const scrollToTop = () => {
    const drawerElement = document.getElementById('fit-compatibility-drawer');

    if (drawerElement) {
      drawerElement.scrollTop = 0;
    }
  };

  if (drawerIndex === 4) {
    track('step4');
  }

  return (
    <Drawer
      DrawerRootProps={{
        id: 'fit-compatibility-drawer'
      }}
      position={drawerPosition}
      open={drawerOpen}
      onClose={onDrawerClose}
    >
      {drawerIndex === 0 ? (
        <DrawerHeader
          title={header}
          onClose={onDrawerClose}
        />
      ) : (
        <DrawerHeader
          title={header}
          onBack={() => {
            setDrawerIndex(drawerIndex - 1);
            scrollToTop();
          }}
          onClose={onDrawerClose}
        />
      )}
      <DrawerBody>
        <div className="sui-mt-2">
          {buildDrawer(sections)}
        </div>
      </DrawerBody>
      <DrawerFooter>
        <Button
          fullWidth
          onClick={() => {
            const indexBump = drawerIndex + 1;
            if (DRAWER_MAPPING[indexBump] === undefined) {
              onDrawerClose();
            } else {
              scrollToTop();
              setDrawerIndex(indexBump);
            }
          }}
        >
          {footer}
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
