import React from 'react';
import { Ruler } from '@one-thd/sui-icons';
import {
  Typography,
  Link,
  Skeleton,
  SkeletonBlock
} from '@one-thd/sui-atomic-components';
import { useFitCompatibility } from './FitCompatibilityProvider';

export const FitCompatibilityWidgetMini = () => {
  const {
    isLoading,
    header,
    setDrawerOpen
  } = useFitCompatibility();

  return (
    isLoading ? (
      <Skeleton
        grow
        disablePadding
      >
        <SkeletonBlock
          aspect="wide"
          width="full"
          height={6}
        />
      </Skeleton>
    ) : (
      <div className="sui-flex sui-gap-2">
        <Ruler data-testid="fit-icon" />
        <Typography variant="body-base" height="tight">
          <Link
            underline="always"
            onClick={() => setDrawerOpen(true)}
            component="button"
          >
            {header}
          </Link>
        </Typography>
      </div>
    )
  );
};
