import React, { useContext } from 'react';
import { Price } from '@thd-olt-component-react/price';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, string, shape } from 'prop-types';
import {
  arrayOf as arrayOfType,
  extend,
  params,
  shape as shapeType,
  string as stringType,
  bool as boolType,
} from '@thd-nucleus/data-sources';
import {
 Typography, Card, CardActions, CardBody, CardContent, CardMedia, CardTitle,
  RatingMeter
} from '@one-thd/sui-atomic-components';

import { detailsDataModel } from './DetailsDataModel';
import { triggerAnalytics, getProductInfo } from '../../helpers';

const ProductCardList = ({ product, anchorClass, index, storeId }) => {

  const {
    itemId,
    averageRating,
    brandName,
    imageUrl,
    productLabel,
    productUrl,
    totalReviews
  } = getProductInfo(product);

  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';

  const onProductLinkClick = () => {
    triggerAnalytics('product-hotspots.product-link-click', { sku: itemId });
  };

  return (
    <Card orientation="horizontal" className="sui-border-0 card-container sui-pt-4" role="productCardList">
      <CardContent disablePadding grow initialSize="2/4">
        <a
          className={anchorClass}
          href={productUrl}
          onClick={onProductLinkClick}
          rel="noreferrer"
          target={isMobile ? '_self' : '_blank'}
          key={index}
          role="link"
          aria-label="productMedia"
        >
          <CardMedia src={imageUrl?.replace(/<SIZE>/g, '300')} alt="Product" aspect="square" />
        </a>
      </CardContent>
      <CardContent disablePadding grow initialSize="4/4">
        <a
          className={anchorClass}
          href={productUrl}
          onClick={onProductLinkClick}
          rel="noreferrer"
          target={isMobile ? '_self' : '_blank'}
          role="link"
          aria-label="productDetail"
          key={index}
        >
          <CardTitle header={
            <Typography variant="h5">{productLabel}</Typography>
            }
          />
          <CardBody lineClamp>
            <Typography variant={isMobile ? 'h6' : 'h5'}>
              {brandName && (
                <span className="details__brand">
                  by&nbsp;
                  <span className="details__brand--bold">
                    {brandName}
                  </span>
                </span>
              )}
            </Typography>
            <RatingMeter
              edge="start"
              value={Number(averageRating)}
              label={`(${totalReviews})`}
            />
            <Price
              itemId={itemId}
              product={product}
              storeId={storeId}
              large={false}
            />
          </CardBody>
        </a>
        <CardActions>
          <div className="sui-basis-full sui-max-w-full">
            <AddToCart
              variant="secondary"
              itemId={String(itemId)}
              cartReqParams={{ quantity: 1, itemId: String(itemId) }}
              cartOptions={{
                host: 'https://www.homedepot.com',
                channel,
                paypal: false,
                misship: false,
                component: 'shoppable images drawer',
                sharedSection: 'shoppable images'
              }}
            >
              Add To Cart
            </AddToCart>
          </div>
        </CardActions>
      </CardContent>
    </Card>
  );
};

ProductCardList.dataModel = extend({ detailsDataModel }, Price);

ProductCardList.propTypes = {
  product: shape({
    itemId: string
  }).isRequired,
  anchorClass: string,
  index: number,
  storeId: string.isRequired
};

ProductCardList.defaultProps = {
  anchorClass: '',
  index: 0
};

export { ProductCardList };