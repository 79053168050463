import React from 'react';

const CONTEXT_KEY = Symbol.for('@thd-olt-component-react/product-pod/Context');
const globalSymbols = Object.getOwnPropertySymbols(global);
const hasContext = (globalSymbols.indexOf(CONTEXT_KEY) > -1);

const funcDefault = () => {};

if (!hasContext) {
  global[CONTEXT_KEY] = React.createContext({
    automotiveFitmentObject: null,
    brand: 'hidden',
    brandTitleMaxLine: null,
    brandMaxLine: null,
    channel: 'desktop',
    env: 'prod',
    hideAccordion: false,
    hideBulkPrice: false,
    hideLimitPerOrder: false,
    hidePromotions: false,
    itemId: null,
    maxKeyFeatures: 0,
    noATCFulfillment: false,
    onImageClick: funcDefault,
    onCheckNearbyStoresClick: funcDefault,
    onFavoriteClick: funcDefault,
    onOptionClick: funcDefault,
    onOptionMouseEnter: funcDefault,
    onOptionMouseLeave: funcDefault,
    onQuantityChange: funcDefault,
    onRatingClick: funcDefault,
    onVariantClick: funcDefault,
    onCartSuccess: funcDefault,
    onCartFailure: funcDefault,
    pageType: null,
    podType: 'default',
    position: 1,
    previewImgUrl: null,
    product: null,
    quantity: 1,
    showVariantAlert: false,
    titleMaxLine: null,
    showDisabledATC: false,
    showSoldOutMessage: false,
    showAisleAndBay: false
  });
}

export const ProductPodContext = global[CONTEXT_KEY];
