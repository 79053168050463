import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PaginationDisplayedItemCount = React.forwardRef((props, ref) => {

  const {
    component,
    disableGrow = false,
    items = 10,
    itemsPerPage = 10,
    page = 1,
    ...other
  } = props;

  const Root = component || 'p';

  const from = (page - 1) * itemsPerPage + 1;
  const to = Math.min(from + itemsPerPage - 1, items);

  const rootClasses = classNames('sui-font-regular sui-text-base sui-text-primary sui-tracking-normal sui-normal-case sui-line-clamp-unset', {
    'sui-grow': !disableGrow,
    'sui-grow-0': disableGrow
  });

  return (
    <Root className={rootClasses} ref={ref} {...other}>Viewing <b>{from}-{to}</b> of <b>{items}</b></Root>
  );
});

PaginationDisplayedItemCount.displayName = 'PaginationDisplayedItemCount';

PaginationDisplayedItemCount.propTypes = {
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, prevents the component from growing.
   * @default false
   */
  disableGrow: PropTypes.bool,
  /**
   * The total number of items.
   */
  items: PropTypes.number.isRequired,
  /**
   * The number of items per page.
   */
  itemsPerPage: PropTypes.number.isRequired,
  /**
   * The index of the current page.
   */
  page: PropTypes.number.isRequired,
};

PaginationDisplayedItemCount.defaultProps = {
};

export { PaginationDisplayedItemCount };
