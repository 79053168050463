import React from 'react';
import PropTypes from 'prop-types';
import { LinkItem } from '@thd-olt-component-react/list';
import '../../styles/refinement.scss';

const LinkRefinement = ({
  arrow,
  linkClassName,
  dimension,
  id,
  onChange,
  refinement
}) => {

  const handleLinkClick = (event) => {
    event.preventDefault();
    if (onChange) {
      onChange({ refinement, dimension });
    }
  };

  return (
    <LinkItem
      className={linkClassName}
      id={id}
      data-refinement={refinement.label}
      data-filterposition="left rail:top filters:1"
      data-active="yes"
      data-omni="true"
    >
      {arrow && (
        <img
          className="dimension__link"
          src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
          alt="back-caret"
          height="15"
          width="9"
        />
      )}
      <a href={refinement.url} data-refinementvalue={refinement.label} onClick={(event) => handleLinkClick(event)}>
        {refinement.label}
      </a>
    </LinkItem>
  );
};

LinkRefinement.propTypes = {
  arrow: PropTypes.bool,
  linkClassName: PropTypes.string,
  dimension: PropTypes.shape({}).isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  refinement: PropTypes.shape({
    label: PropTypes.string,
    refinementKey: PropTypes.string,
    selected: PropTypes.bool,
    url: PropTypes.string,
  }).isRequired
};

LinkRefinement.defaultProps = {
  arrow: false,
  linkClassName: null,
  id: null
};

LinkRefinement.displayName = 'LinkRefinement';

export { LinkRefinement };
