import { useDataModel } from '@thd-nucleus/data-sources';

export const useRefinements = ({ navParam, storeId, ssr = false, skip = false }) => {

  const { data, loading } = useDataModel('categoryList', {
    skip,
    ssr,
    variables: {
      navParam,
      storeId,
      additionalSearchParams: { plp: true },
      storefilter: 'ALL'
    }
  });

  if (!data) return { loading };

  const dimensions = data?.searchModel?.dimensions || [];
  const subcategories = dimensions.find((dimension) => dimension.dimensionId === '2');
  const refinements = subcategories ? subcategories.refinements : [];

  return { refinements, loading };
};

useRefinements.displayName = 'useRefinements';
