const formatAisleBayResponse = ({ response }) => {
  let { aisle = '', bay = '', locationDesc = '', invLocDesc = '' } = response || {};

  // Appliance Priority
  if (locationDesc.toLowerCase() === 'showroom') {
    return 'Appliance Showroom ';
  }

  if (invLocDesc?.toLowerCase().includes('showroom')) {
    return `Showroom, Bay ${bay}`;
  }

  // Requires aisle if no location desc
  if (aisle === '') {
    return '';
  }

  // Handle combination of aisle and bay
  if (/end cap/i.test(aisle)) {
    aisle = `End Cap ${(aisle.replace('E', ' '))} `;
  } else if (locationDesc) {
    aisle = locationDesc;
  } else if (aisle !== '') {
    aisle = `Aisle ${aisle}`;
  }

  if (bay !== '' && aisle !== '') {
    bay = `, Bay ${bay}`;
  }

  return `${(aisle)}${(bay)}`;
};

const cleanNumber = (number) => {
  return `${number}`.replace(/[^\d]/g, '');
};

const formatPhoneNumber = (number) => {
  const formattedNum = (`${number}`.length >= 6)
    ? `${number}`.replace(/^(\d{3})(\d{1,3})/g, '$1-$2-') : `${number}`.replace(/^(\d{3})/g, '$1-');
  return formattedNum;
};

const isValidPhoneNumber = ({ phoneNumber }) => {
  const noHyphensPhoneNumberPattern = /^[2-9]\d{2}\d{3}\d{4}/;
  const isValid = Boolean(`${phoneNumber}`.match(noHyphensPhoneNumberPattern));
  return isValid;
};

const publish = (type, data) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(`aisle-bay.${type}`, data);
  }
};

const ready = ({ component }) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(`${component}.ready`);
  }
};
const getBOPISFulfillment = (fulfillmentOptions) => {
  const pickupService = fulfillmentOptions?.find((option) => option.type === 'pickup');
  const bopis = pickupService?.services?.find((service) => service.type === 'bopis');
  return bopis || null;
};

const getAisleBayInventory = (localStoreQuantity, isAppliance) => {
  if (isAppliance) return 'On Display';
  return `${localStoreQuantity || 0} in stock`;
};

const getStoreLayoutLink = ({ aislebay, itemId, storeId, storeName }) => {
  const { aisle, bay } = aislebay || {};
  const url = `/l/detail/${storeName}/${storeId}/layout?aisle=${aisle}&bay=${bay}`;
  return itemId ? `${url}&productId=${itemId}` : url;
};

const getLocalStore = (fulfillmentOptions) => {
  const bopisFulfillment = getBOPISFulfillment(fulfillmentOptions);
  return (bopisFulfillment?.locations || []).find((location) => location.isAnchor);
};

const transformData = (localStore, isAppliance, aisleBayData, storeName) => {
  const aisleBayMessage = formatAisleBayResponse({ response: aisleBayData });
  const localStoreQuantity = localStore?.inventory?.quantity;
  const aisleBayInventory = getAisleBayInventory(localStoreQuantity, isAppliance);
  const localStoreName = localStore?.storeName || storeName;

  const hasAllRequiredProperties = Boolean(
    localStoreName
    && (localStoreQuantity || isAppliance)
    && aisleBayInventory
    && aisleBayMessage
  );

  return {
    hasAllRequiredProperties,
    aisleBayInventory,
    localStoreName,
    aisleBayMessage
  };
};

export {
  cleanNumber,
  formatPhoneNumber,
  getLocalStore,
  isValidPhoneNumber,
  publish,
  getAisleBayInventory,
  ready,
  getStoreLayoutLink,
  transformData
};
