/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

const defaultTarget = typeof window !== 'undefined' ? window : null;

export default function useScrollTrigger(options = {}) {
  const {
    monitor = false,
    target = defaultTarget
  } = options;
  const [trigger, setTrigger] = React.useState(false);
  const store = React.useRef();

  useEffect(() => {
    if (!monitor) {
      setTrigger(false);
      return;
    }

    const handleScroll = () => {
      const currentYOffset = store.current !== undefined ? store.current : 0;
      if (target) {
        store.current = target.pageYOffset !== undefined ? target.pageYOffset : target.scrollTop;
      }
      setTrigger(store.current !== currentYOffset);
    };

    target.addEventListener('scroll', handleScroll);
    return () => target.removeEventListener('scroll', handleScroll);
  }, [target, monitor]);

  return { trigger };
}