import {
  params, string, arrayOf, shape, number, customType, client
} from '@thd-nucleus/data-sources';

const ListDetail = shape({
  listId: string().isRequired(),
  listName: string(),
  items: arrayOf(shape({
    itemId: string()
  }))
});

const dataModel = {
  product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
    itemId: string(),
    dataSources: string(),
    media: shape({
      image: shape({
        url: string()
      }).client(),
      images: arrayOf(shape({
        url: string(),
        sizes: arrayOf(string()),
        type: string(),
        subType: string()
      })),
    }),
    pricing: params({ storeId: string() }).shape({
      value: number({ float: true })
    }),
    favoriteDetail: client(shape({
      count: number()
    }))
  }),
  listDetails: params({
    customerAccountID: string().isRequired(),
    userId: string().isRequired(),
    customerType: customType('CustomerType').enum(['B2B', 'B2C'], 'B2C')
  }).shape({
    listResponse: shape({
      listDetails: arrayOf(ListDetail)
    })
  })
};

export { dataModel };
