import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDomPath } from '@thd-olt-functional/utils';
import { track } from '../analytics';
import './Alert.style.scss';

/** THD Alert component. */
const Alert = (props) => {
  const {
    children,
    dismiss,
    inline,
    keyTriggers,
    message,
    title,
    type,
    noClose
  } = props;

  const alertClass = inline ? 'alert-inline' : 'alert';
  const showClose = (type === 'danger') ? (!noClose && !inline) : !inline;

  const keyPress = (event) => {
    const triggers = keyTriggers ? keyTriggers : [13, 32]; /* eslint-disable-line */
    if (triggers.indexOf(event.keyCode) > -1) {
      event.currentTarget.parentNode.remove();
    }
  };

  const onClickHandler = (event) => {
    dismiss();
    event.currentTarget.parentNode.remove();
  };

  const [path, ref] = useDomPath();

  useEffect(() => {
    if ((type === 'danger' || type === 'warning') && path?.length > 0) {
      track({
        path,
        inline,
        message,
        title,
        type
      });
    }
  }, [path?.length]);

  return (
    <>
      {
        (title || message || children) && (
          <div className={`${alertClass} ${alertClass}--${type}`} ref={ref}>
            <span className={`${alertClass}__message`}>
              {
                title
                && <span className={`${alertClass}__title`}>{title} </span>
              }
              {message}
              {children}
            </span>
            {
              showClose
              /* eslint-disable-next-line */
              && <i className="alert__close" onKeyDown={keyPress} onClick={onClickHandler} role="button" tabIndex="0" />
            }
          </div>
        )
      }
    </>
  );
};

Alert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /**
   * Handler for close icon click
   */
  dismiss: PropTypes.func,
  /** Display the alert inline */
  inline: PropTypes.bool,
  /** Do not Display Close icon. Only works with danger type */
  noClose: PropTypes.bool,
  /**
   * Accessibility Linter: Allow remove on focus via space or enter
   */
  keyTriggers: PropTypes.arrayOf(PropTypes.number),
  /** Alert Message */
  message: PropTypes.string,
  /** Alert Title (bold text) */
  title: PropTypes.string,
  /** Alert type. One of 'danger' | 'success' | 'information' | 'warning' | 'close' */
  type: PropTypes.oneOf(['danger', 'success', 'information', 'warning', 'close']),
};

Alert.defaultProps = {
  children: null,
  dismiss: () => {},
  inline: false,
  keyTriggers: [13, 32],
  message: null,
  title: null,
  type: 'danger',
  noClose: false
};

export default Alert;
