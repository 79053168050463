import { useEffect, useRef, useContext, useState } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';
import {
  arrayOf as arrayType,
  bool as boolType, customType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  useDataModel,
} from '@thd-nucleus/data-sources';
import {
  getLoadingState,
  getNavParam,
  getCustomPriceRange,
  PICK_UP_TODAY_REFINEMENT_KEY
} from '../product-results-helpers';

const ALLOWED_SORT_ORDERS = ['asc', 'desc'];

const usePrevious = (value, { data, loading }) => {
  const ref = useRef();
  useEffect(() => {
    if (data && !loading) {
      ref.current = value;
    }
  }, [data, loading, value]);
  return ref.current;
};

export const useProductResults = ({
  additionalSearchParams = {},
  itemId,
  keyword,
  lowerbound,
  start = 0,
  nValue,
  pageSize = 48,
  sortby = keyword ? 'bestmatch' : 'topsellers',
  sortorder = 'asc',
  productSSR = true,
  searchSSR = true,
  storeId,
  storefilter = 'ALL',
  upperbound,
  isCustomerIdentified = false
}) => {
  const ctx = useContext(ExperienceContext);
  const cachedResponse = useRef(null);
  const { nearByStores = [], channel = 'desktop', isConsumerApp } = ctx;

  const sanitizedSortOrder = sortorder
  && ALLOWED_SORT_ORDERS.indexOf(sortorder) === -1 ? 'asc' : sortorder;

  const sortbyType = {
    topsellers: 'TOP_SELLERS',
    toprated: 'TOP_RATED',
    bestmatch: 'BEST_MATCH',
    price: 'PRICE',
    mostpopular: 'MOST_POPULAR',
    deliverydate: 'DELIVERY_DATE'
  };

  const { data: pData } = useDataModel('product', {
    skip: !(!!itemId && !nValue),
    ssr: productSSR,
    variables: {
      itemId,
      storeId
    },
  });

  const { breadCrumbs } = pData?.product?.taxonomy || {};

  const navParam = getNavParam({
    nValue,
    breadCrumbs
  });

  const { lbound, ubound } = getCustomPriceRange(lowerbound, upperbound);

  const customPrice = !!(lbound || ubound) && {
    lowerBound: lbound,
    upperBound: ubound,
    rangefilter: 'price'
  };

  const nao = parseInt(start, 10);
  const startIndex = pageSize * Math.ceil(start / pageSize);

  let multiStoreIds = [...nearByStores];
  const hasCurrentStore = !!(multiStoreIds.find((store) => store === storeId));
  if (multiStoreIds.length && !hasCurrentStore) {
    multiStoreIds.unshift(storeId);
  }
  const storeFilterOpts = (navParam || '').includes(PICK_UP_TODAY_REFINEMENT_KEY) ? 'IN_STORE' : storefilter;
  const standardOpts = {
    additionalSearchParams: {
      ...additionalSearchParams,
      multiStoreIds: (!multiStoreIds.length && storeFilterOpts === 'IN_STORE' && isConsumerApp)
        ? [storeId] : multiStoreIds
    },
    channel: channel.toUpperCase(),
    filter: {
      ...(!!customPrice && customPrice)
    },
    keyword,
    navParam,
    orderBy: {
      field: sortbyType[sortby],
      order: sanitizedSortOrder.toUpperCase()
    },
    pageSize,
    storefilter: storeFilterOpts,
    storeId,
    startIndex,
    isBrandPricingPolicyCompliant: isCustomerIdentified
  };
  const standardResponse = useDataModel('searchModel', {
    skip: cachedResponse.current || (!navParam && !keyword),
    ssr: searchSSR,
    variables: { ...standardOpts }
  });

  // The cached response is to prevent category pages from firing clientside 1 extra query thats not needed
  const cachedIsCategory = standardResponse?.data?.searchModel?.metadata?.contentType === 'categorypage'
    && standardResponse?.data?.searchModel?.products?.length === 0;
  if (standardResponse?.data && !cachedResponse.current?.data && cachedIsCategory) {
    cachedResponse.current = standardResponse;
  }

  const prevProps = usePrevious({
    keyword,
    navParam,
    lowerbound: lbound,
    sortby,
    storeId,
    sortorder: sanitizedSortOrder,
    startIndex,
    upperbound: ubound
  }, standardResponse);

  if (cachedResponse.current) {
    return {
      data: cachedResponse.current.data,
      loading: false,
      responseLoading: false,
      error: null,
      variables: cachedResponse.current.variables
    };
  }

  return {
    data: standardResponse.data,
    loading: getLoadingState({
      data: standardResponse.data,
      loading: standardResponse.loading,
      props: {
        keyword,
        lowerbound: lbound,
        navParam,
        sortby,
        storeId,
        startIndex,
        sortorder: sanitizedSortOrder,
        upperbound: ubound
      },
      prevProps
    }),
    responseLoading: standardResponse.loading,
    error: standardResponse.error,
    variables: standardResponse.variables
  };
};

export const ProductKPFResults = ({ itemId, storeId, channel }) => {
  const { data, loading } = useDataModel('product', {
    ssr: false,
    skip: !itemId,
    variables: {
      itemId,
      storeId
    }
  });

  if (channel !== 'mobile') return {};

  if (!data) return { loading };

  return data?.product?.keyProductFeatures?.keyProductFeaturesItems[0] || {};
};

ProductKPFResults.displayName = 'KPFData';

ProductKPFResults.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    keyProductFeatures: params().shape({
      keyProductFeaturesItems: arrayType(shapeType({
        features: arrayType(shapeType({
          name: stringType(),
          refinementId: stringType(),
          refinementUrl: stringType(),
          value: stringType()
        }))
      }))
    })
  })
};

ProductKPFResults.propTypes = {
  itemId: PropTypes.string,
  storeId: PropTypes.string,
  channel: PropTypes.string.isRequired
};

ProductKPFResults.defaultProps = {
  itemId: null,
  storeId: null
};
