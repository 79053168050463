import {
  params,
  string,
  shape,
  arrayOf,
  customType,
  bool,
  number,
  extend
} from '@thd-nucleus/data-sources';
import { ProductPod } from '@thd-olt-component-react/product-pod';

const ProductModel = extend({}, { itemId: string() }, ProductPod.dataModel.product);

const appliedRefinement = shape({
  label: string(),
  refinementKey: string(),
  url: string()
});

const appliedDimension = shape({
  label: string(),
  refinements: arrayOf(appliedRefinement),
  isNumericFilter: bool(),
});

const appliedDimensions = arrayOf(appliedDimension);

const nestedRefinement = shape({
  label: string(),
  url: string(),
  recordCount: string(),
  refinementKey: string(),
});

const refinement = shape({
  label: string(),
  refinementKey: string(),
  recordCount: string(),
  selected: bool(),
  imgUrl: string(),
  url: string(),
  nestedRefinements: arrayOf(nestedRefinement)
});

const dimension = shape({
  collapse: bool(),
  dimensionId: string(),
  isVisualNav: bool(),
  isVisualDimension: bool(),
  isNumericFilter: bool(),
  isColorSwatch: bool(),
  label: string(),
  nestedRefinementsLimit: number(),
  refinements: arrayOf(refinement),
  visualNavSequence: string()
});

const dimensions = arrayOf(dimension);
const primaryFilters = arrayOf(dimension);

const taxonomy = shape({
  breadCrumbs: arrayOf(shape({
    dimensionName: string(),
    label: string(),
    url: string(),
    refinementKey: string()
  }))
});

const searchReport = shape({
  correctedKeyword: string(),
  keyword: string(),
  pageSize: number(),
  searchUrl: string(),
  sortBy: string(),
  sortOrder: string(),
  startIndex: number(),
  totalProducts: number()
});

const metadata = shape({
  canonicalUrl: string(),
  clearAllRefinementsURL: string(),
  contentType: string(),
  h1Tag: string(),
  isStoreDisplay: string(),
  productCount: shape({
    inStore: string()
  }),
  searchRedirect: string(),
  stores: shape({
    storeId: string(),
    storeName: string(),
    address: shape({
      postalCode: string()
    }),
    nearByStores: arrayOf(shape({
      storeId: string(),
      storeName: string(),
      distance: string(),
      address: shape({
        postalCode: string()
      })
    }))
  })
});

const BreadCrumbType = shape({
  dimensionName: string(),
  url: string()
});

export const productDefaultResultsDataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    dataSources: string(),
    taxonomy: shape({
      breadCrumbs: arrayOf(BreadCrumbType)
    })
  }),
  searchModel: params({
    keyword: string(),
    navParam: string(),
    storeId: string(),
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE', 'TABLET'], 'DESKTOP'),
    storefilter: customType('StoreFilter').enum(['ALL', 'IN_STORE', 'ONLINE'], 'ALL'),
    additionalSearchParams: customType('AdditionalParams').shape({
      mcvisId: string(),
      sponsored: bool(),
      svocID: string()
    }),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      svocID: string(),
      programTiers: arrayOf(
        shape({
          tier: string(),
          program: string()
        })
      )
    })
  }).shape({
    id: string(),
    appliedDimensions,
    dimensions,
    primaryFilters,
    metadata,
    products: params({
      startIndex: number(),
      pageSize: number(),
      orderBy: customType('ProductSort').shape({
        field: customType('ProductSortField').enum([
          'TOP_SELLERS', 'MOST_POPULAR', 'TOP_RATED', 'NEW_ITEMS',
          'BEST_MATCH', 'PRODUCT_NAME', 'BRAND_NAME', 'PRICE'
        ]),
        order: customType('SortOrder').enum(['ASC', 'DESC'])
      }),
      filter: customType('ProductFilter').shape({
        rangefilter: string(),
        lowerBound: number(),
        upperBound: number()
      })
    }).arrayOf(ProductModel),
    searchReport,
    taxonomy
  })
};
