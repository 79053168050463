import React from 'react';
import { string, element } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export const Tip = ({ text, children }) => {
  return (
    <div className="sui-p-4">
      <Typography
        component="span"
        weight="bold"
        height="tight"
      >
        Tip:
      </Typography>
      {!!text && (
        <Typography
          component="span"
          weight="regular"
          height="tight"
        >
            &nbsp;{text}
        </Typography>
      )}
      {children}
    </div>
  );
};

Tip.propTypes = {
  text: string,
  children: element
};

Tip.defaultProps = {
  text: '',
  children: null
};
