/* global cookieUtils  */
import React, {
  createRef,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useReducer,
  useState,
} from 'react';
import classNames from 'classnames';

import { NameField } from '@thd-olt-component-react/list-forms';
import { bool, func, number, string } from 'prop-types';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import THDCustomer from '@thd-olt-global/thd-customer';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { Input } from '@thd-olt-component-react/input';
import { Button, Col, Image } from '@thd-olt-component-react/core-ui';
import './favorite.style.scss';
import { dataModel } from './dataModel';

let customerInfoCache;

const initialState = {
  favoriteCount: 0,
  isFavorite: false,
  disableTooltip: false,
  successSave: {
    status: false,
    listId: '',
    listName: ''
  },
  shouldCreateList: false,
  shouldOpenTooltip: false,
  customerInfo: {},
  itemPrice: null,

};

function stateReducer(state, { action, value }) {
  switch (action) {
  case 'increaseFavoriteCount':
    return { ...state, favoriteCount: (state?.favoriteCount + value) };
  case 'setFavoriteCount': return state?.favoriteCount === value
    ? state
    : { ...state, favoriteCount: value };
  case 'isFavorite': return state.isFavorite === value
    ? state
    : { ...state, isFavorite: value };
  case 'disableTooltip': return state.disableTooltip === value
    ? state
    : { ...state, disableTooltip: value };
  case 'successSave':
    return { ...state, successSave: value };
  case 'shouldCreateList': return state.shouldCreateList === value
    ? state
    : { ...state, shouldCreateList: value };
  case 'shouldOpenTooltip': return state.shouldOpenTooltip === value
    ? state
    : { ...state, shouldOpenTooltip: value };
  case 'customerInfo':
    return { ...state, customerInfo: value };
  case 'itemPrice':
    return { ...state, itemPrice: value };
  default:
    return state;
  }
}

/* showAddToListBox prop is a new button type for save to favorites that
makes the button a box vs a circle or heart. */

const Favorite = (props) => {
  const {
    className,
    closeTooltip,
    collectionId,
    collectionImage,
    iconClass,
    itemId,
    label,
    onFavoriteButtonClick,
    pageType,
    quantity,
    showAddToListBox,
    showCircle,
    showCount,
    small,
    storeId: propStoreId,
    vertical
  } = props;
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const { channel, isServer } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const defaultStoreId = useStoreId();
  const storeId = propStoreId || defaultStoreId;
  const theme = useTheme(Favorite, props);
  const { hideCount, showPlusSign } = theme.props;
  const favoritesRef = createRef();
  const tooltipPlacement = showAddToListBox ? 'top' : 'bottom';
  const { customerAccountID, userId, customerType } = state?.customerInfo || {};

  useEffect(() => {
    // THDCustomer getters parse all the cookies in the document, causing a
    // significant performance hit for doing this on every render of Favorite.
    // This caches the data we need from it to fix performance.
    // @TODO: Put the below information into the customer-information hook
    // currently work-in-progress from B2B team.
    customerInfoCache = customerInfoCache || {
      customerAccountID: window.THDCustomer?.default?.svocID,
      userId: window.THDCustomer?.default?.userID,
      customerType: window.THDCustomer?.default?.customerType || 'B2C'
    };
    dispatch({ action: 'customerInfo', value: customerInfoCache });
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('save-to-favorites.ready');
    }
  }, []);

  const opts = {
    ssr: false,
    skip: (!customerAccountID || !userId),
    fetchPolicy: 'cache-first',
    variables: {
      customerAccountID,
      userId,
      customerType
    }
  };
  const {
    data: myListData,
    refetch
  } = useDataModel('listDetails', opts);

  const dispatchAction = useCallback(async (type = 'create', listId = '', listName = '') => {
    if (showCount && !hideCount) {
      dispatch({ action: 'increaseFavoriteCount', value: 1 });
    }
    dispatch({ action: 'isFavorite', value: true });
    dispatch({ action: 'successSave',
      value: {
        status: true,
        listId,
        listName
      } });
    setTimeout(() => {
      // This will auto-close the tooltip after 3 seconds
      dispatch({ action: 'successSave',
        value: {
          status: false
        } });
    }, 5000);

    if (collectionId) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('save-to-favorites.track-add-collection', { collectionId });
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('save-to-favorites.track-add-item',
        { quantity, price: state?.itemPrice || 0, sku: itemId });
    }

    if (type === 'create') {
      dispatch({ action: 'shouldOpenTooltip', value: false });
      dispatch({ action: 'shouldCreateList', value: false });
    }
  }, [collectionId, itemId, state?.itemPrice, showCount, hideCount, quantity]);

  const createList = useCallback(async (listName) => {
    const isListNameEmpty = ['', undefined, null].includes(listName);
    if (isListNameEmpty) {
      return;
    }

    let response = collectionId ? await THDCustomer.createListWithCollection(
      listName,
      customerAccountID,
      collectionId
    ) : await THDCustomer.createListWithItem(
      listName,
      customerAccountID,
      itemId,
      '',
      quantity
    );
    response = JSON.parse(response);
    if (response?.ListResponse?.status === 'SUCCESS' || response?.ListResponseV2?.status === 'SUCCESS') {
      window.LIFE_CYCLE_EVENT_BUS.trigger('save-to-favorites.create-a-list-with-item', {
        creationLocation: 'save to your list overlay',
        quantity,
        price: state?.itemPrice || 0,
        sku: itemId
      });
      const listId = response?.ListResponse?.listId || response?.ListResponseV2?.listId;
      dispatchAction('create', listId, listName);
      refetch();
    }
  }, [collectionId, customerAccountID, itemId, state?.itemPrice, dispatchAction, refetch, quantity]);

  const saveItemToList = useCallback(async (list, event) => {
    const { onClick, onFavoriteChange } = props;
    let response = collectionId ? await THDCustomer.saveCollectionToList(
      list.listName,
      list.listId,
      customerAccountID,
      collectionId
    ) : await THDCustomer.saveItemToList(
      list.listName,
      list.listId,
      customerAccountID,
      itemId,
      '',
      quantity
    );
    response = JSON.parse(response);
    if (response?.ListResponse?.status === 'SUCCESS' || response?.ListResponseV2?.status === 'SUCCESS') {
      dispatchAction('save', list.listId, list.listName);
      if (onFavoriteChange) {
        onFavoriteChange(parseInt(itemId, 0));
      }
      if (onClick) {
        onClick(event, { ...props });
      }
      if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
        window.LIFE_CYCLE_EVENT_BUS.trigger('save-to-favorites.click', { ...props });
      }
    }

  }, [customerAccountID, collectionId, itemId, props, dispatchAction, quantity]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.getURLParameters) {
      const params = window.getURLParameters() || {};
      const autoFavCollection = params.autofavcollection && params.autofavcollection === collectionId;
      const autoFavItem = params.autofavitem && params.autofavitem === itemId;
      if ((autoFavCollection || autoFavItem) && myListData) {
        const { listDetails = [] } = myListData?.listDetails?.listResponse || {};
        if (listDetails.length === 1) {
          let savedAlready = false;
          listDetails[0].items.forEach((item) => {
            if (item.itemId === itemId || item.itemId === collectionId) {
              savedAlready = true;
            }
          });
          if (!savedAlready) {
            saveItemToList(listDetails[0]);
          }
        } else {
          dispatch({ action: 'shouldOpenTooltip', value: true });
        }
      }
    }
  }, [myListData, collectionId, itemId, saveItemToList]);

  useEffect(() => {
    dispatch({ action: 'isFavorite', value: false });
    dispatch({ action: 'setFavoriteCount', value: 0 });
  }, [itemId]);

  if (typeof window !== 'undefined' && window.THDCustomer && myListData && !state?.isFavorite) {
    const { listDetails } = myListData?.listDetails?.listResponse || {};
    (listDetails || []).forEach((list) => {
      const { items } = list;
      if ((items || []).some((item) => item.itemId === itemId)) {
        dispatch({ action: 'isFavorite', value: true });
        if (showCount && !hideCount) {
          dispatch({ action: 'increaseFavoriteCount', value: 1 });
        }
      }
    });
  }

  let iconClassList = classNames(
    iconClass,
    'Favorite-icon',
    {
      'Favorite-icon--selected': state?.isFavorite && !showPlusSign,
      'Favorite-icon--plus--selected': state?.isFavorite && showPlusSign,
      'Favorite-icon--display': !label,
      'Favorite-icon--plus': showPlusSign
    }
  );
  const [iconClasses, setIconClasses] = useState(iconClassList);

  const productDomains = ['media', 'pricing'];
  if (showCount) {
    productDomains.push('favoriteDetail');
  }

  const { data, loading, error } = useProductDeprecated({
    itemId,
    storeId,
    domains: productDomains,
    ssr: false,
    skip: !itemId
  });

  if ((isServer !== loading) || (error && !data)) {
    return null;
  }

  const favoriteCount = data?.product?.favoriteDetail?.count;
  if (showCount && !hideCount && favoriteCount && state?.favoriteCount < favoriteCount) {
    dispatch({ action: 'increaseFavoriteCount', value: favoriteCount });
  }

  const { image } = data?.product?.media || {};
  const imageUrl = image?.url.replace('<SIZE>', '400') || collectionImage;
  if (!state?.itemPrice && data?.product?.pricing?.value) {
    dispatch({ action: 'itemPrice', value: data?.product?.pricing?.value });
  }

  let favoriteCountRoundOff = state?.favoriteCount;
  const maxFavoriteCount = 999;
  if (state?.favoriteCount && state?.favoriteCount > maxFavoriteCount) {
    favoriteCountRoundOff = `${(state?.favoriteCount / 1000).toFixed(1)}k`;
  }
  const circleClasses = classNames('Favorite-wrapper-icon', {
    'Favorite-wrapper--circle': showCircle,
    'Favorite-wrapper--circle-count': state?.favoriteCount > 0
  });

  const buttonClasses = classNames(
    className,
    'Favorite-wrapper',
    (!showAddToListBox ? {
      'Favorite-wrapper__label--horizontal': label && !vertical,
      'Favorite-icon__label--vertical': label && vertical,
      'Favorite-wrapper--plus': showPlusSign,
      'Favorite-wrapper--small': small
    }
      : 'Favorite-wrapper--box')
  );

  const buttonWrapperClasses = classNames(
    {
      'button-box-wrapper': showAddToListBox
    }
  );

  const labelClasses = classNames(
    {
      'Favorite-wrapper__label': !showAddToListBox,
      'Favorite-wrapper__label--no-left-margin': showPlusSign && !showCircle && !showAddToListBox,
      'Favorite-wrapper__label--save-to-favorites': showAddToListBox
    }
  );

  let tooltipInfo = customerInfoCache?.customerType === 'B2B' ? 'Save to Lists' : 'Save to favorites or a new list';
  if (!customerAccountID && customerInfoCache?.customerType === 'B2C') {
    tooltipInfo = 'Sign in to save to favorites or a new list';
  }
  const cancelCreateList = () => {
    dispatch({ action: 'shouldCreateList', value: false });
    dispatch({ action: 'shouldOpenTooltip', value: true });
  };
  const newListClick = () => {
    dispatch({ action: 'shouldCreateList', value: true });
    dispatch({ action: 'shouldOpenTooltip', value: true });
    window.LIFE_CYCLE_EVENT_BUS.trigger('save-to-favorites.create-a-list', {});
  };
  const signInRedirect = (location) => {
    const autoFav = collectionId ? `autoFavCollection=${collectionId}` : `autoFavItem=${itemId}`;
    if (typeof window !== 'undefined' && window.location) {
      const searchParam = window.location.search ? window.location.search + `&${autoFav}` : `?${autoFav}`;
      window.location.href = `/auth/view/${location}?redirect=${window.location.pathname}${searchParam}`;
    }
  };
  const ClickTooltipContent = forwardRef((contentProp, ref) => {
    const { listDetails } = myListData?.listDetails?.listResponse || {};

    const [listNameInputValue, setListNameInputValue] = React.useState('');
    const [listNameDuplicateError, setListNameDuplicateError] = React.useState(null);
    const [listNameValid, setIsListNameValid] = React.useState(true);

    const isDuplicateListName = (value) => {
      if (listDetails === undefined) { return false; }
      let isDuplicate = listDetails.some((list) => { return (list.listName === value); });
      return isDuplicate;
    };

    const handleValidation = ({ value, valid }) => {
      setListNameInputValue(value);
      const isDuplicate = isDuplicateListName(value);

      if (isDuplicate) {
        setListNameDuplicateError('You already have a list with that name. Please enter a different name.');
        setIsListNameValid(false);
        return;
      }

      if (!valid) {
        setIsListNameValid(false);
        return;
      }

      setIsListNameValid(true);
      setListNameDuplicateError(null);
    };

    const handleCreateListKeyPress = (event) => {
      event.preventDefault();
      createList(listNameInputValue);
    };

    const handleListKeyPress = useCallback((list, event) => {
      if (event.code === 'Enter') {
        event.stopPropogation();
        saveItemToList(list, event);
      }
    }, []);

    if (myListData && !state?.successSave.status) {
      return (
        <>
          <ul className="tooltip-content">
            <li className="tooltip-content__add-to">Save to:</li>
          </ul>
          <ul ref={ref} className="tooltip-content tooltip-content--scrollable">
            {
              (listDetails || []).map((list, index) => {
                return (
                  <button
                    key={`list-name-${index}`}
                    onKeyPress={(event) => handleListKeyPress(list, event)}
                    className="tooltip-content__button"
                    type="button"
                    onClick={(event) => saveItemToList(list, event)}
                  >
                    <li className="tooltip-content__list">
                      {list.listName}
                    </li>
                  </button>
                );
              })
            }
          </ul>
          <ul className="tooltip-content">
            {
              state?.shouldCreateList ? (
                <form onSubmit={handleCreateListKeyPress}>
                  <li className="tooltip-content__create-list">
                    <NameField
                      className="tooltip-content__name-field"
                      value={listNameInputValue}
                      onValidation={handleValidation}
                      error={listNameDuplicateError}
                    />
                    <Col fallback="4" className="cancel-button">
                      <Button
                        link
                        type="button"
                        onClick={cancelCreateList}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col fallback="8" className="create-button">
                      <Button
                        outline
                        type="submit"
                        disabled={listNameInputValue === '' || !listNameValid}
                        data-testid="create-button"
                      >
                        Create
                      </Button>
                    </Col>
                  </li>
                </form>
              ) : (
                <button
                  className="tooltip-content__button"
                  type="button"
                  onClick={newListClick}
                  data-testid="newlist-button"
                >
                  <li className="tooltip-content__new-list">
                    <Image
                      className="plus-skinny"
                      src="https://assets.thdstatic.com/images/v1/plus-skinny.svg"
                      alt="plus"
                      width="18"
                      height="18"
                    />New List
                  </li>
                </button>
              )
            }
          </ul>
        </>
      );
    }
    if (state?.successSave?.status) {
      return (
        <>
          <Image
            className="green-check"
            src="https://assets.thdstatic.com/images/v1/alert-check-green.svg"
            alt="green-check"
            width="23"
            height="23"
          />
          Saved to&nbsp;
          <a
            className="list-name"
            href={`/list/view/details/${state?.successSave?.listId}`}
          >
            {state?.successSave?.listName}
          </a>
        </>
      );
    }
    return (
      <div className="sign-in">
        {isMobile
          ? <span>Sign in to save to <br /> favorites or a new list.</span>
          : null}
        <Button outline className="sign-in__button" onClick={() => signInRedirect('signin')}>
          Sign in
        </Button>
        <Button
          className="sign-in__create-account-button"
          onClick={() => signInRedirect('createaccount')}
        >
          Create an Account
        </Button>
      </div>
    );
  });
  const tooltipOnClick = () => {
    dispatch({ action: 'disableTooltip', value: (!state?.disableTooltip) });
    dispatch({ action: 'successSave', value: { status: false } });
    dispatch({ action: 'shouldOpenTooltip', value: false });
    if (!collectionId && window.digitalData?.page?.pageInfo?.pageType === 'pip') {
      window.LIFE_CYCLE_EVENT_BUS.trigger('save-to-favorites.track-click', {});
    }
    window.LIFE_CYCLE_EVENT_BUS.trigger('save-to-favorites.track-overlay', {});
    onFavoriteButtonClick();
  };

  const FavoriteButton = forwardRef((favProps, ref) => {
    return (
      <div className={buttonWrapperClasses} ref={ref}>
        <Tooltip
          channel="desktop"
          closeButton
          content={<ClickTooltipContent />}
          interactive
          placement={tooltipPlacement}
          showClose={false}
          visible={(state?.disableTooltip
            || state?.successSave?.status
            || state?.shouldCreateList
            || state?.shouldOpenTooltip) && !closeTooltip}
        >
          <button
            type="button"
            className={buttonClasses}
            onClick={tooltipOnClick}
            onClose={() => dispatch({ action: 'disableTooltip', value: false })}
            ref={favoritesRef}
            data-testid="favorites"
          >
            {!showAddToListBox && (
              <span className={circleClasses}>
                <span className={iconClassList} />
                {state?.favoriteCount > 0 && (<span className="Favorite-wrapper--count">{favoriteCountRoundOff}</span>)}
              </span>
            )}
            {label && (<span className={labelClasses}>{label}</span>)}
          </button>
        </Tooltip>
      </div>
    );
  });

  if ((!itemId && !collectionId) || (itemId && !data)) {
    return null;
  }

  return (
    <>
      {!isMobile && (
        <Tooltip
          channel="desktop"
          className="saveToListPromptText"
          content={tooltipInfo}
          disableTooltip={state?.disableTooltip}
          interactive
          maxWidth={125}
          placement={tooltipPlacement}
        >
          <FavoriteButton />
        </Tooltip>
      )}
      {isMobile && (
        <FavoriteButton />
      )}
    </>
  );
};

Favorite.propTypes = {
  className: string,
  collectionId: string,
  collectionImage: string,
  closeTooltip: bool,
  iconClass: string,
  itemId: string,
  label: string,
  onClick: func,
  onFavoriteButtonClick: func,
  onFavoriteChange: func,
  pageType: string,
  quantity: number,
  showAddToListBox: bool,
  showCircle: bool,
  showCount: bool,
  small: bool,
  storeId: string,
  vertical: bool,
};

Favorite.defaultProps = {
  className: null,
  collectionId: null,
  collectionImage: null,
  closeTooltip: false,
  iconClass: null,
  itemId: null,
  label: null,
  onClick: null,
  onFavoriteButtonClick: () => {},
  onFavoriteChange: null,
  pageType: null,
  quantity: 1,
  showAddToListBox: false,
  showCircle: false,
  showCount: false,
  small: false,
  storeId: null,
  vertical: false,
};

Favorite.themeProps = {
  hideCount: bool,
  showPlusSign: bool
};

Favorite.defaultThemeProps = {
  hideCount: false,
  showPlusSign: false
};

Favorite.displayName = 'Favorite';

Favorite.dataModel = dataModel;

export { Favorite };
