import {
  string,
  shape,
  params
} from '@thd-nucleus/data-sources';

export const dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSource: string(),
    details: shape({
      installation: shape({
        serviceType: string()
      }),
    }),
    info: shape({
      productSubType: shape({
        name: string()
      })
    }),
  })
};
