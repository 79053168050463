/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Col, Row } from '@thd-olt-component-react/grid';
import { UnstyledList } from '@thd-olt-component-react/list';
import { LinkRefinement } from '../Refinements/LinkRefinement';
import { NestedDimension } from './NestedDimension';
import '../../styles/dimension.scss';

class LinkDimension extends Component {
  static contextType = ExperienceContext; // eslint-disable-line react/static-property-placement

  state = {
    collapsed: false,
    seeAll: false
  };

  componentDidMount() {
    this.setState({ collapsed: this.props.collapsed });
  }

  componentDidUpdate(prevProps) {
    if (this.props.collapsed !== prevProps.collapsed) {
      this.setState({ collapsed: this.props.collapsed }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  handleSeeAll = (event) => {
    event.preventDefault();
    this.setState((state) => {
      return { seeAll: !state.seeAll };
    });
  };

  getRefinementId = (refinement, refinementIndex) => {
    const { refinementKey } = refinement;
    return `${refinementKey}-${refinementIndex}`;
  };

  handleCollapse = (event) => {
    event.preventDefault();
    this.setState((state) => {
      return {
        collapsed: !state.collapsed
      };
    });
  };

  render() {
    const {
      collapsed,
      seeAll
    } = this.state;

    const {
      breadCrumbs,
      drawer,
      dimension,
      onChange,
      multiFacet
    } = this.props;

    const breadCrumbsCategories = breadCrumbs
      .filter((breadCrumb) => (breadCrumb.dimensionName || '') && breadCrumb.dimensionName === 'Category');

    const {
      label: categoryLabel,
      url: categoryUrl
    } = breadCrumbsCategories.pop() || {};

    const {
      dimensionId,
      label,
      refinements
    } = dimension;

    const isCategory = label.toUpperCase() === 'CATEGORY';
    const linkClassName = classNames('dimension__item dimension__item--link', {
      'dimension__item--hidden': !seeAll && !drawer && !isCategory
    });

    const dimensionClassName = classNames('dimension', {
      'dimension--drawer': drawer,
      'dimension--padding': multiFacet
    });

    const borderClassName = classNames('dimension__box', {
      'dimension__box--line': drawer && !collapsed && !multiFacet,
      'dimension__box--padding-right': multiFacet
    });

    const groupClassName = classNames({
      'dimension__link--drawer-scroll': drawer,
      'dimension__link--pad': isCategory
    });

    const dimensionSeeAllClassName = classNames('dimension__see-all', {
      'dimension__see-all--align': isCategory
    });

    const dimensionLabelClassName = classNames('dimension__label', {
      'dimension__label--padding': !drawer,
      'dimension__label--category': isCategory
    });

    const wrapperClassName = classNames({
      'dimension--collapsed': collapsed
    });

    return (
      <div className={dimensionClassName}>
        <Row>
          <Row>
            <a className={borderClassName} href="#" onClick={this.handleCollapse}>
              <Col fallback="9">
                <h2 id="department_list" className="dimension__label" data-group={label}>
                  {`${isCategory ? 'Department' : label}`}
                </h2>
              </Col>
              <Col fallback="3" className="dimension__caret--align">
                <img
                  className={collapsed ? 'dimension__caret--collapsed' : 'dimension__caret'}
                  alt="caret-icon"
                  src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
                  height="15"
                  width="9"
                />
              </Col>
            </a>
          </Row>
          <Row className={wrapperClassName}>
            {breadCrumbsCategories && breadCrumbsCategories.length > 0 && (
              <Col>
                <UnstyledList>
                  {breadCrumbsCategories.map((category, categoryIndex) => {
                    const id = this.getRefinementId(category, categoryIndex);
                    const categoryLinkClassname = classNames('dimension__item dimension__item--category-link');
                    return (
                      <LinkRefinement
                        arrow
                        linkClassName={categoryLinkClassname}
                        dimension={dimension}
                        id={id}
                        key={id}
                        onChange={onChange}
                        refinement={category}
                      />
                    );
                  })}
                </UnstyledList>
              </Col>
            )}
            <Col>
              <a
                href={categoryUrl}
                data-refinementvalue={categoryLabel}
                onClick={(event) => {
                  event.preventDefault();
                  if (onChange) {
                    onChange({ refinement: { label: categoryLabel, url: categoryUrl }, dimension });
                  }
                }}
              >
                <div className={dimensionLabelClassName}>
                  {categoryLabel}
                </div>
              </a>
            </Col>
            {refinements && refinements.length > 0 && (
              <UnstyledList className={groupClassName}>
                {refinements.map((refinement, refinementIndex) => {
                  const id = this.getRefinementId(refinement, refinementIndex);
                  const { nestedRefinements } = refinement;
                  return (
                    <Fragment key={id}>
                      <LinkRefinement
                        linkClassName={linkClassName}
                        dimension={dimension}
                        id={id}
                        onChange={onChange}
                        refinement={refinement}
                      />
                      {isCategory && nestedRefinements && !!nestedRefinements.length && (
                        <NestedDimension
                          id={id}
                          dimension={dimension}
                          refinement={refinement}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </UnstyledList>
            )}
          </Row>
          {!collapsed && refinements && refinements.length > 5 && !drawer && (
            <a
              className={dimensionSeeAllClassName}
              href="#"
              onClick={this.handleSeeAll}
            >
              {isCategory && (
                !seeAll ? '+ See More Departments' : '- See Fewer Departments'
              )}
              {!isCategory && (
                !seeAll ? '+ View All' : '- View Less'
              )}
            </a>
          )}
        </Row>
      </div>
    );
  }
}

LinkDimension.propTypes = {
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
  collapsed: PropTypes.bool,
  drawer: PropTypes.bool,
  dimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    )
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  multiFacet: PropTypes.bool
};

LinkDimension.defaultProps = {
  drawer: false,
  collapsed: false,
  multiFacet: false
};

LinkDimension.displayName = 'LinkDimension';

export { LinkDimension };
