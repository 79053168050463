import { AVATAAR_EXPERIENCE_LINK, AVATAAR_SKUS, IMAGE_TYPES } from '../constants';

class Helper {

  static getAPIState = ({ itemFetchId, productData, collectionFetchId, collectionData } = {}) => {
    const { data, loading, error } = (collectionFetchId ? collectionData : productData) || {};
    const fetchId = itemFetchId || collectionFetchId;

    if (error && !data) return 'error';
    if (!data || (fetchId !== data?.product?.itemId && !data?.collection)) return 'loading';
    if (data && loading) {
      const { product } = data || {};
      const { itemId, media } = product || {};
      const { augmentedRealityLink, images, threeSixty, video } = media || {};
      const hasMedia = images?.length || video?.length || threeSixty?.length || augmentedRealityLink;
      if (hasMedia && fetchId === itemId) {
        return 'partial';
      }
      return 'loading';
    }
    return 'ready';
  };

  static AR_URL = 'https://assets.thdstatic.com/mobile-apps/2d/images/';

  static getStructuredMediaList = ({
    mediaList, size = '145', isThumbnail = false
  }) => {
    return (mediaList || []).map((media) => {
      const { sizes, type, url, videoStill } = media;
      let newUrl = url;
      let newVideoStill = videoStill;
      if (type.match(/IMAGE/ig) || type.match(/HOTSPOTS/ig)) {
        if ((sizes || []).indexOf(size) > -1) {
          newUrl = url.replace(/<SIZE>/ig, size);
        } else if (url && !url.match(/<SIZE>/ig)) {
          // This is meant for Akamai media images that handle auto-sizing on their own
          if (isThumbnail) {
            const mediaUrl = new URL(url);
            mediaUrl.searchParams.set('impolicy', 'thumbnail');
            newUrl = mediaUrl.toString();
          }
        } else {
          return null;
        }
      } else if (type.match(/VIDEO/ig)) {
        if (!url) {
          return null;
        }
        if (!media.videoStill) {
          newVideoStill = 'https://images.thdstatic.com/catalog/productImages/No_Image_145.jpg';
        }
      }
      return {
        ...media,
        url: newUrl,
        videoStill: newVideoStill
      };
    }).filter((media) => !!media
      && (
        Helper.isImage({ media })
        || Helper.isAR({ media })
        || Helper.isVideo({ media })
        || Helper.isImage360({ media })
        || Helper.isHotspot({ media })
        || Helper.isCustomColor({ media })
        || Helper.isManualColor({ media })
      )
    );
  }

  /** *
   * Takes the initial media object containing the 3 media types and returns the array of media items
   * @param media
   * @returns {*[]}
   */
  static getInitialMediaList = ({ media, externalMedia, itemId, showAvataarDefault }) => {
    if (externalMedia) {
      return externalMedia;
    }
    const {
      augmentedRealityLink, images, threeSixty, video, image: mainImage
    } = media || {};
    const videos = (video || []).map((v) => {
      return {
        ...v,
        id: v.videoId
      };
    });
    const hasAvataarItemId = Helper.containAvataarId(itemId);

    // TODO: Remove this once API has AR data - added for Avataar TnT
    let abTestAR = [];
    if (hasAvataarItemId && showAvataarDefault && mainImage?.url) {
      abTestAR = [{
        type: 'AVATAAR_AR',
        mediaStill: mainImage?.url,
        usdz: [],
        image: ''
      }];

    }
    const augmentedReality = !(augmentedRealityLink?.image && augmentedRealityLink?.usdz) ? abTestAR : [{
      ...augmentedRealityLink,
      mediaStill: `${Helper.AR_URL}${augmentedRealityLink.image}`,
      type: 'AUGMENTED_REALITY'
    }];
    const imagesWithIds = (images || []).map((image, imageIndex) => {
      return {
        ...image,
        id: `${image.url.split('/').pop().split('_')[0]}.${imageIndex}`,
        type: image?.type
      };
    });

    const hotspotImages = (images || []).filter((image) => image?.hotspots?.length > 0).map((image, imageIndex) => {
      return {
        ...image,
        id: `${image.url.split('/').pop().split('_')[0]}-hotspot.${imageIndex}`,
        type: 'HOTSPOTS'
      };
    });

    // determine the start position for videos or 360 images
    const position = Math.min(imagesWithIds.length || 0, 2);
    const restOfImages = imagesWithIds.splice(position);

    // TODO: remove when type is added from API team
    const threeSixtyWithType = showAvataarDefault ? []
      : (threeSixty || []).map((item) => {
        return {
          ...item,
          mediaStill: `${item.url}/?thumb&profile=600`,
          type: 'THREESIXTY'
        };
      });

    return [
      ...imagesWithIds,
      ...videos,
      ...threeSixtyWithType,
      ...augmentedReality,
      ...hotspotImages,
      ...restOfImages
    ];
  };

  static isVideo = ({ media = {} }) => {
    return /VIDEO/i.test((media?.type ?? ''));
  };

  static isHotspot = ({ media = {} }) => {
    return (media?.type ?? '') === 'HOTSPOTS';
  };

  static isImage360 = ({ media = {} }) => {
    return (media?.type ?? '') === 'THREESIXTY';
  };

  static isImage = ({ media = {} }) => {
    return /IMAGE/i.test((media?.type ?? ''));
  };

  static isAvataarAR = ({ media = {} }) => {
    const type = (media?.type ?? '');
    return type === 'AVATAAR_AR';
  };

  static isNonAvataarAR = ({ media = {} }) => {
    const type = (media?.type ?? '');
    return type === 'AUGMENTED_REALITY';
  };

  static isAR = ({ media = {} }) => {
    const type = (media?.type ?? '');
    return type === 'AUGMENTED_REALITY' || type === 'AVATAAR_AR';
  };

  static isCustomColor = ({ media = {} }) => {
    return media?.type === 'CUSTOM_COLOR';
  }

  static isManualColor = ({ media = {} }) => {
    return media?.type === 'MANUAL_COLOR';
  }

  static normalizeImage = ({ image, size }) => {
    const { sizes, type, url } = image;
    const imageSize = sizes.indexOf(size) > -1 ? size : sizes.pop();
    return {
      ...image,
      mediaType: type,
      id: url.split('/').pop().split('_')[0],
      url: url.replace('<SIZE>', imageSize)
    };
  };

  static getInteractiveMediaTitle = ({ mediaList }) => {
    const hotspotMediaList = (mediaList || []).filter((med) => med?.type === 'HOTSPOTS');
    let altMediaTitle = 'Related Videos & 360° View';
    if (!!hotspotMediaList?.length && mediaList?.length > hotspotMediaList?.length) { // have other Media in altMedia
      altMediaTitle = 'Related Videos, 360° View & Shop This Look';
    } else if (!!hotspotMediaList?.length
      && mediaList?.length === hotspotMediaList?.length) { // have only hotspots in altMedia Array
      altMediaTitle = 'Shop This Look';
    }
    return altMediaTitle;
  };

  // TODO: Check if this is still needed after AR asset is returned by API
  static normalizeARAsset = ({ product = {}, imageSize = '' }) => {
    const { augmentedRealityLink = [] } = product.media;

    let arAsset = augmentedRealityLink;
    // Normalize AR asset to match media list objects
    if (arAsset) {
      const usdz = arAsset.usdz && arAsset.usdz.length && arAsset.usdz[0];
      const assetLocation = arAsset.image;
      if (assetLocation && usdz) {
        const id = assetLocation.split('_')[0] || null;
        arAsset = {
          id,
          url: Helper.AR_URL + assetLocation,
          usdz,
          isAR: true,
          height: imageSize,
          type: 'AUGMENTED_REALITY'
        };
        return [arAsset];
      }
    }
    return [];
  };

  static isTouchEnabled = () => {
    if (typeof window !== 'undefined') {
      return ('ontouchstart' in window)
        || (navigator.maxTouchPoints > 0)
        || (navigator.msMaxTouchPoints > 0);
    }
    return false;
  }

  static getHighResImages(media, axios) {
    const { id, url } = media || {};
    if (id && url && this.isImage({ media })) {
      const locationArray = url.split('/');
      const fileName = locationArray.pop();
      const extension = fileName.substr(fileName.lastIndexOf('.') + 1);
      const highResImage = {
        ...media,
        url: `${locationArray.join('/')}/${id}_max.${extension}`
      };
      return axios.head(highResImage.url, {}).then(() => {
        return highResImage;
      }).catch(() => Promise.resolve(false));
    }
    return Promise.resolve(false);
  }

  static formatVideoProps(mainAsset) {
    return {
      source: mainAsset.source === 'CONFIGURABLE_S3' ? mainAsset.source : null,
      videoSource: mainAsset.source === 'CONFIGURABLE_S3' ? mainAsset.url : null,
      videoId: mainAsset.source === 'CONFIGURABLE_S3' ? null : mainAsset.videoId
    };
  }

  static containAvataarId = (itemId = null) => {
    const hasItemId = AVATAAR_SKUS.some((product) => {
      return product.itemId === itemId;
    });
    return hasItemId;
  };

  static getAvataarExperienceLink = (itemId = null) => {
    const product = AVATAAR_SKUS.filter((prd) => {
      return prd.itemId === itemId;
    });

    const link = product.length > 0
      ? AVATAAR_EXPERIENCE_LINK + '?productId=' + product[0].productId + '&env=preprod&addToCartEnabled=false&ar=0'
      : '';
    return link;
  };

  static swapPrimaryImageWithSpecifiedImageType = ({ primaryImageUrl, images, imageType }) => {
    const newImages = Array.isArray(images) ? [...images] : [];
    if (imageType) {
      const specifiedImage = newImages.find((image) => image.type === imageType);
      if (specifiedImage) {
        const cleanPrimaryImageUrl = primaryImageUrl?.replace('_1000', '_<SIZE>');
        const primaryImage = newImages.find((image) => image.url === cleanPrimaryImageUrl);
        if (primaryImage) {
          const primaryImageIndex = newImages.findIndex((image) => image.url === cleanPrimaryImageUrl);
          const specifiedImageIndex = newImages.findIndex((image) => image.url === specifiedImage.url);
          newImages[primaryImageIndex] = specifiedImage;
          newImages[specifiedImageIndex] = primaryImage;
        }
      }
    }
    return newImages;
  }

  static getDisplayedMedia = ({
    isMobile,
    itemId = null,
    productData,
    collectionId = null,
    collectionData,
    decisionEngineResponse = {},
    externalMedia = null,
    overrideData = null,
    placeholder = null,
    showAvataarDefault = false,
    pageType = ''
  }) => {
    const displayedMediaReturn = { displayedMedia: {}, apiState: '', returnStatement: null };
    const { media: overrideMedia } = overrideData || {};
    const minimizeImage = isMobile && pageType === 'pep';

    const apiState = overrideMedia ? null : this.getAPIState({
      itemFetchId: itemId, productData, collectionFetchId: collectionId, collectionData
    });

    if (apiState === 'loading') {
      displayedMediaReturn.apiState = apiState;
      displayedMediaReturn.returnStatement = placeholder;
    } else {
      // Construct displayedMedia
      const { product } = productData.data || {};
      const { collection } = collectionData?.data || {};
      const customColor = Helper.getCustomColorImage(product?.paintDetails);
      const manualColor = Helper.getManualColorImage(product?.paintDetails);

      const mediaSource = (collectionId ? collection : product)?.media;
      let mediaList = this.getInitialMediaList({ media: overrideMedia || mediaSource,
        externalMedia,
        itemId,
        showAvataarDefault });
      if (customColor) mediaList.unshift(customColor);
      if (manualColor) mediaList.unshift(manualColor);
      if (decisionEngineResponse.imageType) {
        mediaList = this.swapPrimaryImageWithSpecifiedImageType({
          primaryImageUrl: mediaSource?.image?.url,
          images: mediaList,
          imageType: decisionEngineResponse.imageType
        });
      }
      if (apiState === 'error' || !mediaList?.length) {
        displayedMediaReturn.apiState = apiState;
        displayedMediaReturn.returnStatement = null;
      } else {
        const thumbnailSize = isMobile ? '100' : '145';
        const thumbnails = this.getStructuredMediaList({
          mediaList, size: thumbnailSize, isThumbnail: true
        });
        const main = this.getStructuredMediaList({
          mediaList, size: minimizeImage ? '300' : '600'
        });
        const zoom = this.getStructuredMediaList({
          mediaList, size: '1000'
        });
        const augmentedRealityIndex = thumbnails.length > 0
          ? zoom.findIndex((media) => media.type === 'AUGMENTED_REALITY' || media.type === 'AVATAAR_AR')
          : 0;
        displayedMediaReturn.displayedMedia = {
          thumbnails,
          main,
          zoom,
          augmentedRealityIndex
        };
      }
    }

    return displayedMediaReturn;
  };

  // for swatch Hover
  static getMainSuperSkuHover = ({
    itemId = '',
    superSkuHoveredProductResponse = null,
    collection = null,
    overrideData = null,
    externalMedia = null,
    showAvataarDefault = false,
    superSkuHoverId = ''
  }) => {
    const { media: overrideMedia } = overrideData || {};
    const mediaSourceHover = superSkuHoverId
      ? (collection || superSkuHoveredProductResponse?.data?.product)?.media
      : [];
    const mediaListHover = this.getInitialMediaList({ media: overrideMedia || mediaSourceHover,
      externalMedia,
      itemId,
      showAvataarDefault });
    const mainSuperSkuHover = this.getStructuredMediaList({ mediaList: mediaListHover, size: '600' });
    return mainSuperSkuHover;
  };

  static getImageGuid(image) {
    const { url } = image;

    const baseUrl = /https:\/\/[\w.-]*\/productImages\//;

    return url.replace(baseUrl, '').split('/')[0];
  }

  static getMediaType(media = {}) {
    if (this.isImage({ media })) {
      return 'image';
    }
    if (this.isVideo({ media })) {
      return 'video';
    }
    if (this.isImage360({ media })) {
      return 'threesixty';
    }
    if (this.isAR({ media })) {
      return 'ar';
    }
    if (this.isCustomColor({ media })) {
      return 'custom_color';
    }

    if (this.isManualColor({ media })) {
      return 'manual_color';
    }

    return 'n/a';
  }

  static getCustomColorImage = (paintDetails) => {
    if (!paintDetails?.rgb || paintDetails?.colorType === 'CustomColor') return null;
    const color = paintDetails.rgb;
    return {
      type: 'CUSTOM_COLOR',
      id: `custom-color-${color.red}-${color.green}-${color.blue}`,
      brandLogo: paintDetails.brandLogo,
      rgb: { ...paintDetails.rgb },
      alt: `custom-color-${color.red}-${color.green}-${color.blue}`
    };
  }

  static getManualColorImage = (paintDetails) => {
    if (!paintDetails) return null;
    return (paintDetails?.colorType === 'CustomColor' || !paintDetails?.rgb) && {
      type: 'MANUAL_COLOR',
      id: 'manual-color',
      brandLogo: paintDetails.brandLogo,
      rgb: null,
      alt: 'manual-color'
    };
  }

  static loadAvataarScript() {
    let scripts = [];
    const AVATAAR_AR_SCRIPT_SRC = 'https://orion.avataar.me/enterpriseIntegration/iframeParent/iframe.js';
    if (typeof window !== 'undefined' && window.document) {
      scripts = Array.from(document.getElementsByTagName('script'));
    }
    const loadedAvataarARScript = scripts.find((script) => script.getAttribute('src') === AVATAAR_AR_SCRIPT_SRC);

    if (!loadedAvataarARScript) {
      const script = document.createElement('script');
      script.src = AVATAAR_AR_SCRIPT_SRC;
      script.type = 'text/javascript';
      script.id = 'avataar-script';
      script.async = true;
      document.head.appendChild(script);
    }

    return () => {
      if (loadedAvataarARScript?.parentNode) {
        loadedAvataarARScript.parentNode.removeChild(loadedAvataarARScript);
      }
    };
  }

  static isInternalAR(data) {
    return data ? Helper.isAR({ media: data }) && data?.usdz?.length > 0 : false;
  }

  static armToImageMapping = ({ decision }) => {
    const imageMap = {
      arm_1: IMAGE_TYPES[0],
      arm_2: IMAGE_TYPES[1],
      arm_3: IMAGE_TYPES[2],
      arm_4: IMAGE_TYPES[3]
    };
    return imageMap[decision] || IMAGE_TYPES[0];
  }
}

export default Helper;