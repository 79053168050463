import { useStore } from '@thd-nucleus/experience-context';
import { useKPF } from './useKPF';
import { useRefinements } from './useRefinements';

export const useKPFAndRefinements = ({ itemId, ssr = false }) => {

  const { storeId } = useStore({ varnish: false });

  const {
    products,
    productType,
    navParam,
    name,
    loading: kpfLoading
  } = useKPF({ itemId, storeId });

  const {
    refinements,
    loading: refinementLoading
  } = useRefinements({ navParam, storeId, ssr, skip: !navParam });

  const kpfAndRefinement = {
    products,
    refinements,
    productType,
    navParam,
    name,
    loading: refinementLoading || kpfLoading
  };

  return {
    products,
    refinements,
    productType,
    navParam,
    name,
    loading: refinementLoading || kpfLoading
  };

};

useKPFAndRefinements.displayName = 'useKPFAndRefinements';