import React from 'react';
import { func } from 'prop-types';
import { Pill } from '@one-thd/sui-atomic-components';

export const ShowRelatedItems = ({ showFirstTagProduct }) => {
  const buttonStyle = {
    margin: '-40px auto auto 10px',
    right: '4px'
  };

  const showFirstTagDrawer = () => {
    showFirstTagProduct();
  };

  return (
    <div className="sui-flex sui-flex-row sui-absolute" style={buttonStyle}>
      <Pill
        className="sui-bg-inverse sui-text-inverse sui-font-regular sui-text-base sui-inline-flex sui-rounded-full
          sui-font-w-bold  sui-items-center sui-justify-center sui-h-8 sui-whitespace-nowrap"
        label="Shop Related Items"
        onClick={showFirstTagDrawer}
        role="button"
        aria-label="Show Related Items"
      />
    </div>
  );
};
ShowRelatedItems.displayName = 'ShowRelatedItems';
ShowRelatedItems.propTypes = {
  showFirstTagProduct: func.isRequired
};
