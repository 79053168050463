import React, { useContext } from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames/bind';
import { Price } from '@thd-olt-component-react/price';
import { ProductPodContext } from '../../ProductPodContext';
import styles from './product-price.module.scss';

const cx = classNames.bind(styles);

export const ProductPrice = ({
  hideBulkPrice, hideLimitPerOrder, large
}) => {
  const {
    hideRebates,
    hidePromotions,
    itemId,
    onSavingsCenterToggle,
    product,
    showExactPrice,
    showPreferredPricingBadge,
    showPreferredPricingBadgeToolTip,
    storeId,
    hidePriceBadge,
    hideSavingsText
  } = useContext(ProductPodContext);

  if (product.availabilityType?.discontinued) return null;

  return (
    <div className={cx('product-pod__price')}>
      <Price
        disableRangePricing={showExactPrice}
        hideBadge={hidePriceBadge}
        hideBulkPrice={hideBulkPrice}
        hideLimitPerOrder={hideLimitPerOrder}
        hidePromotions={hidePromotions}
        hideRebates={hideRebates}
        hideSavingsText={hideSavingsText}
        itemId={itemId}
        large={large}
        onSavingsCenterToggle={onSavingsCenterToggle}
        showPreferredPricingBadge={showPreferredPricingBadge}
        showPreferredPricingBadgeToolTip={showPreferredPricingBadgeToolTip}
        storeId={storeId}
      />
    </div>
  );
};

ProductPrice.displayName = 'ProductPrice';

ProductPrice.propTypes = {
  hideBulkPrice: bool,
  hideLimitPerOrder: bool,
  large: bool
};

ProductPrice.defaultProps = {
  hideBulkPrice: false,
  hideLimitPerOrder: false,
  large: true
};
