import React from 'react';

const defaultContextValue = {
  getAisleBayForPod: () => {},
  showAisleBayPods: false,
};

const AisleBayPodsContext = React.createContext(defaultContextValue);

AisleBayPodsContext.displayName = 'AisleBayPodsContext';

export { AisleBayPodsContext };
