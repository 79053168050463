import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { string, oneOfType, number } from 'prop-types';
import { debounce } from '@thd-olt-functional/utils';
import { Button, Heading } from '@thd-olt-component-react/core-ui';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { params, string as stringType, useDataModel } from '@thd-nucleus/data-sources';
import { MapGenerator } from './MapGenerator';
import styles from './store-layout.module.scss';

const cx = classNames.bind(styles);

const StoreLayout = ({
  storeId, storeName, aisle, bay
}) => {
  const mapRef = useRef();
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('store-map.ready'); }, []);
  let map;
  let marker;
  const options = {
    variables: {
      storeId
    }
  };
  const DEFAULT_ZOOM_LVL = 2;
  const MAX_ZOOL_LVL = 8;
  const MIN_ZOOM_LVL = 0;
  const { data, loading, error } = useDataModel('storeLayout', options);
  let { layoutDetails } = data?.storeLayout || {};
  useEffect(() => {
    if (!loading && layoutDetails && mapRef.current) {
      let xData;
      let yData;
      let orientation = (Math.abs(window.orientation) === 90) ? 0 : 90;
      map = new MapGenerator(layoutDetails, {
        wrapper: mapRef.current
      });
      map.draw();
      map.rotate(orientation);
      if (aisle && bay) {
        map.center();
        marker = map.addMarker(aisle, bay);
        xData = parseFloat(marker?.attr('data-x'));
        yData = parseFloat(marker?.attr('data-y'));
        map.zoom(DEFAULT_ZOOM_LVL, { xData, yData, speed: 0 });
        map.drawAisleNames();
        map.drawAisleNumbers();
      } else {
        map.center({ vertical: 'top' });
        map.zoom(DEFAULT_ZOOM_LVL);
      }
    }
    return function () {
      layoutDetails = null;
    };
  }, [loading, layoutDetails]);
  const executeZoomIn = () => {
    if (map.zoomLevel < MAX_ZOOL_LVL) {
      map.zoom(map.zoomLevel + 1);
      if (map.zoomLevel > DEFAULT_ZOOM_LVL) {
        map.drawAisleNumbers();
        map.drawAisleNames();
        if (typeof marker !== 'undefined') {
          map.scaleMarker(marker, 'small', { ease: 'bounce' });
        }
      }
    }
  };
  const executeZoomOut = () => {
    if (map.zoomLevel > MIN_ZOOM_LVL) {
      map.zoom(map.zoomLevel - 1);
      if (map.zoomLevel > DEFAULT_ZOOM_LVL) {
        map.drawAisleNumbers();
        map.drawAisleNames();
        if (typeof marker !== 'undefined') {
          map.scaleMarker(marker, 'small', { ease: 'bounce' });
        }
      } else {
        map.removeAisleNumbers();
        map.removeAisleNames();
        if (typeof marker !== 'undefined') {
          map.scaleMarker(marker, 'large', { ease: 'bounce' });
        }
      }
    }
  };
  const handleZoomIn = debounce(250, executeZoomIn);
  const handleZoomOut = debounce(250, executeZoomOut);

  if (loading) {
    return (
      <LoadingPlaceholder
        type="media"
        rows={6}
        ready={false}
        showLoadingAnimation
      >
        Loading...
      </LoadingPlaceholder>
    );
  }

  return (
    <div className={cx('store-layout')} data-component="StoreLayout">
      <Heading title={`${storeName} # ${storeId}`} />
      <div className={cx('zoom-wrapper')}>
        <Button title="zoom_in" aria-label="zoom in" outline onClick={handleZoomIn} className={cx('zoom-wrapper__btn')}>
          +
        </Button>
        <Button
          title="zoom_out"
          aria-label="zoom out"
          outline
          onClick={handleZoomOut}
          className={cx('zoom-wrapper__btn')}
        >
          -
        </Button>
      </div>
      <div id="storemap-wrapper" ref={mapRef} />
    </div>
  );
};

const propTypes = {
  storeId: string.isRequired,
  storeName: string.isRequired,
  aisle: oneOfType([number, string]),
  bay: oneOfType([number, string])
};

const defaultProps = {
  aisle: null,
  bay: null
};

StoreLayout.displayName = 'StoreLayout';

const dataModel = {
  storeLayout: params({ storeId: stringType().isRequired() }).shape({
    layoutDetails: stringType()
  })
};

StoreLayout.propTypes = propTypes;
StoreLayout.defaultProps = defaultProps;
StoreLayout.dataModel = dataModel;

export { StoreLayout };
