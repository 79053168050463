import { string } from 'prop-types';
import {
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import { useHelmet, TitleTag, MetaTag, LinkTag } from '@thd-nucleus/thd-helmet';
import { getHoistedImgPath, getPrimaryImage } from '../helpers/product-helpers';

const pepMetadataHandler = ({ data }) => {
  if (!data?.product) return null;

  const { identifiers, media = {} } = data.product;
  const primaryImage = getPrimaryImage({ media });
  const title = identifiers?.productLabel || 'The HomeDepot';
  const hoistedImgPath = getHoistedImgPath({ primaryImage });

  const tags = [
    new TitleTag(title),
    new MetaTag({ name: 'robots', content: 'NOINDEX, NOFOLLOW', id: 'robots' }),
    new LinkTag({ rel: 'canonical', href: 'https://www.homedepot.com/', id: 'canonical' }),
    new LinkTag({
      rel: 'preload',
      href: hoistedImgPath,
      id: 'preloadImg',
      as: 'image',
      media: '(max-width: 600px)',
      fetchpriority: 'high'
    }),
  ];
  return tags;
};

const ProductEntryMetadata = ({ itemId }) => {
  const storeId = useStoreId();
  const productOptions = {
    variables: {
      itemId,
      storeId
    },
    alias: 'Metadata'
  };
  const { data, loading, error } = useDataModel('product', productOptions);

  useHelmet('pepMetadata', { data }, pepMetadataHandler, [itemId, storeId]);
  return null;
};

ProductEntryMetadata.displayName = 'ProductEntryMetadata';

ProductEntryMetadata.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shapeType({
      brandName: stringType(),
      canonicalUrl: stringType(),
      itemId: stringType(),
      modelNumber: stringType(),
      productLabel: stringType(),
      storeSkuNumber: stringType(),
      upcGtin13: stringType()
    }),
    media: shapeType({
      image: shapeType({
        url: stringType()
      }).client()
    }),
    pricing: params({ storeId: stringType() }).shape({
      value: numberType({ float: true })
    })
  })
};
ProductEntryMetadata.propTypes = {
  itemId: string.isRequired
};

export { ProductEntryMetadata };
