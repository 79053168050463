/* eslint-disable react/no-unknown-property */
import React, { useContext, useState } from 'react';
import { string as stringType, func, bool as boolType, number as numberType } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  useDataModel, extend, string, shape, params, arrayOf, number, bool
} from '@thd-nucleus/data-sources';
import { Col, Row, } from '@thd-olt-component-react/core-ui';
import { Price } from '@thd-olt-component-react/price';
import { useImpression } from '@thd-olt-component-react/impression';
import { Favorite } from '@thd-olt-component-react/save-to-favorites';
import { ProductImage } from '../product-image/ProductImage';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductPodContext } from '../../ProductPodContext';
import { LikeDislike } from '../like-dislike/LikeDislike';
import {
  LIKE_CLICK,
  INSTANT_RECS_IMAGE_CLICK,
  DISLIKE_CLICK,
  THUMBS_UP_GRAY,
  THUMBS_UP_ORANGE
} from '../../constants';
import { publish } from '../../../analytics';
import './instant-recs-product-pod.style.scss';
import { ProductFavorites } from '../product-favorites/ProductFavorites';

export const InstantRecsProductPod = (props) => {
  const { channel, hosts, onFavoriteChange } = useContext(ExperienceContext);

  const {
    itemId,
    storeId,
    onLikeClick,
    onDislikeClick,
    liked,
    position
  } = props;

  const impressionContextData = useImpression({ data: {
    id: itemId,
    name: 'ProductPod',
    component: 'ProductPod',
    position: position + 1,
    type: 'product'
  } });

  const [isThumbsUpOrange, setIsThumbsUpOrange] = useState(liked);

  const { data, loading, error } = useDataModel('product', {
    variables: {
      itemId,
      storeId,
    },
  });

  const { product } = data || {};

  if (!product) return null;

  const onRecsImageClick = () => (
    publish(INSTANT_RECS_IMAGE_CLICK, {
      sku: itemId,
      position
    })
  );

  const dislikeClick = () => {
    onDislikeClick(itemId);

    publish(DISLIKE_CLICK, {
      sku: itemId,
      position
    });
  };

  const likeClick = () => {
    setIsThumbsUpOrange((prev) => !prev);
    onLikeClick(itemId);

    publish(LIKE_CLICK, {
      sku: itemId,
      position
    });
  };

  const context = {
    ...props,
    product,
    channel,
    hideSavingsText: true,
    onImageClick: onRecsImageClick,
  };

  return (
    <div
      data-automation-id="podnode"
      data-type="product"
      ref={impressionContextData.ref}
      clickid={impressionContextData.clickID}
    >
      <meta data-prop="productID" content={itemId} />
      <ProductPodContext.Provider value={context}>
        <Col className="instant-recs-product-pod">
          <ProductFavorites
            className="product-pod__favorite product-pod__favorite--top-right"
            position="topRight"
            showCircle
            onFavoriteChange={onFavoriteChange}
          />
          <ProductImage />
          <Row className="instant-recs-product-pod__ratings-container">
            <ProductRating />
          </Row>
          <Row className="instant-recs-product-pod__price-container">
            <ProductPrice large={false} />
          </Row>
          <Row>
            <LikeDislike
              onLikeClick={likeClick}
              onDislikeClick={dislikeClick}
              thumbsUpColor={isThumbsUpOrange ? THUMBS_UP_ORANGE : THUMBS_UP_GRAY}
            />
          </Row>
        </Col>
      </ProductPodContext.Provider>
    </div>
  );
};

InstantRecsProductPod.propTypes = {
  itemId: stringType.isRequired,
  storeId: stringType,
  onLikeClick: func.isRequired,
  onDislikeClick: func.isRequired,
  liked: boolType,
  position: numberType.isRequired
};

InstantRecsProductPod.defaultProps = {
  storeId: '',
  liked: false
};

const instantRecsDataModel = extend({
  product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
    itemId: string(),
    dataSources: string(),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        sizes: arrayOf(string()),
        type: string(),
        subType: string(),
      }))
    }),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: string(),
        totalReviews: string()
      })
    }),
    pricing: params({ storeId: string() }).shape({
      value: number(),
      original: number()
    }),
    badges: params({ storeId: string() }).arrayOf(shape({
      name: string(),
    })),
    identifiers: shape({
      canonicalUrl: string(),
      productType: string(),
      productLabel: string(),
    }),
    info: shape({
      isSponsored: bool(),
      sponsoredBeacon: shape({
        onClickBeacon: string(),
        onViewBeacon: string()
      }),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string()
      }),
      globalCustomConfigurator: shape({
        customExperience: string()
      }),
    })
  })
},
Favorite,
Price);

InstantRecsProductPod.dataModel = instantRecsDataModel;

InstantRecsProductPod.displayName = 'InstantRecsProductPod';
