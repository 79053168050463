import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { Favorite } from '@thd-olt-component-react/save-to-favorites';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ProductPodContext } from '../../ProductPodContext';
import './ProductFavorites.style.scss';

export const ProductFavorites = (props) => {
  const {
    label,
    position,
    onFavoriteChange,
    showCircle
  } = props;

  const { channel } = useContext(ExperienceContext);
  const {
    hideFavorites,
    itemId,
    onFavoriteClick,
    pageType,
    showFavoritesCount,
    storeId
  } = useContext(ProductPodContext);

  if (hideFavorites) return null;

  const isMobile = channel === 'mobile';

  const favoriteClasses = className('product-pod__favorite', {
    'product-pod__favorite--mobile': isMobile,
    'product-pod__favorite--top-right': position === 'topRight'
  });

  return (
    <>
      <div className={favoriteClasses}>
        <Favorite
          itemId={itemId}
          label={label}
          onClick={onFavoriteClick}
          onFavoriteChange={onFavoriteChange}
          pageType={pageType}
          position={position}
          showCircle={showCircle}
          showCount={showFavoritesCount}
          storeId={storeId}
        />
      </div>
    </>
  );
};

ProductFavorites.propTypes = {
  label: PropTypes.string,
  /* eslint-disable-next-line */
  favoriteCount: PropTypes.number,
  onFavoriteChange: PropTypes.func,
  position: PropTypes.oneOf(['inline', 'topRight']),
  showCircle: PropTypes.bool
};

ProductFavorites.defaultProps = {
  label: null,
  favoriteCount: 0,
  onFavoriteChange: null,
  position: 'inline',
  showCircle: false
};

ProductFavorites.displayName = 'ProductFavorites';
