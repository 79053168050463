import React from 'react';
import { string } from 'prop-types';
import { Text } from './Text';

export const Image = ({
  src,
  alt,
  size,
  subHeader
}) => {
  return (
    <>
      <img
        className="sui-object-contain sui-self-start"
        src={src}
        alt={alt}
        title={alt}
        height={size}
        width={size}
      />

      {subHeader && (
        <div className="sui-m-2 !sui-mr-0">
          <Text text={subHeader} />
        </div>
      )}
    </>
  );
};

Image.propTypes = {
  src: string.isRequired,
  alt: string.isRequired,
  size: string.isRequired,
  subHeader: string
};

Image.defaultProps = {
  subHeader: ''
};
