import React, { useContext } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { StoreLayout } from '@thd-olt-component-react/store-layout';
import { dataModel } from './dataModel';
import { AisleBayFindMost } from './AisleBayFindMost';
import { AisleBaySingleItem } from './AisleBaySingleItem';

const AisleBay = ({
  itemId, products, centered, clsRemediation, searchKeyword
}) => {
  const { channel } = useContext(ExperienceContext);
  const store = useStore({ varnish: true, online: true });
  if (!itemId?.length && !products?.length) return null;

  return products.length
    ? (
      <AisleBayFindMost
        itemId={itemId}
        store={store}
        products={products}
        searchKeyword={searchKeyword}
      />
    )
    : (
      <AisleBaySingleItem
        itemId={itemId}
        store={store}
        channel={channel}
        centered={centered}
        clsRemediation={clsRemediation}
      />
    );
};

AisleBay.displayName = 'AisleBay';

AisleBay.propTypes = {
  /** An Item that has product highlights */
  itemId: string,
  /** Support for Search / Browse generic Aisle & Bay information */
  products: arrayOf(shape({})),
  // justifies and aligns all text centered
  centered: bool,
  // settings for minimizing cls
  clsRemediation: shape({
    // reserves whitespace while loading
    placeholders: bool,
    // disables removal of reserved whitespace
    preservePlaceholders: bool,
  }),
  // shows searchKeyword on storemap for nativeApp
  searchKeyword: string
};

AisleBay.defaultProps = {
  itemId: null,
  products: [],
  centered: false,
  clsRemediation: {
    placeholders: false,
    preservePlaceholders: false
  },
  searchKeyword: null
};

AisleBay.dataModel = extend({}, dataModel, AisleBayFindMost, StoreLayout);

export { AisleBay };
