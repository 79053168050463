export default {
  601: '601',
  602: '602',
  603: '603',
  604: '604',
  605: '605',
  606: '606',
  610: '610',
  611: '611',
  612: '612',
  613: '613',
  614: '614',
  616: '616',
  617: '617',
  622: '622',
  623: '623',
  624: '624',
  627: '627',
  631: '631',
  636: '636',
  637: '637',
  638: '638',
  641: '641',
  646: '646',
  647: '647',
  650: '650',
  652: '652',
  653: '653',
  656: '656',
  659: '659',
  660: '660',
  662: '662',
  664: '664',
  667: '667',
  669: '669',
  670: '670',
  674: '674',
  676: '676',
  677: '677',
  678: '678',
  680: '680',
  681: '681',
  682: '682',
  683: '683',
  685: '685',
  687: '687',
  688: '688',
  690: '690',
  692: '692',
  693: '693',
  694: '694',
  698: '698',
  703: '703',
  704: '704',
  705: '705',
  707: '707',
  714: '714',
  715: '715',
  716: '716',
  717: '717',
  718: '718',
  719: '719',
  720: '720',
  721: '721',
  723: '723',
  725: '725',
  726: '726',
  727: '727',
  728: '728',
  729: '729',
  730: '730',
  731: '731',
  732: '732',
  733: '733',
  734: '734',
  735: '735',
  736: '736',
  737: '737',
  738: '738',
  739: '739',
  740: '740',
  741: '741',
  742: '742',
  744: '744',
  745: '745',
  751: '751',
  754: '754',
  757: '757',
  766: '766',
  767: '767',
  769: '769',
  771: '771',
  772: '772',
  773: '773',
  777: '777',
  778: '778',
  780: '780',
  782: '782',
  783: '783',
  784: '784',
  785: '785',
  786: '786',
  791: '791',
  792: '792',
  794: '794',
  795: '795',
  901: '901',
  902: '902',
  906: '906',
  907: '907',
  908: '908',
  909: '909',
  910: '910',
  911: '911',
  912: '912',
  913: '913',
  914: '914',
  915: '915',
  916: '916',
  917: '917',
  918: '918',
  919: '919',
  920: '920',
  921: '921',
  922: '922',
  923: '923',
  924: '924',
  925: '925',
  926: '926',
  927: '927',
  928: '928',
  929: '929',
  930: '930',
  931: '931',
  933: '933',
  934: '934',
  935: '935',
  936: '936',
  937: '937',
  939: '939',
  940: '940',
  949: '949',
  950: '950',
  951: '951',
  952: '952',
  953: '953',
  954: '954',
  955: '955',
  956: '956',
  957: '957',
  958: '958',
  959: '959',
  960: '960',
  961: '961',
  962: '962',
  963: '963',
  965: '965',
  966: '966',
  968: '968',
  969: '969',
  970: '970',
  971: '971',
  975: '975',
  976: '976',
  977: '977',
  978: '978',
  979: '979',
  981: '981',
  982: '982',
  983: '983',
  984: '984',
  985: '985',
  986: '986',
  987: '987',
  988: '988'
};