import { useDataModel } from '@thd-nucleus/data-sources';

export const useKPF = ({ itemId, storeId, ssr = false }) => {

  const { data, loading } = useDataModel('product', { variables: { itemId } });
  if (!data) return { loading };

  const product = data?.product || {};
  const crumbs = product.taxonomy?.breadCrumbs;

  const items = product.keyProductFeatures?.keyProductFeaturesItems || {};
  const item = items?.length ? items[0] : {};
  const features = item.features || [];

  const lastCrumb = crumbs?.length ? crumbs[crumbs?.length - 1] : {};
  const browsePath = (lastCrumb.url || '').replace(/.*\.com/, '');

  const products = features
    .filter((feature) => feature.refinementId)
    .map((feature) => {
      const { name = '', value = '', refinementId } = feature;
      const encoded = parseInt(refinementId, 10).toString(36);
      const path = `${browsePath}Z${encoded}`;
      return {
        label: `${name} - ${value}`,
        url: path
      };
    })
    .filter((feature, featureIndex) => {
      // limit to 3 refinements per story
      return featureIndex < 3;
    });

  const productType = crumbs?.length > 1 ? crumbs[crumbs?.length - 1].label : 'Similar Products';

  const capped = crumbs?.slice(0, 2);
  const l3Crumb = capped?.length === 2
    ? crumbs[capped?.length - 1]
    : null;

  const url = l3Crumb?.url || '';

  // "/b/Furniture-Living-Room-Furniture-Sectionals/N-5yc1vZceuf"
  const val = /\/N-5yc1vZ(\w+)/.exec(url);

  const navParam = val && val?.length
    ? `5yc1vZ${val[1]}`
    : '';

  const name = l3Crumb?.label || '';

  return {
    products,
    productType,
    navParam,
    name,
    loading
  };
};

useKPF.displayName = 'useKPF';
