export const UUID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return 'ss-s-s-s-sss'.replace(/s/g, s4);
};

export const getHost = ({ env = 'prod', ssr = true, https = true }) => {
  const prefix = https ? 'https:' : '';

  const clientHost = `${prefix}//www.homedepot.com`;
  const serverDefaultHost = `${prefix}//www.edge-pr.homedepot.com`;
  const devHost = `${prefix}//hd-qa71.homedepotdev.com`;
  const devServer = `${prefix}//www.edge-dev.homedepot.com`;
  const stageHost = `${prefix}//hd-qa74.homedepotdev.com`;
  const stageServer = `${prefix}//www.edge-stage.homedepot.com`;
  const betaServer = `${prefix}//www-beta.edge-pr.homedepot.com`;

  const runtime = (typeof window !== 'undefined' || !ssr) ? 'client' : 'server';

  switch (`${env}:${runtime}`) {
    case 'prod:client':
      return clientHost;
    case 'prod:server':
      return serverDefaultHost;
    case 'prod-beta:client':
      return clientHost;
    case 'prod-beta:server':
      return betaServer;
    case 'dev:client':
      return devHost;
    case 'dev:server':
      return devServer;
    case 'stage:client':
      return stageHost;
    case 'stage:server':
      return stageServer;
    default:
      return clientHost;
  }
};

export default {
  UUID,
  getHost
};
