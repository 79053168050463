import {
  alias,
  arrayOf as arrayType,
  bool as boolType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';

const sampleProductPageLoad = shapeType({
  availabilityType: shapeType({
    discontinued: boolType()
  }),
  dataSource: stringType(),
  identifiers: shapeType({
    itemId: stringType()
  }),
  fulfillment: params({ storeId: stringType(), zipCode: stringType() }).shape({
    fulfillmentOptions: arrayType(shapeType({
      fulfillable: boolType()
    }))
  }),
  pricing: params({ storeId: stringType() }).shape({
    value: numberType({ float: true })
  })
});

export const dataModel = {
  product: params({ itemId: stringType().isRequired(), dataSource: stringType() })
    .shape(shapeType({
      dataSource: stringType(),
      identifiers: shapeType({
        itemId: stringType(),
        isSuperSku: boolType(),
        parentId: stringType(),
        sampleId: stringType()
      }),
      specificationGroup: arrayType(shapeType({
        specifications: arrayType(shapeType({
          specName: stringType(),
          specValue: stringType()
        })),
        specTitle: stringType()
      }))
    })),
  sampleProduct: alias('product')
    .params({ itemId: stringType().isRequired(), dataSource: stringType() })
    .shape(sampleProductPageLoad),
  sampleSearchModel: alias('searchModel')
    .params({ itemIds: arrayType(stringType().isRequired()).isRequired() })
    .arrayOf(shapeType({
      products: arrayType(sampleProductPageLoad)
    })),
  sampleProducts: alias('products')
    .params({ itemIds: arrayType(stringType().isRequired()).isRequired() })
    .arrayOf(shapeType({
      availabilityType: shapeType({
        discontinued: boolType()
      }),
      identifiers: shapeType({
        itemId: stringType()
      }),
      fulfillment: params({ storeId: stringType(), zipCode: stringType() }).shape({
        fulfillmentOptions: arrayType(shapeType({
          fulfillable: boolType()
        }))
      }),
      media: shapeType({
        images: arrayType(shapeType({
          sizes: arrayType(stringType()),
          subType: stringType(),
          url: stringType()
        }))
      }),
      pricing: params({ storeId: stringType() }).shape({
        value: numberType({ float: true })
      }),
      specificationGroup: arrayType(shapeType({
        specifications: arrayType(shapeType({
          specName: stringType(),
          specValue: stringType()
        })),
        specTitle: stringType()
      }))
    })),
  metadata: params({ parentId: stringType().isRequired() }).shape({
    attributes: arrayType(shapeType({
      attributeName: stringType(),
      attributeValues: arrayType(shapeType({
        value: stringType()
      })),
      isSwatch: boolType()
    })),
    childItemsLookup: arrayType(shapeType({
      itemId: stringType(),
      attributeCombination: stringType()
    })),
    sampleProductDetail: arrayType(shapeType({
      itemId: stringType(),
      sampleId: stringType()
    }))
  })
};