import { useEffect, useState } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { getUncalledUniqueSampleIdsFromProducts } from '../helpers';

import { SAFE_CLIENT_SEARCHMODEL_LIMIT } from '../constants';

export const usePLPSamples = ({
  products,
  productsFinishedLoading,
  storeId,
  storeZip
}) => {
  const [currentSamplePage, setCurrentSamplePage] = useState(0);
  const pageStart = currentSamplePage * SAFE_CLIENT_SEARCHMODEL_LIMIT;
  const pageEnd = pageStart + SAFE_CLIENT_SEARCHMODEL_LIMIT;
  let sampleProductIds = [];
  if (productsFinishedLoading) {
    sampleProductIds = getUncalledUniqueSampleIdsFromProducts(products);
  }
  const totalSamplePages = Math.ceil(sampleProductIds.length / SAFE_CLIENT_SEARCHMODEL_LIMIT) - 1;
  
  const sampleProductsOptions = {
    skip: !sampleProductIds?.length,
    ssr: false,
    variables: {
      itemIds: sampleProductIds.slice(pageStart, pageEnd),
      storeId,
      zipCode: storeZip
    }
  };
  const samples = useDataModel('sampleSearchModel', sampleProductsOptions);

  useEffect(() => {
    if (samples.data?.searchModel?.products) {
      if (currentSamplePage !== totalSamplePages) {
        setCurrentSamplePage(currentSamplePage + 1);
      }
    }
  }, [samples.data?.searchModel?.products]);

  return {
    loading: samples.loading || currentSamplePage < totalSamplePages
  };
};