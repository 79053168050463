import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ProductResultsContext } from './ProductResultsContext';
import '../styles/results-wrapped.scss';

const ResultsWrapped = (props) => {

  const { children = null } = props;
  const { data, loading } = useContext(ProductResultsContext);
  const wrappedClasses = classNames('results-wrapped', {
    'results-wrapped--fade': (loading && data)
  });
  const displayMessage = !loading
    && data
    && data?.searchModel?.metadata
    && !data?.searchModel?.metadata.searchRedirect
    && data?.searchModel?.products?.length === 0;
  return (
    <div className={wrappedClasses} data-component="ResultsWrapped">
      {displayMessage
        ? (
          <div className="results-wrapped--no-products">
            Sorry, there are no products available online or in your local store.
            Please use our Store Finder to select another local store.
          </div>
        )
        : children}
    </div>
  );
};

ResultsWrapped.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ResultsWrapped.defaultProps = {
  children: null
};

ResultsWrapped.displayName = 'ResultsWrapped';

export { ResultsWrapped };
