import React from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { addListItemClass } from './List.component';

const UnstyledList = (props) => {

  const { children, className } = props;

  const classes = classNames(
    className,
    'list',
    'list--type-plain'
  );

  return (
    <ul className={classes}>
      { addListItemClass(children, 'list__item list__item--padding-none') }
    </ul>
  );
};

UnstyledList.propTypes = {
  children: oneOfType([
    // List Items are modified to add classes so can't set specific type
    node,
    arrayOf(node)
  ]).isRequired,
  /** Additional class names */
  className: string
};

UnstyledList.defaultProps = {
  className: null
};

export default UnstyledList;
