import React from 'react';
import PropTypes from 'prop-types';
import { Col } from '@thd-olt-component-react/grid';
import './ToggleSwitch.styles.scss';

const ToggleSwitch = (props) => {
  const {
    children,
    checked,
    className,
    disabled,
    forwardedRef,
    id,
    onChange
  } = props;

  return (
    <Col className={className}>
      <div className="toggle-switch-btn">
        <input
          className="toggle-switch-btn__input"
          checked={checked}
          disabled={disabled}
          id={id}
          onChange={onChange}
          ref={forwardedRef}
          type="checkbox"
        />
        { /* eslint-disable-next-line */}
        <label className="toggle-switch-btn__label" htmlFor={id}>
          <span className="toggle-switch-btn__knob" />
        </label>
        {children && (
          <span className="toggle-switch-btn__name">
            {children}
          </span>
        )}
      </div>
    </Col>
  );
};

ToggleSwitch.propTypes = {
  /** Optional text to right of toggle switch */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /** HTML attribute, if true then render toggle switch as checked */
  checked: PropTypes.bool,
  className: PropTypes.string,
  /** HTML attribute, if true then make toggle switch grey and unclickable */
  disabled: PropTypes.bool,
  /** Ref forwarded from parent */
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  onChange: PropTypes.func.isRequired
};

ToggleSwitch.defaultProps = {
  children: null,
  checked: false,
  className: null,
  disabled: false,
  forwardedRef: null
};

export default ToggleSwitch;
