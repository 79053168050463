import { bool, number } from '@thd-nucleus/data-sources/react/dataModel/prop-types';
import {
  alias, params,
  arrayOf as arrayType,
  shape as shapeType,
  string as stringType,
  bool as boolType
} from '@thd-nucleus/data-sources';

let AisleBayModel = params({
  itemId: stringType().isRequired(),
  storeId: stringType().isRequired(),
  aisleBayText: stringType().isRequired(),
  phoneNumber: stringType().isRequired(),
  isSavingsSubscribed: bool()
});

// ds21 does not have .mutation
// @todo remove check when on 22
// update: Currently waiting on ProductPod, also other components where this
//   component is used also need to be on ds22 for their tests to work.
if (AisleBayModel.mutation) {
  AisleBayModel = AisleBayModel.mutation().shape({
    result: stringType()
  });
} else {
  AisleBayModel = AisleBayModel.shape({
    result: stringType()
  });
}

export const dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    identifiers: shapeType({
      productType: stringType(),
      storeSkuNumber: stringType(),
      brandName: stringType(),
      productLabel: stringType()
    }),
    availabilityType: shapeType({
      type: stringType()
    }),
    media: shapeType({
      image: shapeType({
        url: stringType()
      }).client()
    })
  }),
  clientOnlyProduct: alias('product').params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    fulfillment: params({ storeId: stringType(), zipCode: stringType() }).shape({
      anchorStoreStatus: boolType(),
      anchorStoreStatusType: stringType(),
      fulfillmentOptions: arrayType(shapeType({
        type: stringType(),
        services: arrayType(shapeType({
          type: stringType(),
          locations: arrayType(shapeType({
            isAnchor: bool(),
            type: stringType(),
            storeName: stringType(),
            storePhone: stringType(),
            inventory: shapeType({
              isInStock: bool(),
              quantity: number(),
              isLimitedQuantity: bool()
            })
          }))
        }))
      }))
    }),
  }),
  aislebay: params({
    storeId: stringType().isRequired(),
    storeSkuIds: arrayType(stringType().isRequired())
  }).shape({
    storeSkus: arrayType(shapeType({
      storeNumber: stringType(),
      storeSkuId: stringType(),
      aisleBayInfo: shapeType({
        aisle: stringType(),
        bay: stringType(),
        invLocDesc: stringType(),
        invLocDescFriendly: stringType(),
      })
    }))
  }),
  storeDetails: params({ storeId: stringType().isRequired() }).shape({
    address: shapeType({
      street: stringType(),
      city: stringType(),
      state: stringType(),
      country: stringType(),
      postalCode: stringType(),
    })
  }),
  aisleBayTextMe: AisleBayModel
};