import React from 'react';
import { string, arrayOf } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources/react/dataModel/useDataModel';
import { WarningFilled } from '@one-thd/sui-icons';
import { Link } from '@one-thd/sui-atomic-components';
import { dataModel } from './dataModel';
import { VISIT_PRO_DESK, HOME_DEPOT_QUOTE_CENTER } from './constants';

const NonBuyableQuoteCenter = ({ itemId, checkForProductSubType }) => {
  const opts = {
    variables: {
      itemId
    },
    skip: !itemId
  };
  const { data, loading, error } = useDataModel('product', opts);

  if (!data) {
    return null;
  }

  const { details, info } = data?.product || {};
  const { serviceType } = details?.installation || {};
  const { name } = info?.productSubType || {};

  let isQuoteCenter = serviceType === HOME_DEPOT_QUOTE_CENTER;

  if (checkForProductSubType?.includes('HDQC')) {
    isQuoteCenter = name === HOME_DEPOT_QUOTE_CENTER;
  }

  if (!isQuoteCenter) {
    return null;
  }

  return (
    <div className="sui-border-1 sui-border-solid sui-border-warning sui-p-4">
      <div className="sui-align-baseline">
        <WarningFilled color="warning" size="small" />
        <span className="sui-text-xs sui-font-bold sui-pl-1">Special Order</span>
      </div>
      <div className="sui-text-xs sui-mt-2 sui-mb-2">{VISIT_PRO_DESK}</div>
      <div>
        <span className="sui-pr-1 sui-text-xs">Call</span>
        <Link underline="always" variant="body-xs" href="tel:1-844-776-7768" weight="bold">1-844-776-7768</Link>
      </div>
    </div>
  );
};

NonBuyableQuoteCenter.propTypes = {
  itemId: string.isRequired,
  checkForProductSubType: arrayOf(string)
};

NonBuyableQuoteCenter.defaultProps = {
  checkForProductSubType: null
};

NonBuyableQuoteCenter.displayName = 'NonBuyableQuoteCenter';

NonBuyableQuoteCenter.dataModel = dataModel;

export { NonBuyableQuoteCenter };
