import {
  params,
  arrayOf as arrayType,
  shape as shapeType,
  string as stringType,
  bool as boolType,
  number,
  client
  , shape
} from '@thd-nucleus/data-sources';

export const dataModel = {
  product: params({
    itemId: stringType().isRequired(),
    dataSource: stringType()
  }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shape({ storeSkuNumber: stringType() }),
    fulfillment: client(params({ storeId: stringType(), zipCode: stringType() }).shape({
      fulfillmentOptions: arrayType(shapeType({
        type: stringType(),
        services: arrayType(shapeType({
          type: stringType(),
          locations: arrayType(shapeType({
            isAnchor: boolType(),
            type: stringType(),
            inventory: shapeType({
              isInStock: boolType(),
              quantity: number()
            })
          }))
        }))
      }))
    })),
  })
};
