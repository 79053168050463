import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../typography/Typography';
import createTypography from '../utils/createTypography';

/**
 * A component to render primary and secondary text inside of `MenuItem`.
 *
 * Related components: [Dropdown](#dropdown), [DropdownField](#dropdownfield),
 * [NativeDropdown](#nativedropdown), [DynamicDropdown](#dynamicdropdown), [MenuList](#menulist)
 *
 * Usage:
 *
 * ```jsx
 * import { ListItemText } from '@one-thd/sui-atomic-components';
 * ```
 */
const ListItemText = React.forwardRef((props, ref) => {

  const {
    children,
    component: ListItemTextRoot = 'div',
    disableTypography = false,
    primary: primaryProp,
    primaryTypographyProps,
    secondary: secondaryProp,
    secondaryTypographyProps,
    ...other
  } = props;

  let primary = primaryProp != null ? primaryProp : children;
  let secondary = secondaryProp;

  const primaryTypography = createTypography({
    color: 'primary',
    ...primaryTypographyProps,
  });

  const secondaryTypography = createTypography({
    variant: 'body-xs',
    color: 'subtle',
    ...secondaryTypographyProps,
  });

  let PrimaryElement = primaryTypographyProps?.variant ? primaryTypography.element : 'span';
  let SecondaryElement = secondaryTypography.element;

  if (primary != null && primary.type !== Typography && !disableTypography) {
    primary = (
      <PrimaryElement display="block" className={`${primaryTypography.classes} group-focus-visible/sui-menu-item:sui-text-inverse`}>
        {primary}
      </PrimaryElement>
    );
  }

  if (secondary != null && secondary.type !== Typography && !disableTypography) {
    secondary = (
      <SecondaryElement display="block" className={`${secondaryTypography.classes} group-focus-visible/sui-menu-item:sui-text-inverse`}>
        {secondary}
      </SecondaryElement>
    );
  }

  return (
    <ListItemTextRoot
      className="sui-flex-auto sui-my-1 sui-block"
      ref={ref}
      {...other}
    >
      {primary}
      {secondary}
    </ListItemTextRoot>
  );
});

ListItemText.displayName = 'ListItemText';

ListItemText.labelProp = 'primary';

ListItemText.propTypes = {
  /**
   * Alias for the `primary` prop.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the children won't be wrapped by a Typography component.
   * This can be useful to render an alternative Typography variant by wrapping
   * the `children` (or `primary`) text, and optional `secondary` text
   * with the Typography component.
   * @default false
   */
  disableTypography: PropTypes.bool,
  /**
   * The main content element.
   */
  primary: PropTypes.node,
  /**
   * These props will be forwarded to the primary typography component
   * (as long as disableTypography is not `true`).
   */
  primaryTypographyProps: PropTypes.object,
  /**
   * The secondary content element.
   */
  secondary: PropTypes.node,
  /**
   * These props will be forwarded to the secondary typography component
   * (as long as disableTypography is not `true`).
  */
  secondaryTypographyProps: PropTypes.object
};

ListItemText.defaultProps = {};

export { ListItemText };
