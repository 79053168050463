import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@thd-olt-component-react/grid';
import { Alert } from '@thd-olt-component-react/alert';
import { Button } from '@thd-olt-component-react/button';
import { Image } from '@thd-olt-component-react/core-ui';
import { Radio } from '@thd-olt-component-react/checkbox';
import {
  arrayOf,
  params,
  string,
  shape,
  number,
  useDataModel
} from '@thd-nucleus/data-sources';

const ChangeStore = (props) => {
  const [storeIndex, setStoreIndex] = useState(0);
  const [stores, setStores] = useState(props.stores); // eslint-disable-line react/destructuring-assignment
  // storeLocation teated as postalCode if user entered 5 digit number otherwise will treat as store Id.
  const [storeLocation, setStoreLocation] = useState(props.stores[0]?.address?.postalCode || null); // eslint-disable-line

  const { onChangeClick } = props;

  const [getStoreSearch, { data, loading, error }] = useDataModel('storeSearch', { opts: {} }, { lazy: true });
  const [getStoreDetails, storeResponse] = useDataModel('storeDetails', { opts: {} }, { lazy: true });

  const onSubmit = useCallback((event) => {
    if (event) {
      event.preventDefault();
    }
    if (storeLocation.length === 5) {
      getStoreSearch({
        variables: {
          zipCode: storeLocation,
          limit: 5,
          radius: 50
        }
      });
    } else {
      getStoreDetails({
        variables: {
          storeId: storeLocation
        }
      });
    }
  }, [storeLocation, getStoreDetails, getStoreSearch]);

  useEffect(() => {
    if ((storeResponse.error && !storeResponse.data) || !storeResponse.data || storeResponse.loading) {
      return;
    }
    const zipCode = storeResponse?.data?.storeDetails?.address?.postalCode;
    if (zipCode) {
      getStoreSearch({
        variables: {
          zipCode,
          limit: 5,
          radius: 50
        }
      });
    } else {
      setStoreIndex(0);
      setStores(null);
    }

  }, [storeResponse, getStoreSearch]);

  useEffect(() => {
    if ((error && !data) || !data || loading) {
      return;
    }

    setStoreIndex(0);
    setStores(data.storeSearch);

  }, [data, error, loading]);

  const onChangePreferredStore = () => {
    if (!storeIndex && storeIndex !== 0) {
      onChangeClick();
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('product-results.change-store', stores[storeIndex]);
    }
  };

  const onChangeStoreIndex = (index) => {
    setStoreIndex(index);
  };

  const onUpdateZipCode = (event) => {
    setStoreLocation(event.target.value);
  };

  const onCancel = () => {
    setStoreIndex(0);
    onChangeClick();
  };

  return (
    <div className="change-store">
      <Row>
        <form id="prc-store-search-form" className="change-store__form" onSubmit={onSubmit}>
          <div className="change-store__input">
            <input
              className="form-input__field"
              type="text"
              defaultValue={storeLocation}
              onChange={onUpdateZipCode}
            />
            <Button
              className="change-store__button"
              inline
              type="submit"
              form="prc-store-search-form"
            >
              <Image
                alt="magnifying glass"
                className="change-store__magnifying-glass"
                src="https://assets.thdstatic.com/images/v1/magnify-symbol-white.svg"
                height="40"
                width="40"
              />
            </Button>
          </div>
        </form>
      </Row>
      {!stores && (
        <Row>
          <Col>
            <Alert
              inline
              message="Please enter a valid ZIP Code or store number"
            />
          </Col>
        </Row>
      )}
      {stores && stores.length > 0 && (
        <>
          <Row>
            <div className="change-store__radio-wrapper">
              <div className="change-store__radio-wrapper-title">Choose your preferred store: </div>
              <Radio.Group className="change-store__radio-group">
                {stores.map((store, index) => {
                  const { distance, storeId, storeName } = store;
                  const miles = distance ? (Math.round(distance * 100) / 100) : '0.0';
                  const label = `${storeName} (${miles} mi)`;
                  return (
                    <Radio
                      className="change-store__radio-button"
                      id={`${storeId}-${index}`}
                      key={`${storeId}-${index}`}
                      onChange={() => onChangeStoreIndex(index)}
                      value={index.toString()}
                      checked={index === 0}
                    >
                      {label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          </Row>
          <Row className="change-store__buttons">
            <div className="change-store__save">
              <Button onClick={onChangePreferredStore}>Save</Button>
            </div>
            <div className="change-store__cancel">
              <Button outline onClick={onCancel}>Cancel</Button>
            </div>
          </Row>
        </>
      )}
    </div>
  );
};

ChangeStore.propTypes = {
  onChangeClick: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      storeId: PropTypes.string,
      storeName: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.shape({
        postalCode: PropTypes.string
      })
    })
  ).isRequired,
};

ChangeStore.displayName = 'ChangeStore';

const storeDetail = {
  storeId: string(),
  storeName: string(),
  distance: string(),
  address: shape({
    postalCode: string()
  })
};

ChangeStore.dataModel = {
  storeSearch: params({
    zipCode: string().isRequired(),
    radius: number({ float: true }).isRequired(),
    limit: number()
  }).shape(arrayOf(storeDetail)),
  storeDetails: params({
    storeId: string()
  }).shape(storeDetail)
};

export { ChangeStore };
