import React from 'react';
import { string, bool } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export const Text = ({ text, isTitle, isTiny }) => {
  return (
    isTitle ? (
      <Typography
        variant="h3"
        weight="bold"
        height="tight"
      >
        {text}
      </Typography>
    ) : (
      <Typography
        variant={`body-${isTiny ? 'xs' : 'base'}`}
        weight="regular"
        height={isTiny ? 'tight' : 'normal'}
      >
        {text}
      </Typography>
    )
  );
};

Text.propTypes = {
  text: string.isRequired,
  isTitle: bool,
  isTiny: bool
};

Text.defaultProps = {
  isTitle: false,
  isTiny: false
};
