import { getDomPath } from '@thd-olt-functional/utils';
import {
  BADGE_CLICKTHROUGH,
  PRODUCT,
  PRODUCT_POD_ATC,
  PRODUCT_POD_CLICK,
  PRODUCT_POD_IRG_ATC
} from './components/constants';
import { getAtcInfo } from './components/utils';

export const publish = (eventName, data) => {
  if (typeof window !== 'undefined') {
    window.LIFE_CYCLE_EVENT_BUS.trigger(eventName, data);
  }
};

export const publishAnalyticsEvent = ({
  eventName,
  action,
  analyticsData,
  itemId,
  productPodRef,
  ignoreAnalytics,
  pageType,
  podType,
  position,
  product,
  pageNumber,
  podCount,
  state,
  target,
  irgSectionName,
  recommendationVersion = 'n/a',
  setState
}) => {
  if (ignoreAnalytics) return;
  let eventData;

  if (eventName === PRODUCT_POD_ATC) {
    const isTableView = state?.isTableView;
    eventData = {
      ...(analyticsData || {}),
      ...getAtcInfo({
        pageType, podType, position, product, isTableView
      }),
      path: getDomPath(productPodRef, { attributes: ['id'] })
    };
  } else if (eventName === PRODUCT_POD_IRG_ATC) {
    eventData = {
      sku: itemId,
      displayPosition: position + 1
    };
  } else {
    eventData = {
      podPageNumber: pageNumber,
      podPosition: position + 1,
      podType: PRODUCT,
      podPageResults: podCount,
      podAction: action,
      podInteractionNumber: state?.podInteractionNumber,
      podAnchorSku: itemId,
      recommendationVersion,
      sponsoredProduct: !!(state?.sponsoredValues?.isSponsored),
      sponsoredClickBeacon: state?.sponsoredValues?.sponsoredBeacon?.onClickBeacon,
      sponsoredMetadata: state?.sponsoredValues?.sponsoredMetadata,
      target,
      badges: product?.badges,
      path: getDomPath(productPodRef, { attributes: ['id'] })
    };
    setState({
      ...state,
      podInteractionNumber: state.podInteractionNumber + 1
    });
  }

  if ((eventName === PRODUCT_POD_CLICK || eventName === PRODUCT_POD_ATC) && product?.badges?.length) {
    publish(BADGE_CLICKTHROUGH, { badges: product.badges, pageType });
  }
  if (analyticsData?.irgSectionName || irgSectionName) {
    eventData.irgSectionName = analyticsData?.irgSectionName || irgSectionName;
  }
  publish(eventName, eventData);
};
