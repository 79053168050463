export const lengthRE = /Product Length/i;
export const heightRE = /Product Height/i;
export const widthRE = /Product Width/i;
export const SAFE_PRODUCTS_LIMIT = 16;
export const SAFE_CLIENT_SEARCHMODEL_LIMIT = 24;
export const ITEM_DOMAINS = ['identifiers'];
export const SAMPLE_DOMAINS = [
  'identifiers',
  'specificationGroup',
  'availabilityType',
  'media',
  'fulfillment',
  'pricing'
];
