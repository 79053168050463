import React, { useEffect, useRef, useState } from 'react';
import { func, string } from 'prop-types';
import { Input } from '@thd-olt-component-react/input';
import styles from './name-field.module.scss';
import { getValidationError, isNameFieldValid } from '../helpers';

export const NameField = (props) => {
  const {
    error, onValidation, value, label, placeholder, className
  } = props;
  const [name, setName] = useState(value);
  const [fieldError, setFieldError] = useState(null);
  const externalErrorRef = useRef();
  // hack b/c input doesn't pass ref down
  const triggerValidation = () => {
    const inputElements = document.getElementsByClassName('name-field-input');
    const [field] = inputElements || [];
    if (field) {
      const inputElement = field.closest('input');
      inputElement.focus();
      inputElement.blur();
    }
  };

  useEffect(() => {
    if (error) {
      externalErrorRef.current = {
        nameValue: name,
        error,
      };
      triggerValidation();
    }
  }, [error]);

  const onBlur = (blur) => {
    if (blur === externalErrorRef.current?.nameValue) {
      setFieldError(externalErrorRef.current?.error);
    }
  };

  const fieldValidation = (fieldValue) => {
    const sameValue = fieldValue === externalErrorRef.current?.nameValue;
    const externalError = sameValue ? externalErrorRef.current?.error : null;
    const { error: validationError } = getValidationError({ value: fieldValue, externalError }) || {};
    setName(fieldValue || '');
    setFieldError(validationError || '');
    if (onValidation) {
      onValidation({
        value: fieldValue,
        valid: !validationError,
      });
    }
  };

  const determineNameValidity = (inputValue) => {
    const unchanged = inputValue === externalErrorRef.current?.nameValue;
    const errorMessage = unchanged ? externalErrorRef.current?.error : null;
    return isNameFieldValid({ value: inputValue, externalError: errorMessage });
  };

  const sameName = externalErrorRef.current?.nameValue === name;
  const errorText = sameName ? externalErrorRef.current?.error : fieldError;

  return (
    <div className={className}>
      <div className={`${styles['name-field__label']} name-field-label`}>{label}</div>
      <Input
        autocomplete={false}
        name="listName"
        placeholder={placeholder}
        errorText={errorText}
        onBlur={onBlur}
        onChange={fieldValidation}
        validation={determineNameValidity}
        value={name}
        validateOnBlur
        maxLength="50"
        required
        className="name-field-input"
      />
    </div>
  );
};

NameField.displayName = 'ListFormsNameField';

NameField.propTypes = {
  error: string,
  onValidation: func,
  value: string,
  placeholder: string,
  label: string,
  className: string,
};

NameField.defaultProps = {
  error: null,
  onValidation: null,
  value: '',
  placeholder: 'List Name',
  label: 'List Name',
  className: '',
};
