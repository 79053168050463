import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useEffect
} from 'react';
import { string, bool } from 'prop-types';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { useDataModel, QueryProvider } from '@thd-nucleus/data-sources';
import { useStore, ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { InstantRecs, InstantRecsPlaceholder, instantRecsDataModel } from './InstantRecs';
import { getAppId } from '../utils/helpers';
import {
  LIKE, DISLIKE,
  QUERY_NAME as queryName
} from '../core/constants';
import '../../styles/thd-recs-containers.scss';

const InstantRecsContainer = (props) => {

  const {
    anchorId,
    requestKey,
    anchorItemId,
    showMultiAnchor
  } = props;

  let personalizedRecsModels = useConfigService('fs:isPersonalizedRecs');
  let recsModels = useConfigService('fs-prop:pip-master__instant-recs--recsModels');
  recsModels = !recsModels ? {} : JSON.parse(recsModels);

  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const maxRecommendations = channel === 'desktop' ? '12' : '4';
  const { storeId, storeZip: zipCode, isLocalized } = useStore();
  const loadedStoreInfo = useRef({ storeId, zipCode });
  const [userInteraction, setUserInteraction] = useState({
    itemId: '',
    interaction: '',
    likedProducts: [],
    replacedProducts: [],
    currRecs: []
  });

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const onLikeClick = ({
    itemId,
    likedProducts,
    replacedProducts,
    currRecs
  }) => (
    setUserInteraction({
      interaction: LIKE,
      itemId,
      likedProducts,
      replacedProducts,
      currRecs
    })
  );

  const onDislikeClick = ({
    itemId,
    likedProducts,
    replacedProducts,
    currRecs
  }) => {

    setUserInteraction({
      interaction: DISLIKE,
      itemId,
      likedProducts,
      replacedProducts,
      currRecs
    });
  };

  const resetRecs = () => (
    setUserInteraction({
      interaction: '',
      currRecs: [],
      likedProducts: [],
      replacedProducts: [],
      itemId: '',
    })
  );

  const prepareReqData = () => {
    const {
      interaction,
      likedProducts,
      replacedProducts,
      currRecs,
      itemId
    } = userInteraction;

    // on page load - get recommendations for PIP
    if (interaction === '') {
      return '';
    }

    const strifinedJson = JSON.stringify({
      interaction,
      interactionProduct: itemId,
      pipProduct: anchorItemId,
      likedProducts,
      replacedProducts,
      currentlyRecommendedProducts: currRecs,
    });

    return encodeURIComponent(strifinedJson);
  };

  let interactiveAnchorId;
  let serviceTypeValue;
  let apiValue;
  if (userInteraction.interaction === LIKE || userInteraction.interaction === DISLIKE) {
    interactiveAnchorId = anchorItemId;
    serviceTypeValue = null;
    apiValue = 'instantrecomm';
  } else {
    interactiveAnchorId = anchorId;
    serviceTypeValue = showMultiAnchor ? 'mutlianchor' : null;
    apiValue = showMultiAnchor ? 'interactive_recs' : 'instantrecomm';
  }

  const setModelAndTableName = () => {

    let modelKey; let
      tableNameKey;

    switch (userInteraction.interaction) {

    case LIKE:
      modelKey = 'likeModel';
      tableNameKey = 'likeTableName';
      break;

    case DISLIKE:
      modelKey = 'dislikeModel';
      tableNameKey = 'dislikeTableName';
      break;

    default: // page load - default
      modelKey = 'pageLoadModel';
      tableNameKey = 'pageLoadTableName';
    }

    const model = (!!recsModels && recsModels[modelKey]) || 'visually_similar_motif_model';
    const tablename = (!!recsModels && recsModels[tableNameKey]) || 'visually_similar_models';

    return { model, tablename };
  };

  const len = userInteraction?.likedProducts.length;
  const opts = {
    variables: {
      storeId,
      dataSource: 'instantrecomm',
      anchorId: len > 0 ? userInteraction.likedProducts[len - 1] : interactiveAnchorId,
      maxResults: maxRecommendations,
      apiName: apiValue,
      key: requestKey,
      instantRec: prepareReqData(),
      serviceType: serviceTypeValue,
      appId: getAppId(channel, isConsumerApp),
      ...(setModelAndTableName())
    },
    skip: !isLocalized,
    ssr: false
  };

  const {
    data,
    loading,
    variables,
    error
  } = useDataModel(queryName, opts);

  if (data && !loading && isLocalized) {
    loadedStoreInfo.current = {
      storeId: variables.storeId,
      zipCode: variables.zipCode,
      mounted: true
    };
  }

  const hasNoRecs = useMemo(() => {
    if (error && userInteraction.interaction === DISLIKE
      && JSON.stringify(error)?.match('id":404')?.length) {
      return userInteraction.itemId;
    }
    return '';
  }, [error]);

  const mountedFn = useCallback(
    () => loadedStoreInfo.current.mounted,
    [loadedStoreInfo.current.mounted]
  );

  if (!mounted) {
    return null;
  }

  return (
    <QueryProvider dataSource="instantrecomm" cacheKey="instantrecs-container">
      <InstantRecs
        onDislikeClick={onDislikeClick}
        onLikeClick={onLikeClick}
        data={data?.recs}
        loading={loading}
        resetRecs={resetRecs}
        hasNoRecs={hasNoRecs}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </QueryProvider>
  );

};

InstantRecsContainer.displayName = 'InstantRecsContainer';
InstantRecsContainer.dataModel = instantRecsDataModel;

InstantRecsContainer.propTypes = {
  showLoading: bool,
  anchorId: string.isRequired,
  requestKey: string,
  anchorItemId: string.isRequired,
  showMultiAnchor: bool,
  schemaName: string.isRequired
};

InstantRecsContainer.defaultProps = {
  showLoading: true,
  requestKey: null,
  showMultiAnchor: false
};

const HydratedInstantRecs = withHydrator(
  {
    delay: 1500,
    placeholder: (<InstantRecsPlaceholder />),
    id: 'instantrecomm',
    preserveCtxVal: 'clientStore'
  },
  InstantRecsContainer);

const InstantRecommendations = withErrorBoundary(HydratedInstantRecs);

InstantRecommendations.dataModel = instantRecsDataModel;

export { InstantRecommendations };