import React, { useState, useEffect } from 'react';
import { oneOf, bool } from 'prop-types';
import { FitCompatibilityProvider, FitCompatibilityWidget } from './components';
import { FitDrawer } from './components/subcomponents';
import {
  TYPE_LIST,
  ANIMATIONS,
  IMAGES
} from './constants';

export const FitCompatibility = ({ type, drawerPosition, imageCacheEnabled }) => {
  const [isLoading, setIsLoading] = useState(imageCacheEnabled);

  useEffect(() => {
    const cacheImages = async () => {
      try {
        if (!TYPE_LIST.includes(type)) throw new Error('No type provided to FitCompatability');
        await Promise.all([...Object.values(ANIMATIONS), ...Object.values(IMAGES)].map(async (src) => {
          return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = src;
            image.onload = resolve();
            image.onerror = reject();
          });
        }));
        setIsLoading(false);
      } catch (er) {
        console.log(er);
        setIsLoading(false);
      }
    };
    if (imageCacheEnabled) cacheImages();
  }, []);

  if (!TYPE_LIST.includes(type)) return null;

  return (
    <div
      data-testid="fit-compatibility"
      data-component="FitCompatibility"
    >
      <FitCompatibilityProvider
        isLoading={isLoading}
        type={type}
        drawerPosition={drawerPosition}
      >
        <FitDrawer />
        <FitCompatibilityWidget />
      </FitCompatibilityProvider>
    </div>
  );
};

FitCompatibility.propTypes = {
  type: oneOf(TYPE_LIST),
  drawerPosition: oneOf(['left', 'right']),
  imageCacheEnabled: bool
};

FitCompatibility.defaultProps = {
  type: '',
  drawerPosition: 'right',
  imageCacheEnabled: false
};

FitCompatibility.displayName = 'FitCompatibility';
