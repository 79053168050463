import React from 'react';
import { RectShape } from '@thd-olt-component-react/loading-placeholder';
import { Row } from '@thd-olt-component-react/grid';
import '../../styles/store-selector.scss';

const StoreSelectorPlaceholder = () => {
  return (
    <div className="store-selector__placeholder" data-component="StoreSelectorPlaceholder">
      <Row>
        <RectShape style={{ width: '100%', height: '20px', margin: '5px' }} color="#E0E0E0" />
      </Row>
    </div>
  );
};

StoreSelectorPlaceholder.displayName = 'StoreSelectorPlaceholder';

export { StoreSelectorPlaceholder };