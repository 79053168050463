import { useNavigate, useLocation } from 'react-router-dom';

export const useHistory = () => {

  // eslint-disable-next-line
  console.warn('useHistory is decprated, update to useNavigate');
  const navigate = useNavigate();
  let location = useLocation();
  return {
    location,
    push: (path) => {
      navigate(path);
    },
    replace: (path) => {
      navigate(path, { replace: true });
    },
    go: (num) => {
      navigate(num);
    }
  };
};