const Util = () => {
  const xmlToJSON = (xml) => {
    if (!xml) return null;
    try {
      let obj = {};

      if (xml.nodeType === 1) { // element
        // do attributes
        if (xml.attributes.length > 0) {
          for (let index = 0; index < xml.attributes.length; index += 1) {
            let attribute = xml.attributes.item(index);
            obj[attribute.nodeName] = attribute.nodeValue;
          }
        }
      } else if (xml.nodeType === 3) { // text
        obj = xml.nodeValue;
      }

      // do children
      if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i += 1) {
          let item = xml.childNodes.item(i);
          let nodeName = item.nodeName;
          if (typeof (obj[nodeName]) === 'undefined') {
            obj[nodeName] = xmlToJSON(item);
          } else {
            if (typeof (obj[nodeName].push) === 'undefined') {
              let old = obj[nodeName];
              obj[nodeName] = [];
              obj[nodeName].push(old);
            }
            obj[nodeName].push(xmlToJSON(item));
          }
        }
      }
      return obj;
    } catch (exception) {
      return null;
    }
  };
  const parseXML = (xmlStr) => {
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlStr, 'application/xml');
    return xml;
  };
  const getLineWidth = (baseSize, scale) => {
    let lineScale = scale / 1000;
    return baseSize * (lineScale / scale);
  };
  const defaultNumberTo = (actual, defaultValue) => {
    let originalValue = parseFloat(actual);
    if (Number.isNaN(originalValue)) {
      return defaultValue;
    }
    return originalValue;
  };
  const transformRotation = (raw) => {
    let first = raw.replace(/\)/g, ') ');
    let second = {};
    let data = first.match(/(\w+\((\\-?\d+\.?\d*,?)+\))+/g);
    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        let third = data[key].match(/[\w\\.\\-]+/g);
        second[third.shift()] = third;
      });
    }
    return second;
  };
  const filterBlankAisle = (aisleValue) => {
    let aisle = aisleValue;
    if (!Array.isArray(aisle)) {
      aisle = [aisle];
    }
    aisle = aisle.filter((value) => {
      if (value.name === '' || value.number === '') return false;
      return true;
    });
    return aisle;
  };

  const updateSelectionStyles = (selection, newStyles) => {
    const styleNames = Object.keys(newStyles);
    styleNames.forEach((styleName) => {
      selection.style(styleName, newStyles[styleName]);
    });
  };

  return {
    xmlToJSON,
    parseXML,
    fixAisle: filterBlankAisle,
    getLineWidth,
    parseTransform: transformRotation,
    defaultNumberTo,
    updateSelectionStyles
  };

};
export { Util };