import { useEffect, useRef } from 'react';
import { useDataModel, useLazyDataModel } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { getAnchorItemIds, getMetadataAttributeValues, getSampleColorSpecValue } from '../helpers';

export const useOrderSample = ({ disableSampleCall, itemId }) => {
  const { storeId, storeZip } = useStore();
  const excludedAnchorItemIdsRef = useRef([]);
  const anchorItemIdRef = useRef(itemId);

  const response = useDataModel('product', {
    skip: !itemId,
    ssr: true,
    variables: {
      itemId
    }
  });
  const [productReq, productResponse] = useLazyDataModel('product', { fetchPolicy: 'cache-first' });

  const productData = productResponse?.data || response?.data;
  const { sampleId, isSuperSku, parentId } = productData?.product?.identifiers || {};

  const [metadataReq, metadataResponse] = useLazyDataModel('metadata', { fetchPolicy: 'cache-first' });
  
  const sample = useDataModel('sampleProduct', {
    fetchPolicy: 'cache-first',
    skip: disableSampleCall || !sampleId || sampleId === anchorItemIdRef.current,
    ssr: false,
    variables: {
      itemId: sampleId,
      storeId,
      zipCode: storeZip
    },
  });
  
  const sampleProduct = sample?.data?.product;
  const samplePrice = sampleProduct?.pricing?.value;

  // There are MANY data issues in anchor and sample products
  // The useEffect below ensures that we only pull valid samples (if possible)
  useEffect(() => {
    const productHasInvalidPrice = sampleId // sample needs sampleId
      && (
        // sampleId should not equal the anchor itemId
        sampleId === anchorItemIdRef.current
          /*
            sample needs to have a price
            * Discontinued samples are okay without a price, but due to bad data
            * we will to try to find a sample with a price for this SKU where possible
          */
          || (sampleProduct?.identifiers?.itemId === sampleId && !samplePrice)
      );
    if (productHasInvalidPrice && productData) {
      if (!metadataResponse.data?.metadata) {
        metadataReq({ variables: { parentId } });
      } else {
        const metadata = metadataResponse.data.metadata;
        const skuNames = getMetadataAttributeValues(metadata.attributes);
        const thisSkuName = getSampleColorSpecValue(metadata.attributes, productData.product.specificationGroup);
        excludedAnchorItemIdsRef.current = [...excludedAnchorItemIdsRef.current, anchorItemIdRef.current];
        if (thisSkuName && skuNames.includes(thisSkuName)) {
          const anchorItemIds = getAnchorItemIds({
            metadata,
            specificSkuNames: [thisSkuName],
            excludedItemIds: excludedAnchorItemIdsRef.current
          });
          if (anchorItemIds.length) {
            anchorItemIdRef.current = anchorItemIds[0];
            productReq({
              skip: !anchorItemIdRef.current,
              ssr: false,
              variables: {
                itemId: anchorItemIdRef.current
              },
            });
          }
        }
      }
    }
  }, [metadataResponse.data?.metadata, productData, sampleProduct]);

  return {
    data: {
      discontinued: !!sampleProduct?.availabilityType?.discontinued,
      isSuperSku,
      parentId,
      price: samplePrice,
      sampleProduct,
      sampleId
    },
    loading: productResponse.loading || sample?.loading
  };
};
