/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { publish } from '../publisher';
import { ProductResultsContext } from './ProductResultsContext';
import { AppliedRefinement } from './Refinements/AppliedRefinement';
import {
  getClearAllWithSort,
  getAppliedRefinements,
  getSortNameValue
} from '../product-results-helpers';
import '../styles/results-applied.scss';

const ResultsApplied = ({
  onAppliedChange,
  onDisplay,
  onDisplayChange,
  viewPrimaryFilters,
  hideResultsCount,
  isGetItFastOnPlp
}) => {

  const { data, loading } = useContext(ProductResultsContext);
  const [cancelClick, setCancelClick] = useState(false);
  const { appliedDimensions = [], metadata, searchReport } = data?.searchModel || {};

  const {
    keyword = '', sortBy = '', sortOrder = '', totalProducts = 0
  } = searchReport || {};

  const desktopSort = useRef(getSortNameValue({
    key: (sortBy && sortBy.toLowerCase()) || '',
    order: (sortOrder && sortOrder.toLowerCase()) || '',
    keyword
  }).value);

  let appliedRefinements = getAppliedRefinements(appliedDimensions, isGetItFastOnPlp);

  const handleSortOrderChange = (sortorder) => {
    if (sortorder) {
      desktopSort.current = `${sortorder.output.sortby}:${sortorder.output.sortorder}`;
    }
  };

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('sortorder.value', handleSortOrderChange);
  }, [desktopSort]);

  if (!data) return null;

  const handleOnDisplayChange = () => {
    // when onDisplay filters is removed from chip list
    window.LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements-visualNav.click', {
      dimensionName: 'Get It Fast',
      label: 'On Display',
      refinementLabel: 'On Display',
      refinementKey: '',
      selected: true
    });
    onDisplayChange({ useStoreFilter: 'false', multiStoreIds: [] });
  };

  if (onDisplay === 'true') {
    appliedRefinements.push({
      dimensionName: 'Get It Fast',
      refinementLabel: 'On Display',
      refinementKey: ''
    });
  }

  const handleClearAllClick = () => {
    document.body.classList.add('filter-and-sort--fade');
    setTimeout(() => {
      let count = 0;
      let url = '';
      getClearAllWithSort({ metadata, searchReport }).split('?').forEach((key) => {
        if (count >= 0) {
          url += (count === 0 ? key : (count === 1 ? '?' : '&') + key);
        }
        count += 1;
      });
      const refinement = {
        url,
        clear: true
      };
      if (onAppliedChange) {
        onAppliedChange({ refinement, onDisplay: 'false' });
      }

      // trigger below event for setting DDE.filters as empty
      publish('change-filters-refinements-clear-all', refinement);
      document.body.classList.remove('filter-and-sort--fade');
    }, 0);
  };

  const resultsAppliedLabelClasses = classNames(
    'sui-self-end', {
      'results-applied__label': !viewPrimaryFilters,
      'results-applied__primary-filter-label': viewPrimaryFilters,
      'results-applied__primary-filter-label-GIF-plp': isGetItFastOnPlp,
    });

  // Removal of filters : upon click of 'x', trigger below event
  if (cancelClick && onAppliedChange && !loading) {
    if (appliedDimensions && Object.keys(appliedDimensions).length > 0) {
      /* if onDisplay filters is selected and user removes other filters from chip list
          add onDisplay info into appliedDimensions  */
      let newAppliedDimensions = [...appliedDimensions];
      if (onDisplay === 'true') {
        newAppliedDimensions.push({
          label: 'Get It Fast',
          refinement: [{
            label: 'On Display',
            refinementLabel: 'On Display',
            refinementKey: ''
          }]
        });
      }
      publish('change-filters-refinements-shortings', newAppliedDimensions);
    } else {
      // applied and removed 1st filter
      handleClearAllClick();
    }
    setCancelClick(false);
  }

  const handleFiltersChipChange = (props) => {
    document.body.classList.add('filter-and-sort--fade');
    setTimeout(() => {
      onAppliedChange(props);
      setCancelClick(true);
      document.body.classList.remove('filter-and-sort--fade');
    }, 0);
  };

  return (
    <div className="results-applied" data-component="ResultsApplied">
      {!hideResultsCount && (totalProducts || totalProducts === 0) && (
        <span className={resultsAppliedLabelClasses}>{`${totalProducts.toLocaleString()} Results`}</span>
      )}
      {!viewPrimaryFilters && appliedRefinements.map((refinement, index) => {
        const {
          dimensionName,
          refinementLabel,
          refinementKey,
          url
        } = refinement;

        const refinementLabelShown = (refinementLabel === 'Pick Up Today')
          ? 'In Stock at Store Today'
          : refinementLabel;

        return (
          <AppliedRefinement
            key={index}
            index={index}
            deselectUrl={url}
            dimensionName={dimensionName}
            label={refinementLabelShown}
            refinementKey={refinementKey}
            onChange={(refinementLabel === 'On Display') ? handleOnDisplayChange : handleFiltersChipChange}
            onDisplay={onDisplay}
            desktopSort={desktopSort.current}
          />
        );
      })}
      {!isGetItFastOnPlp && !viewPrimaryFilters && appliedRefinements.length > 1 && (
        <a
          className="results-applied__clear-all"
          onClick={handleClearAllClick}
          role="button"
          tabIndex={0}
        >
          Clear All
        </a>
      )}

      {isGetItFastOnPlp && !viewPrimaryFilters && appliedRefinements.length > 1 && (
        <button
          type="button"
          className="results-applied__clearall bttn--inline bttn-outline bttn--filter"
          onClick={handleClearAllClick}
          tabIndex={0}
        >
          <span className="bttn__clearall">
            Clear All
          </span>
        </button>
      )}
    </div>
  );
};

ResultsApplied.propTypes = {
  onAppliedChange: PropTypes.func.isRequired,
  onDisplay: PropTypes.string,
  onDisplayChange: PropTypes.func,
  viewPrimaryFilters: PropTypes.bool,
  hideResultsCount: PropTypes.bool,
  isGetItFastOnPlp: PropTypes.bool
};

ResultsApplied.defaultProps = {
  onDisplay: 'false',
  onDisplayChange: null,
  viewPrimaryFilters: false,
  hideResultsCount: false,
  isGetItFastOnPlp: false
};

ResultsApplied.displayName = 'ResultsApplied';

export { ResultsApplied };
