import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { Checkbox, ToggleSwitch } from '@thd-olt-component-react/checkbox';
import { StoreSelector } from '../StoreSelector/StoreSelector';
import '../../styles/refinement.scss';

import { StoreSelectorPlaceholder } from '../StoreSelector/StoreSelectorPlaceholder';

const StandardRefinement = ({
  checkboxClassName,
  dimension,
  id,
  isRange,
  onChange,
  refinement,
  enableMultiStore,
  toggleSwitch,
  onMultiStoreSelectionChange,
  multiStoreSelection,
  onDisplay,
  multiFacet,
  active,
  parentID
}) => {

  const { selected } = refinement;

  const { channel } = useContext(ExperienceContext);
  const { storeName, isLocalized } = useStore({ varnish: false });

  const [multiStoreIds, setMultiStoreIds] = useState(multiStoreSelection ? [multiStoreSelection] : []);

  const isMobile = channel === 'mobile';
  const isChecked = (!multiFacet && !!selected && !isRange) || (multiFacet && active);

  const refinementCountClasses = classNames({ refinement__count: multiFacet });

  const showStoreSelector = refinement.label.toUpperCase() === 'PICK UP TODAY' && enableMultiStore;

  const desktopSort = useRef('');

  const handleSortOrderChange = (sortorder) => {
    desktopSort.current = `${sortorder.output.sortby}:${sortorder.output.sortorder}`;
  };
  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('sortorder.value', handleSortOrderChange);
  }, [desktopSort]);

  const handleOnChange = (event) => {
    if (onChange) {
      const [sortedby, orderedby] = desktopSort.current.split(':');
      onChange({
        refinement, dimension, onDisplay
      });
      window.LIFE_CYCLE_EVENT_BUS.trigger('sortorder.value', { sortby: sortedby, sortorder: orderedby });
    }
  };

  const handleStoreChange = () => {
    if (onChange) {
      onChange({
        refinement,
        dimension,
        onDisplay
      });
    }
  };

  const multiStoreCallback = ({ stores }) => {
    const idCount = multiStoreIds?.length;
    const checkedMultiStoreIds = stores.filter((store) => store.checked)
      .map((checkedStore) => parseInt(checkedStore.storeId, 10));

    // Only apply changes to mobile web, not affect the desktop
    if (!isMobile && checkedMultiStoreIds?.length < idCount && !selected) {
      handleStoreChange(checkedMultiStoreIds);
    }

    // When open the dropdown at the first time, both "multiStoreIds" and "checkedMultiStoreIds" are empty
    // Should apply the filter even both of them are empty in mobile web
    if (isMobile && checkedMultiStoreIds?.length <= idCount && !selected) {
      handleStoreChange(checkedMultiStoreIds);
    }
    // selected returning null after first store is selcted in dropdown
    // we are adding additional check to see if there are unchecked stores in dropdown menu
    if ((selected || checkedMultiStoreIds.length < 5) && enableMultiStore && onMultiStoreSelectionChange) {
      onMultiStoreSelectionChange({ multiStoreIds: checkedMultiStoreIds, onDisplay });
    }
    setMultiStoreIds(checkedMultiStoreIds);
  };

  const handleOnChangeLink = (event) => {
    event.preventDefault();
    if (onChange) {
      onChange({
        refinement, dimension, onDisplay
      });
    }
  };

  const generateRefinementLabel = (labelClassName) => {
    const displayLabel = refinement?.label?.toUpperCase() === 'PICK UP TODAY'
      ? 'In Stock at Store Today'
      : refinement?.label;

    return (
      <h3 className={labelClassName}>{displayLabel}</h3>
    );
  };

  const labelClassName = classNames('refinement__link', {
    'refinement__link--special-value': refinement.label === 'Special Values'
  });

  const toggleSwitchClasses = classNames(
    'refinement__toggle-switch',
    'refinement__toggle-switch--no-padding',
    {
      'refinement__toggle-switch--padding-right': multiFacet
    });

  return (
    <>
      {!toggleSwitch && (
        <Checkbox
          className={checkboxClassName}
          disabled={parseInt(refinement.recordCount, 10) === 0}
          id={`${id}-${parentID}`}
          key={`${id}-${parentID}`}
          data-refinement={refinement.label}
          data-refinementvalue={refinement.label}
          data-active="yes"
          data-omni="true"
          onChange={handleOnChange}
          checked={isChecked}
          value={refinement.refinementKey || ''}
        >
          <a
            className="refinement__link"
            href={refinement.url}
            onClick={(event) => handleOnChangeLink(event, refinement)}
          >
            {generateRefinementLabel(labelClassName)}
          </a>
        </Checkbox>
      )}
      {toggleSwitch && (
        <ToggleSwitch
          className={toggleSwitchClasses}
          id={id}
          key={id}
          onChange={handleOnChange}
          checked={isChecked}
        >
          <div className={labelClassName}>{refinement.label}</div>
        </ToggleSwitch>
      )}
      {showStoreSelector && (
        <div className="refinement__store-selector">
          {isLocalized
            ? (
              <StoreSelector
                multiStoreCallback={multiStoreCallback}
                multiStoreSelection={multiStoreSelection}
                multiFacet={multiFacet}
                storeName={storeName}
              />
            )
            : <StoreSelectorPlaceholder />}
        </div>
      )}
    </>
  );
};

StandardRefinement.propTypes = {
  checkboxClassName: PropTypes.string,
  dimension: PropTypes.shape({}),
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  enableMultiStore: PropTypes.bool,
  multiStoreSelection: PropTypes.arrayOf(PropTypes.string),
  onMultiStoreSelectionChange: PropTypes.func,
  refinement: PropTypes.shape({
    label: PropTypes.string,
    recordCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    refinementKey: PropTypes.string,
    selected: PropTypes.bool,
    url: PropTypes.string,
  }),
  toggleSwitch: PropTypes.bool,
  onDisplay: PropTypes.string,
  multiFacet: PropTypes.bool,
  active: PropTypes.bool,
  parentID: PropTypes.string,
  isRange: PropTypes.bool,
};

StandardRefinement.defaultProps = {
  checkboxClassName: null,
  dimension: null,
  id: null,
  enableMultiStore: false,
  multiStoreSelection: null,
  onMultiStoreSelectionChange: null,
  refinement: null,
  toggleSwitch: false,
  onDisplay: 'false',
  multiFacet: false,
  active: false,
  isRange: false,
  parentID: ''
};

StandardRefinement.displayName = 'StandardRefinement';

StandardRefinement.dataModel = extend(StoreSelector);

export { StandardRefinement };
