import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UUID } from '../utils';

export const addLinkClasses = (children, linkClass) => {
  const childrenList = children.length ? children : [children];

  return childrenList.map((child) => {
    if (child.type === 'a') {
      return React.cloneElement(child, { key: UUID(), className: linkClass });
    }
    return child;
  });
};

const LinkItem = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const classes = classNames(props.className);
  // eslint-disable-next-line react/destructuring-assignment
  return <li className={classes}>{ addLinkClasses(props.children, 'list__link') }</li>;
};

LinkItem.propTypes = {
  /** Link Items must be anchor tags */
  children: PropTypes.oneOfType([
    // TODO: find a way to limit type to anchor tags
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  className: PropTypes.string
};

LinkItem.defaultProps = {
  className: null
};

export default LinkItem;
