import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { string, bool, shape } from 'prop-types';
import { Checkmark } from '@one-thd/sui-icons';
import { extend, useDataModel } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { StoreLayoutModal } from './StoreLayoutModal';
import { dataModel } from './dataModel';
import { transformData, getLocalStore } from '../helper';

const AisleBaySingleItem = ({
  itemId, store, channel, centered, clsRemediation
}) => {
  const storeId = store?.storeId;
  const storeName = store?.storeName;
  const isLocalized = store?.isLocalized;
  const isMobile = channel === 'mobile';
  const { placeholders, preservePlaceholders } = clsRemediation || {};
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const { isConsumerApp } = useContext(ExperienceContext);

  // request simple metadata for ssr
  const { data: metadata } = useDataModel('product', {
    variables: { itemId }
  });

  const { data: storeDetailsData } = useDataModel('storeDetails', {
    skip: !isConsumerApp,
    variables: { storeId }
  });

  const { availabilityType, identifiers, media } = metadata?.product || {};
  const { storeSkuNumber, productType = '', brandName, productLabel } = identifiers || {};
  const { type = '' } = availabilityType || {};
  const { url } = media?.image || {};
  const isOnlineOnly = /Online/i.test(type);
  const isAppliance = /MAJOR_APPLIANCE/i.test(productType);
  // in stores, not online-only
  const isCompatibleProductType = storeSkuNumber && (!isOnlineOnly || isAppliance);
  let zipCode = store?.storeZip;
  if (typeof window !== 'undefined') {
    const zipDetails = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails();
    zipCode = zipDetails?.zipcode;
  }
  // request fulfillment on client for inventory
  const { data: fulfillmentData, loading } = useDataModel('clientOnlyProduct', {
    variables: { itemId, storeId, zipCode },
    skip: !isCompatibleProductType || !isLocalized,
    ssr: false
  });

  const fulfillment = fulfillmentData?.product?.fulfillment;
  const { fulfillmentOptions } = fulfillment || {};
  const dataIsLocalized = Boolean(isLocalized && !loading && fulfillment);
  const isActiveSku = fulfillment?.anchorStoreStatus
    && /(active)|(in_season)/i.test(fulfillment?.anchorStoreStatusType);
  const localStore = getLocalStore(fulfillmentOptions);
  const isInStore = isActiveSku && (localStore?.inventory?.quantity || isAppliance);
  const {
    street, city, state, country, postalCode
  } = storeDetailsData?.storeDetails?.address || {};
  const { isLimitedQuantity, isInStock, quantity } = localStore?.inventory || {};

  // if in store, then get aisle bay info
  const { data: aisleBayData } = useDataModel('aislebay', {
    skip: !isInStore,
    ssr: false,
    variables: {
      storeId,
      storeSkuIds: storeSkuNumber ? [storeSkuNumber] : [],
    }
  });

  const aisleBayInfo = aisleBayData?.aislebay?.storeSkus?.[0]?.aisleBayInfo;
  const isAisleBayEmpty = aisleBayData && !aisleBayInfo;
  const { aisle, bay } = aisleBayInfo || {};

  const {
    hasAllRequiredProperties,
    aisleBayInventory,
    localStoreName,
    aisleBayMessage
  } = transformData(localStore, isAppliance, aisleBayInfo, storeName);

  // data invalidation
  if (!hasAllRequiredProperties) {
    // --> may cause cls from subsequent content insertion
    if (!placeholders) {
      return null;
    }

    // ('product') --> 0 CLS
    if (!isCompatibleProductType) {
      return null;
    }

    // --> may cause cls from collapse of early placeholders
    if (!preservePlaceholders) {
      // ('clientOnlyProduct')
      if (dataIsLocalized && !isInStore) return null;
      // ('aislebay')
      if (isAisleBayEmpty) return null;
    }
  }

  const aisleBayClasses = classNames('sui-leading-loose sui-text-base', {
    'sui-invisible': !hasAllRequiredProperties,
    'sui-m-auto sui-flex sui-justify-center sui-text-center': centered
  });

  const AisleBayInventory = () => (
    <span className="sui-flex sui-items-center sui-fill-success-strong sui-text-success sui-mr-2">
      <Checkmark color="inherit" size="small" />
      <span className="sui-ml-1">
        {aisleBayInventory}
      </span>
    </span>
  );

  const onStoreLayoutClick = () => {
    if (isConsumerApp) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('NATIVE_APP.STORE_MAP', {
        storeId,
        storeName,
        street,
        city,
        state,
        country,
        postalCode,
        sku: storeSkuNumber,
        aisle,
        bay,
        friendlyAisleDescription: aisleBayInfo?.invLocDescFriendly,
        imageURL: url,
        quantity,
        storePhone: localStore?.storePhone,
        isLimitedStock: isLimitedQuantity,
        brandName,
        productLabel,
        stockStatus: isInStock
      });
    } else setStoreModalOpen(true);
  };

  return (
    <fieldset className={aisleBayClasses} data-component="AisleBaySingleItem">
      <legend className="sui-flex sui-pb-1">
        <span className="sui-mr-2 sui-font-bold">
          <>{localStoreName} Store</>
        </span>
        {(isMobile) && <AisleBayInventory />}
      </legend>
      <section className="sui-flex sui-items-center">
        {(!isMobile) && <AisleBayInventory />}
        {!isAppliance && (
          <>
            <span className="sui-flex sui-mr-2">{aisleBayMessage}</span>
            {isMobile && (
              <>
                <button
                  type="button"
                  className="sui-underline sui-text-primary"
                  onClick={onStoreLayoutClick}
                >
                  Store Map
                </button>
                <StoreLayoutModal
                  open={storeModalOpen}
                  onClose={() => setStoreModalOpen(false)}
                  storeId={storeId}
                  storeName={storeName}
                  aisle={aisle}
                  bay={bay}
                />
              </>
            )}
          </>
        )}
      </section>
    </fieldset>
  );
};

AisleBaySingleItem.displayName = 'AisleBaySingleItem';

AisleBaySingleItem.propTypes = {
  itemId: string,
  store: shape({
    storeId: string,
    storeName: string,
  }).isRequired,
  channel: string.isRequired,
  // justifies and aligns all text centered
  centered: bool,
  clsRemediation: shape({
    // reserves whitespace while loading
    placeholders: bool,
    // disallows collapse of empty whitespace
    preservePlaceholders: bool,
  }).isRequired
};

AisleBaySingleItem.defaultProps = {
  itemId: null,
  centered: false
};

AisleBaySingleItem.dataModel = extend({}, dataModel);

export { AisleBaySingleItem };