import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UUID } from '../utils';

class RadioGroup extends Component {

  formName = '';

  componentDidMount() {
    this.formName = UUID();
  }

  generateChildren() {

    const radioButtons = React.Children.map(this.props.children, radioButton =>
      React.cloneElement(radioButton, { formname: this.formName }));
    return radioButtons;
  }

  render() {
    return (
      <form className={this.props.className}>
        {this.generateChildren()}
      </form>
    );
  }
}

RadioGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  /** Extend Styles. */
  className: PropTypes.string
};

RadioGroup.defaultProps = {
  className: null
};

export default RadioGroup;
