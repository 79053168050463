import React, { Suspense } from 'react';
import {
  shape, string, bool, func
} from 'prop-types';
import { Modal, ModalBody, ModalHeader } from '@one-thd/sui-atomic-components';

const StoreLayout = React.lazy(
  () => import(/* webpackChunkName: "StoreLayout" */ '@thd-olt-component-react/store-layout')
    .then((module) => ({ default: module.StoreLayout }))
);

const StoreLayoutModal = ({
  storeId, storeName, aisle, bay, open, onClose
}) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="lg"
    >
      <ModalHeader onClose={onClose}>Store Map</ModalHeader>
      <ModalBody data-component="AislbeBayStoreLayoutModal">
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <div className="md:sui-max-h-[80vh]">
          <Suspense fallback={<div>Loading...</div>}>
            <StoreLayout storeId={storeId} storeName={storeName} aisle={aisle} bay={bay} />
          </Suspense>
        </div>
      </ModalBody>
    </Modal>
  );

};

StoreLayoutModal.displayName = 'StoreLayoutModal';

StoreLayoutModal.propTypes = {
  storeId: string.isRequired,
  storeName: string,
  aisle: string,
  bay: string,
  open: bool.isRequired,
  onClose: func.isRequired
};

StoreLayoutModal.defaultProps = {
  storeName: '',
  aisle: '',
  bay: ''
};

export { StoreLayoutModal };
