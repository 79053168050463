import {
  params, shape, string, arrayOf
} from '@thd-nucleus/data-sources';

const Media = shape({
  images: arrayOf(shape({
    url: string(),
    subType: string()
  }))
});

export const dataModel = {
  products: params({
    itemIds: arrayOf(string().isRequired()).isRequired()
  }).arrayOf(
    shape({
      itemId: string(),
      media: Media
    })
  )
};