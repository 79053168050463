import React from 'react';
import { string as stringProp } from 'prop-types';
import { params, string, shape, useDataModel } from '@thd-nucleus/data-sources';
import { DUAL_PATH_IDENTIFIER } from '../../constants';

export const ProductServiceAddOns = ({ itemId }) => {
  const { data } = useDataModel('product', {
    variables: {
      itemId,
    }
  });
  const product = data?.product || {};

  const isDualPathSku = product?.info?.productSubType?.name === DUAL_PATH_IDENTIFIER;

  return isDualPathSku ? (
    <div className="sui-text-xs sui-pt-1">
      Expert Installation Available
    </div>
  ) : null;
};
ProductServiceAddOns.propTypes = {
  itemId: stringProp.isRequired,
};

ProductServiceAddOns.dataModel = {
  product: params({
    itemId: string().isRequired(),
  }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      productSubType: shape({
        name: string(),
      }),
    }),
  }),
};

ProductServiceAddOns.displayName = 'ProductServiceAddOns';
