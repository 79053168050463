/* eslint-disable id-length */
/* eslint-disable consistent-return */
import {
  dispatch as d3Dispatch,
  interpolate,
  line as d3Line,
  pointer,
  select,
  selectAll,
  transition as d3Transition,
  zoom as d3Zoom,
  zoomIdentity
} from 'd3';
import { Util } from './Util';

const roundNumber = (value, base = 10) => {
  const number = parseInt(value, base);
  if (Number.isNaN(number)) {
    return value;
  }
  return number;
};

const MapGenerator = function (xml, config) {
  if (typeof xml === 'undefined') {
    throw new Error('You must pass xml into StoreMap');
  }
  let options = config;

  options = options || {};

  let defaults = {
    width: window.innerWidth,
    height: window.innerHeight,
    wrapper: '#storemap-wrapper'
  };

  options = { ...defaults, ...options };

  this.wrapper = options.wrapper;

  this.xml = Util().parseXML(xml);
  this.json = Util().xmlToJSON(this.xml);
  if (typeof this.json.GetTHDStoreKioskMap.Message !== 'undefined') {
    this.json = this.json.GetTHDStoreKioskMap.Message.StoreMapData;
  }

  this.scale = window.innerWidth;

  // zoomLevel is used to keep track of where the user has zoomed.
  // the user can zoom with pinch or with the buttons and must
  // sync with each other. This property gets updated from both
  // events.
  this.zoomLevel = 1;

  // The width of the `rootSVG`, not the store
  this.width = options.width;
  this.height = options.height;
  // The angle of the store
  this.rotation = [0];
  this.translate = [0, 0];

  // draw racks will store them in ._racks `Array`
  this._racks = [];

  // The dimenisions of the building
  this.buildingHeight = parseFloat(this.json.bounds.height) * this.scale;
  this.buildingWidth = parseFloat(this.json.bounds.width) * this.scale;

  // @understand how this flag is used
  this.marker_visible = false;
  this.clear();
  this._init(options);
};

MapGenerator.prototype = {
  addMarker: function addMarker(aisleNumber, originBay, config) {
    let self = this;
    let bay = originBay;
    let bayCopy = bay;
    let options = config;
    let aisle = aisleNumber;

    // Aisle can be either OG (outdoot garden) or
    // 001.  If its a number, you have to parseInt
    let aTmp = parseInt(aisle, 10);
    if (!(Number.isNaN(aTmp))) {
      aisle = aTmp;
    }

    let bayNumber = roundNumber(bay);

    if (/[A-Za-z]+/.test(bay)) {
      if (bay === 'OFR' || bay === '0FR') { bayNumber = 1; }
      if (bay === 'OFL' || bay === '0FL') { bayNumber = 2; }
      if (bay === 'OBR' || bay === '0BR') { bayNumber = 999; }
      if (bay === 'OBL' || bay === '0BL') { bayNumber = 1000; }

    } else if (bayNumber === -1) {
      return;
    }

    bay = bayNumber;

    let defaults = {
      namespace: 'storemarker',
      icon: {
        point: 'M16 0c-5.523 0-10 4.477-10 10 0 10 10 22 10 22s10-12 '
        + '10-22c0-5.523-4.477-10-10-10zM16 16c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z',
        fill: '#f96302',
        width: 32,
        height: 32
      },
      rectStyle: { stroke: '#f96302', 'stroke-width': 1 }
    };

    options = { ...defaults, ...options };

    // Only 1 store marker at a time is currently allowed
    select('.' + options.namespace).remove();

    this.marker_visible = true;

    let aisles = selectAll('.aisle-' + aisle);
    let activeBays; let
      aisleWidth;

    let isReverse = false;

    let activeAisle = aisles.filter(function (data) {
      let dataPoint = data;
      if (!Array.isArray(dataPoint.aisle)) {
        dataPoint.aisle = [dataPoint.aisle];
      }

      let filteredAisle = dataPoint.aisle.filter(function (d1) {
        return parseInt(d1.number, 10) === aisle;
      });
      if (filteredAisle.length === 0) {
        return;
      }
      filteredAisle = filteredAisle[0];
      filteredAisle.is_active_aisle = true;

      // check for reverse flag

      let bays = filteredAisle.bays.split('-').map(function (elem) {
        return parseInt(elem, 10);
      });

      // Support for 'OFR', 'OFL', 'OBR', 'OBL'
      // OBR and OBL are 999, 1000 respectively.

      // if the bay is the end (OBL or OBR) and it sthe last loop
      if (bay > 998 && !(Number.isNaN(aisle))) {
        bay = bays[1];
      }

      if (bay < 3 && bays[0] > 2 && !(Number.isNaN(aisle))) {
        bay = bays[0];
      }

      if (bay >= bays[0] && bay <= bays[1] && typeof activeBays === 'undefined') {
        activeBays = bays;
        aisleWidth = filteredAisle.width;

        if (typeof filteredAisle.bayLayout !== 'undefined') {
          if (filteredAisle.bayLayout.indexOf('reverse') !== -1) {
            isReverse = true;
          }
        }
        return true;
      }
      return false;
    });

    let isEndcap = false;
    let rect;

    if (!activeAisle.empty()) {
      rect = activeAisle.select('rect');
    } else {
      isEndcap = true;

      // check if its an endcap
      let endcaps = selectAll('.endcap-' + aisle);
      if (endcaps.empty()) {
        return;
      }

      let endcap = endcaps.filter(function (elem) {
        return roundNumber(elem.bays) === bay;
      });

      if (endcap.empty() && aisles.empty()) {
        return;
      }

      activeAisle = select(endcap.node().parentElement);
      rect = endcap;

    }

    let rectWidth = parseFloat(rect.attr('width'));
    let rectHeight = parseFloat(rect.attr('height'));
    let range; let refX; let refXPercent; let locationOffset;

    let oWidth = ((aisleWidth < 0.25) ? aisleWidth : aisleWidth * 0.0012);
    oWidth *= (self.scale - 50);
    // @update
    if (rect.datum().dir === '270') {
      oWidth *= -1;
    }

    if (!isEndcap) {
      range = activeBays[1] - activeBays[0];

      refXPercent = (bayNumber - activeBays[0]) / range;
      if (isReverse) {
        refXPercent = 1 - refXPercent;
      }
      refX = (rectWidth * refXPercent);

      if (bayCopy === 'OBR' || bayCopy === 'OFR') {
        locationOffset = -0.5;
        if (bayCopy === 'OBR') {
          refX = rectWidth;
        } else {
          refX = 1;
        }
      } else if (bayCopy === 'OBL' || bayCopy === 'OFL') {
        locationOffset = oWidth;
        if (bayCopy === 'OBL') {
          refX = rectWidth;
        } else {
          refX = 1;
        }
      } else if (activeAisle.attr('data-bays-position-' + aisle) === 'left') {
        locationOffset = (bayNumber % 2 === 0) ? -oWidth : (-oWidth * 1.5);
      } else {
        locationOffset = (bayNumber % 2 === 0) ? (oWidth) : -0.5;
      }

    } else {
      refX = (rect.classed('endcap-front')) ? -rectWidth : rectWidth * 2;
      locationOffset = rectHeight / 2;
    }

    let graphics = activeAisle.append('g')
      .attr('data-x', rect.attr('x'))
      .attr('data-y', rect.attr('y'))
      .attr('class', options.namespace);

    let xCord = (parseFloat(rect.attr('x')) - 16);
    let yCord = parseFloat(rect.attr('y') - 32);

    graphics.append('defs')
      .append('svg:marker')
      .attr('id', options.namespace)
      .attr('refX', refX * -1)
      .attr('viewbox', '0 0 32 32')
      .attr('refY', locationOffset)
      .attr('markerWidth', options.icon.width)
      .attr('markerHeight', options.icon.height)
      .attr('fill', options.icon.fill)
      .style('shape-rendering', 'auto')
      .style('stroke', 'none')
      .style('opacity', 0.7)
      .append('svg:path')
      .attr('title', 'marker')
      .attr('d', options.icon.point);

    graphics.append('svg:path')
      .attr('class', options.namespace)
      .style('opacity', 0)
      .attr('d', function () {

        // droid 4.3 needs the L part to create the marker in the right spot
        return 'M' + xCord + ',' + yCord + 'L' + xCord + ',' + (yCord + 0.01);
      })
      .attr('marker-end', 'url(#' + options.namespace + ')')
      .transition()
      .delay(100)
      .style('opacity', 1);

    activeAisle
      .classed('active-aisle ', true)
      .order().raise(); // Makes sure marker stays on top.

    return graphics;
  },
  // * param `Boolean` vertial - if true will center the map vertically
  // * returns `this` for chaining
  center: function centerMap(config) {

    let defaults = {
      horizontal: 'center',
      vertical: 'center'
    };
    let options = config;

    options = { ...defaults, ...options };

    let rotate = '';
    if (this.rotation.length === 3) {
      rotate = 'rotate(' + this.rotation[0] + ',' + this.rotation[1] + ',' + this.rotation[2] + ')';
    }

    let center = this.getCenter();
    let orientation = this.getOrientation();
    let xCord; let yCord;

    if (options.horizontal === 'center') {
      xCord = center.x;
    }
    if (options.horizontal === 'top') {
      xCord = 0;
    }
    if (options.vertical === 'center') {
      yCord = center.y;
    }
    if (options.vertical === 'top') {
      if (orientation === 'vertical') {
        yCord = this.rotation[1] - this.rotation[2];
      } else {
        yCord = 0;
      }

    }

    select('.storemap-wrapper')
      .attr('transform', 'translate(' + xCord + ',' + yCord + ') ' + rotate);
    this.translate = [xCord, yCord];
    this._zoom.transform.k = this.zoomLevel;
    this._zoom.transform.x = xCord;
    this._zoom.transform.y = yCord;
    return this;
  },
  clear: function clear() {
    this._unbindEvents();
    select('svg').remove();
  },
  // * returns `Object` {x,y} coords
  getCenter: function getCenter() {
    let svgHeight = parseFloat(this.svgRoot.attr('height'));
    let svgWidth = parseFloat(this.svgRoot.attr('width'));
    let buildingHeight = this.buildingHeight;
    let buildingWidth = this.buildingWidth;

    let obj1 = (svgWidth / 2) - (buildingWidth / 2);
    let obj2 = (svgHeight / 2) - (buildingHeight / 2);

    return {
      // eslint-disable-next-line id-length
      x: obj1,
      // eslint-disable-next-line id-length
      y: obj2
    };
  },
  // * param `String` text
  // * param `String` lang - example en_US
  // * returns `String` translated text or input text if not in dictionary
  translateText: function translateText(text, lang) {
    let beforeTransalate = text;
    if (beforeTransalate && (beforeTransalate[0] !== '{' || beforeTransalate[beforeTransalate.length - 1] !== '}')) {
      return beforeTransalate;
    }
    beforeTransalate = beforeTransalate.substring(1, beforeTransalate.length - 1);

    let terms = this.json?.dictionary?.term || [];
    let words = terms.filter(function (term) {
      return term.lang === lang && term.id === beforeTransalate;
    });

    if (words.length) {
      return words[0].text;
    }
    return beforeTransalate;
  },
  // * returns 'String' `horizontal` or `vertical`
  getOrientation: function getOrientation() {
    return (this.rotation[0] % 180 === 0 || this.rotation[0] === 0) ? 'horizontal' : 'vertical';
  },
  // * returns `JSON Object`
  getObjects: function getObjects() {
    return this.json.objects;
  },

  // * param `Object` options
  // * param `String` className - CSS classname for the exterior walls. Defaults to `exterior-wall`
  // * param `Boolean` closePath - SVG closePath paramter for the path the gets drawn - defaults to true
  // * param `Object` style - css style for the wall
  // * returns `this` for chaining
  drawExteriorWalls: function drawExteriorWalls(config) {
    let options = config;
    let defaults = {
      className: 'exterior-wall',
      closePath: true
    };

    options = { ...defaults, ...options };

    let exteriorWalls = this.getObjects().exteriorWall;
    this.drawWalls(exteriorWalls, options);
    return this;
  },
  // * param `Object` options
  // * param `String` className - CSS classname for the interior walls. Defaults to `interior-wall`
  // * param `Object` style - css style for the wall
  // * returns `this` for chaining
  drawInteriorWalls: function drawInteriorWalls(config) {
    let defaults = {
      className: 'interior-wall'
    };
    let options = config;
    options = { ...defaults, ...options };
    let interiorWalls = this.getObjects().interiorWall;
    this.drawWalls(interiorWalls, options);
    return this;
  },
  // used by `drawExteriorWalls` and `drawInteriorWalls`

  // * param `Mixed` array or object of wall to create the SVG path with
  // * param `Object` options
  // * param `String` className - CSS classname for the exterior walls. Defaults to `exterior-wall`
  // * param `Boolean` closePath - SVG closePath paramter for the path the gets drawn - defaults to true
  // * param `Object` style - css style for the wall
  // * returns `this` for chaining
  drawWalls: function drawWalls(wallNumber, config) {
    let defaults = {
      style: {
        stroke: '#CCC',
        'stroke-width': 1,
        fill: 'none'
      },
      className: 'wall',
      closePath: false
    };
    let options = config;
    let walls = wallNumber;
    options = { ...defaults, ...options };

    let self = this;

    let exteriorLineFunction = d3Line()
      .x(function (elem) { return parseFloat(elem.x) * self.scale; })
      .y(function (elem) { return parseFloat(elem.y) * self.scale; });

    if (!Array.isArray(walls)) {
      walls = [walls];
    }

    // The line SVG Path we draw
    let data = walls.map(function (elem) {
      if (typeof elem !== 'undefined') {
        return elem.points.point;
      }
      return '';
    });

    if (typeof data[0] === 'undefined') {
      return this;
    }

    self.svg
      .selectAll('path.' + options.className)
      .data(data)
      .enter()
      .append('path')
      .attr('class', options.className)
      .attr('d', function (elem) {
        let line = exteriorLineFunction(elem);
        if (options.closePath) {
          line += 'Z';
        }
        return line;
      });

    Util().updateSelectionStyles(self.svg, options.style);
    return this;
  },
  // draws polygons as feed in from the xml .`areaPolygon` array

  // * param `Object` options
  // * param `Object` options.style - css object styles
  // * param `String` options.className - css classname for the polygons
  // * returns `this` for chaining
  drawAreaPolygon: function drawAreaPolygon(config) {

    let defaults = {
      style: { fill: '#f9f9f9', stroke: '#CCCCCC', 'stroke-width': '0.5' },
      className: 'polygon'
    };
    let options = config;
    options = { ...defaults, ...options };

    let polys = this.getObjects().areaPolygon;
    let self = this;
    let scale = self.scale;

    if (typeof polys === 'undefined') {
      return this;
    }

    if (!Array.isArray(polys)) {
      polys = [polys];
    }

    self.svg.selectAll('polygon.' + options.className)
      .data(polys)
      .enter()
      .append('polygon')
      .attr('points', function (elem) {
        return elem.points.point.map(function (coord) {
          return [coord.x * scale, coord.y * scale].join(',');
        });
      })
      .attr('class', options.className);

    Util().updateSelectionStyles(self.svg.selectAll('polygon.' + options.className), options.style);

    return this;
  },
  // draws rectangles as feed in from the xml .`areaRectangle` array

  // * param `Object` options
  // * param `Object` options.style - css object styles
  // * param `String` options.className - css classname for the polygons
  // * returns `this` for chaining
  drawAreaRectangle: function drawAreaRectangle(config) {

    let defaults = {
      style: { fill: '#f9f9f9', stroke: '#CCCCCC', 'stroke-width': '1' },
      className: 'rects'
    };
    let options = config;
    options = { ...defaults, ...options };

    let rects = this.getObjects().areaRectangle;
    let self = this;

    if (typeof rects === 'undefined') {
      return this;
    }

    self.svg.selectAll('rect.' + options.className)
      .data(rects)
      .enter()
      .append('rect')
      .attr('x', function (elem) {
        return parseFloat(elem.x) * self.scale;
      })
      .attr('y', function (elem) {
        return parseFloat(elem.y) * self.scale;
      })
      .attr('width', function (elem) {
        return parseFloat(elem.width) * self.scale;
      })
      .attr('height', function (elem) {
        return parseFloat(elem.height * self.scale);
      })
      .attr('class', options.className);

    Util().updateSelectionStyles(self.svg.selectAll('rect.' + options.className), options.style);

    return this;
  },
  on: function on(namespace, fn) {
    return this._dispatch.on(namespace, fn);
  },
  // **depends on the rack existing (first call `.drawRack`)**

  // * param `Object` options
  // * param `Object` options.style - css object styles
  // * param `Boolean` showBW - shows / hides the backwall
  // * returns `this` for chaining
  drawAisleNumbers: function drawAisleNumbers(config) {

    if (!select('text.aisle-number').empty()) {
      return this;
    }

    let defaults = {
      style: {
        'font-size': '4px',
        fill: '#f96302',
        'stroke-width': 0.1
      },
      showBW: false
    };
    let options = config;
    options = { ...defaults, ...options };

    let self = this;

    self._racks
      .each(function (data) {
        let elem = data;
        let selection = select(this);

        if (elem.aisle) {
          elem.aisle = Util().fixAisle(elem.aisle);

          if (!options.showBW) {
            elem.aisle = elem.aisle.filter(function (point) {
              return point.number !== 'BW';
            });
          }

          let rackX = parseFloat(elem.x) * self.scale;
          let rackY = parseFloat(elem.y) * self.scale;

          let rack = elem;

          selection.selectAll('text.aisle-number-text')
            .data(elem.aisle)
            .enter()
            .append('text')
            .attr('x', function () {
              return rackX;// + getXoffset(d.position);
            })
            .attr('y', function () {
              return rackY; // + getYOffset(d.position);
            })
            .attr('class', function (cords) {
              return 'aisle-number aisle-position-' + cords.position;
            })
            .attr('transform', function (text) {
              return self._getAisleNumbersTransform(text, rack);
            })
            .text(function (text) {
              return text.number;
            })
            .style('stroke-width', 0.1);

          Util().updateSelectionStyles(selection.selectAll('text.aisle-number'), options.style);
        }

      });

    return this;
  },
  // * param `Object` options
  // * param `Function` options.transition - d3 transition function to fade out.
  removeAisleNumbers: function removeAisleNumbers(config) {

    if (select('text.aisle-number').empty()) {
      return this;
    }

    let defaults = {};
    let options = config;
    defaults.transition = function (selection) {
      return selection
        .style('opacity', 1)
        .transition()
        .delay(10)
        .style('opacity', 0);
    };

    options = { ...defaults, ...options };

    options.transition(selectAll('text.aisle-number'))
      .remove();

    return this;
  },
  // draws the entire store. Also clears the current store

  // * returns `this` for chaining
  draw: function draw() {

    this
      .drawRacks()
      .drawExteriorWalls()
      .drawInteriorWalls()
      .drawAreaPolygon()
      .drawAreaRectangle()
      .drawDoorway()
      .drawRestroom()
      .drawLabels();

    return this;
  },
  // **depends on the rack existing (first call `.drawRack`)**

  // * param `Object` options
  // * param `Object` options.style - css object styles
  // * param `Boolean` showBW - shows / hides the backwall
  // * returns `this` for chaining
  drawAisleNames: function drawAisleNames(config) {
    if (!select('text.aisle-name').empty()) {
      return this;
    }
    let rack;
    let self = this;
    let defaults = {
      style: {
        'font-size': '3px',
        'font-family': 'sans-serif',
        fill: ' #999999',
        opacity: 0.9,
        'stroke-width': 0.1
      },
      activeFill: '#f96302',
      showBW: false
    };
    let options = config;
    options = { ...defaults, ...options };
    let fill = options.style.fill;
    let textToId = function (text) {
      return text.replace(/[^a-zA-Z0-9\- ]/g, '').replace(/\s/g, '').toLowerCase();
    };

    let addDefinition = function (selection, rackData) {

      let clip = selection.append('clipPath')
        .attr('id', function (elem) {
          return textToId(elem.name + '-' + elem.bays + '-' + elem.number);
        });

      clip.append('rect')
        .attr('x', parseFloat(rackData.x) * self.scale)
        .attr('y', self.scale * parseFloat(rackData.y))
        .attr('width', rackData._width)
        .attr('height', rackData._height * 6)
        .attr('transform', 'translate(0,-' + rackData._height * 2.5 + ')');
    };

    self._racks
      .each(function (data) {
        let elem = data;
        let selection = select(this);
        // let rack = d;
        let rackX = parseFloat(elem.x) * self.scale;
        let rackY = parseFloat(elem.y) * self.scale;
        rack = elem;

        if (elem.aisle && elem.aisle.length) {
          elem.aisle = Util().fixAisle(elem.aisle);

          if (!options.showBW) {
            elem.aisle = elem.aisle.filter(function (point) {
              return point.number !== 'BW';
            });
          }

          let defs = selection.append('defs');
          let graphics = selection
            .append('g')
            .attr('class', 'aisle-text-group');

          selection.each(function (aisleData) {
            let aisles = Util().fixAisle(aisleData.aisle);
            defs.selectAll('rect')
              .data(aisles)
              .enter()
              .call(addDefinition, aisleData);
          });

          // select.attr("data-active-aisle-position")

          elem.aisle.forEach((point) => {
            let first = point;
            first._parent_x = rackX;
            first._parent_y = rackY;
          });

          graphics.selectAll('text.aisle-name')
            .data(elem.aisle)
            .enter()
            .append('text')
            .attr('x', function (dataPoint) {
              return dataPoint._parent_x;
            })
            .attr('y', function (dataPoint) {
              return dataPoint._parent_y;
            })
            .attr('clip-path', function (dataPoint) {
              return 'url(#' + textToId(dataPoint.name + '-' + dataPoint.bays + '-' + dataPoint.number) + ')';
            })
            .attr('class', function (dataPoint) {
              return 'aisle-name aisle-position-' + dataPoint.position;
            })
            .attr('transform', function (dataPoint) {
              return self._getTextTransform(dataPoint, rack);
            })
            .attr('style', function (dataPoint) {
              if (dataPoint.is_active_aisle) {
                options.style.fill = options.activeFill;
              } else {
                options.style.fill = fill;
              }
              let style = '';
              Object.entries(options.style).forEach(([key, value]) => {
                style += key + ':' + value + ';';
              });
              return style;
            })
            .text(function (dataPoint) {
              return self.translateText(dataPoint.name, 'en_US');
            });
        }
      });
    return this;
  },
  // draws SVG rects based on xml `rack` node

  // * returns `this` for chaining
  drawRacks: function drawRacks(config) {
    // Rack consists of

    // * The Rack
    // * The Aisle (Parent Obj)
    //   * Aisle Name
    //   * Aisle Number
    // * The Endcap
    let options = config;
    let defaults = {
      rackStyle: { fill: '#F9F9F9', stroke: '#CCC', 'stroke-width': '0.5' },
      endcapStyle: { fill: '#F0F0F0', stroke: '#CCC', 'stroke-width': '0.5' }
    };
    options = { ...defaults, ...options };

    let self = this;
    let rackBaseWidth = 7;

    let drawRack = function drawRack(selection) {
      selection.append('rect')
        .attr('x', function (elem) {
          return parseFloat(elem.x) * self.scale;
        })
        .attr('y', function (elem) {
          return parseFloat(elem.y) * self.scale;
        })
        .attr('width', function (data) {
          let elem = data;
          elem._width = parseFloat(elem.length) * self.scale;
          return elem._width;
        })
        .attr('height', function (data) {
          let elem = data;
          let width = Util().defaultNumberTo(elem.width, 1);
          let rackLineWidth = Util().getLineWidth(rackBaseWidth * parseFloat(width), self.scale);
          elem._height = rackLineWidth * self.scale;
          return elem._height - 0.5;// -1 cause thats the stroke width (.5 *2)
        })
        .attr('class', 'rack')
        .attr('id', function (_data, i) {
          return 'rack-' + i;
        });

      Util().updateSelectionStyles(selection, options.rackStyle);
    };

    let drawEndcaps = function drawEndcap(data) {

      if (typeof data.endcap === 'undefined') {
        return;
      }
      let endcaps = data.endcap;
      if (!Array.isArray(endcaps)) {
        endcaps = [endcaps];
      }

      let aisleHeight = parseFloat(select(this).select('rect').attr('height'));
      let width = (0.0025 * self.scale);

      select(this).selectAll('rect.endcap')
        .data(endcaps)
        .enter()
        .append('rect')
        .attr('class', function (elem) {
          return 'endcap endcap-' + elem.number + ' endcap-' + elem.position;
        })
        .attr('x', function (elem) {
          let xco = parseFloat(data.x) * self.scale;
          if (elem.position === 'back') {
            xco += parseFloat(data.length) * self.scale;
          } else {
            xco -= width;
          }
          return xco;
        })
        .attr('data-bays', function (elem) {
          return elem.bays;
        })
        .attr('y', function () {
          let yco = parseFloat(data.y) * self.scale;
          return yco;
        })
        .attr('height', aisleHeight)
        .attr('width', width);

      Util().updateSelectionStyles(select(this).selectAll('rect.endcap'), options.endcapStyle);
    };

    // This method creates classes on the group
    // of the aisle numbers in `aisle-<n>` formate
    // This makes it easy to select aisles with
    // `d3.select`
    let createAisleClasses = function (data) {
      let elem = data;
      if (elem.aisle && !Array.isArray(elem.aisle)) {
        elem.aisle = [elem.aisle];
      }
      let classes = ['aisle'];
      if (elem.aisle) {
        let bays = [];
        elem.aisle.forEach((value) => {
          let aisle = value;
          classes.push('aisle-' + aisle.number);
          bays.push({
            n: aisle.number,
            bays: aisle.bays,
            position: aisle.position
          });
        });

        select(this)
          .attr('class', classes.join(' '))
          .call(function (selection) {
            bays.forEach((value) => {
              let bay = value;
              selection.attr('data-bays-' + bay.n, bay.bays);
              selection.attr('data-bays-position-' + bay.n, bay.position);
            });
          });
      }
    };

    let racks = this.getObjects().rack;
    let dirOffset = -90;

    self._racks = this.svg.selectAll('g')
      .data(racks)
      .enter()
      .append('g')
      .each(createAisleClasses)
      .attr('transform', function (elem) {
        let dir = parseInt(elem.dir, 10) + dirOffset;
        let xco = parseFloat(elem.x) * self.scale;
        let yco = parseFloat(elem.y) * self.scale;
        return 'rotate(' + dir + ',' + xco + ',' + (yco + 0.50) + ')';
      })
      .call(drawRack)
      .each(drawEndcaps);
    return this;
  },

  // * param `Object` options
  // * param `Object` options.style - css object styles
  // * returns `this` for chaining
  drawLabels: function drawLabels(config) {
    let options = config;
    let defaults = {
      style: {
        'font-family': 'sans-serif',
        fill: ' #999999',
        opacity: 0.9,
        'text-anchor': 'middle',
        'stroke-width': 0.1
      }
    };

    options = { ...defaults, ...options };

    let data = this.getObjects().label;
    let scale = this.scale;
    let fontScale = scale / 1100;
    let self = this;

    if (typeof data === 'undefined') {
      return this;
    }

    this.svg.selectAll('g.label-group')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'label-group')
      .each(function (data1) {
        let elem = data1;
        // if there is a pipe, add another row
        let selection = select(this);

        // d.text.forEach(function (t, i) {
        elem.text = self.translateText(elem.text, 'en_US');
        // });

        let labelData = elem.text.split('|');

        let fontSize = elem.fontSize * fontScale;
        options.style['font-size'] = fontSize + 'px';

        let lineSize = fontSize * 1.15;
        selection.selectAll('text.label')
          .data(labelData)
          .enter()
          .append('text')
          .attr('class', 'label')
          .attr('x', function () {
            return parseFloat(elem.x) * scale;
          })
          .attr('y', function (_d1, i) {
            let offsetY = (lineSize * i) - (lineSize * ((labelData.length - 1) / 2));
            return (parseFloat(elem.y) * scale) + offsetY;
          })
          .attr('transform', function () {
            return 'rotate(' + (parseInt(elem.dir, 10) - 90)
            + ',' + parseFloat(elem.x) * scale + ',' + parseFloat(elem.y) * scale + ')';
          })
          .text(function (d1) {
            return d1;
          });

        Util().updateSelectionStyles(selection.selectAll('text.label'), options.style);
      });
    return this;
  },
  // * param `Object` options
  // * param `Object` options.style - css object styles
  // * param `String` options.className - class name - defaults to `restroom`
  // * returns `this` for chaining
  drawRestroom: function drawRestroom(config) {
    let options = config;
    let defaults = {
      style: {
        fill: '#f9f9f9',
        stroke: '#CCCCCC',
        'stroke-width': '1'
      },
      className: 'restroom'
    };

    options = { ...defaults, ...options };

    let restroom = this.getObjects().restroom;

    if (typeof restroom === 'undefined') {
      return this;
    }

    // @todo, add icons (seems unneccessary though)
    let xco = parseFloat(restroom.x) * this.scale;
    let yco = parseFloat(restroom.y) * this.scale;
    let width = parseFloat(restroom.width) * this.scale;
    let height = parseFloat(restroom.height) * this.scale;

    this.svg.append('rect')
      .attr('x', xco)
      .attr('y', yco)
      .attr('width', width)
      .attr('height', height)
      .attr('class', options.className);

    Util().updateSelectionStyles(this.svg, options.style);

    return this;
  },
  // * param `Object` options
  // * param `Object` options.style - css object styles
  drawDoorway: function drawDoorway(config) {
    let options = config;
    let self = this;
    let doors = this.getObjects().doorway;
    let defaults = {
      style: {
        fill: '#333333',
        stroke: '#999999',
        'stroke-width': 1
      }
    };

    options = { ...defaults, ...options };

    if (typeof doors === 'undefined') {
      return this;
    }

    let height = 0.005;
    let scale = this.scale;

    this.svg.selectAll('rect.doorway')
      .data(doors)
      .enter()
      .append('rect')
      .attr('class', 'doorway')
      .attr('x', function (elem) {
        return parseFloat(elem.x) * scale;
      })
      .attr('y', function (elem) {
        return parseFloat(elem.y) * scale;
      })
      .attr('height', function () {
        return height * scale;
      })
      .attr('width', function (elem) {
        return parseFloat(elem.width) * scale;
      })
      .attr('transform', function (elem) {
        let text = [];
        let xco = parseFloat(elem.x) * scale;
        let yco = parseFloat(elem.y) * scale;
        let width = parseFloat(elem.width) * scale;
        height = 0.005 * scale;

        let rotation = (self.rotation[0] === 0) ? 90 : 0;
        let dir = parseInt(elem.dir, 10) + rotation;

        text.push('rotate(' + (dir) + ',' + (xco) + ',' + (yco) + ')');
        text.push('translate(' + (width / -2) + ',' + (height / -2) + ')');

        return text.join(' ');
      });

    Util().updateSelectionStyles(this.svg.selectAll('rect.doorway'), options.style);

    return this;
  },
  // Fat is greater than 0.75
  // Some stores are more squarish (fat)
  // Zoom uses this because you dont want to zoom in on fat stores
  // cause you have no perspective

  // * returns `Boolean`
  isFat: function isFat() {
    return this.buildingHeight / this.scale > 0.75;
  },
  // * `Object` options
  // * `Function` options.transition - D3 transition function to fade out aisle names
  removeAisleNames: function removeAisleNames(config) {

    if (select('g.aisle-text-group').empty()) {
      return;
    }
    let options = config;
    let defaults = {};

    defaults.transition = function (selection) {
      return selection
        .style('opacity', 1)
        .transition()
        .delay(10)
        .style('opacity', 0);
    };

    options = { ...defaults, ...options };

    options.transition(selectAll('g.aisle-text-group'))
      .remove();
  },
  // * `Mixed` marker - D3 Marker object String selector for marker
  // * `Object` options
  // * `Function` options.transition - D3 transition function to fade out the marker
  removeMarker: function removeMarker(pin, config) {
    let marker = pin;
    let options = config;
    if (typeof marker === 'string') {
      marker = selectAll(marker);
    }

    let defaults = {};

    options = { ...defaults, ...options };
    let transition = function (selection) {
      return selection;
    };

    if (options.fade) {
      transition = function (selection) {
        return selection.transition()
          .delay(100)
          .style('opacity', 0);
      };
    }

    transition(marker)
      .remove();
  },
  // resizes the main `SVG Element`, `.storemap-wrapper`, and `.overlay` objects
  // * returns `this` for chaining
  resize: function resizeMap(height, width, _options) {
    let resize = function (selection) {
      selection
        .attr('height', height)
        .attr('width', width);
    };

    select('svg').call(resize);
    select('.storemap-wrapper').call(resize);

    select('.overlay')
      .call(resize)
      .attr('transform', 'transform(' + width + ',' + height + ') rotate(' + (this.rotation[0] * -1) + ')');
    return this;
  },
  // * param `Number` degrees
  // * returns `this` for chaining
  rotate: function rotate(degrees) {
    let self = this;
    let storemap = select('.storemap-wrapper');
    let text = storemap.attr('transform');
    let transform = [];

    if (text) {
      text = Util().parseTransform(text);

      if (text.translate) {
        transform.push('translate(' + text.translate[0] + ',' + text.translate[1] + ')');
      }
    }

    let xco = parseFloat(storemap.attr('width')) / 2;
    let yco = parseFloat(storemap.attr('height')) / 2;

    self.rotation = [degrees, xco, yco];
    transform.push('rotate(' + self.rotation.join(',') + ')');

    storemap.attr('transform', transform.join(' '));
    this._updateOverlay();

    return this;
  },
  // * param `D3 Object` marker
  // * param 'String' size - `small` or anything else for large
  // * `Object` options
  // * `Object` options.ease - 'bounce'
  // * returns `this` for chaining
  scaleMarker: function scaleMarker(marker, size, config) {
    let options = config;
    if (typeof marker === 'undefined') {
      return this;
    }

    let defaults = {
    };

    options = { ...defaults, ...options };

    let transition = function (selection) {
      return selection;
    };

    if (size === 'small') {
      transition(marker.select('#storemarker path')).attr('transform', 'scale(0.5) translate(16,32)');
    } else {
      transition(marker.select('#storemarker path')).attr('transform', 'scale(1)');
    }

    return this;
  },
  // Allows you to zoom in/out to a certain point from the current zoom position

  // * param `String` scale
  // * param `Object` options
  // * param `Number` options.speed - defaults to 500 ms
  // * param `Number` optins.x - x coordinate to zoom to (defaults to current zoom coords)
  // * param `Number` optins.y - y coordinate to zoom to (defaults to current zoom coords)
  // * returns `this` for chaining
  zoom: function zoomInOut(scale, config) {
    let options = config;
    let orientation = this.getOrientation();

    let defaults = {
      speed: 500
    };

    options = { ...defaults, ...options };

    let zoom = this._zoom;
    let svg = this.svg;
    let self = this;
    let svgHeight = parseFloat(this.svgRoot.attr('height'));
    let svgWidth = parseFloat(this.svgRoot.attr('width'));
    let svgCenter = {
      xco: svgWidth / 2, // + gHeight,
      yco: svgHeight / 2
    };

    if (orientation === 'vertical') {
      // switch x and y
      let _x = options.x || options.xData;
      let _y = options.y || options.yData;
      options.xco = _y;
      options.yco = _x;

    }

    function zoomed() {
      let so = zoom.transform.k;
      let to = [zoom.transform.x, zoom.transform.y];
      svg.attr('transform',
        'translate(' + to + ')'
              + 'scale(' + so + ')'
              + 'rotate(' + self.rotation + ')'
      );

      self._zoom.transform.x = to[0];
      self._zoom.transform.y = to[1];
      self._zoom.transform.k = so;
      self.zoomLevel = so;
    }

    let speed = options.speed;
    function interpolateZoom(itranslate, iscale) {
      return d3Transition().duration(speed).tween('zoom', function () {
        let to = [zoom.transform.x, zoom.transform.y];
        let iTranslate = interpolate(to, itranslate);
        let iScale = interpolate(zoom.transform.k, iscale);
        return function (it) {
          zoom.transform.k = iScale(it);
          zoom.transform.x = iTranslate(it)[0];
          zoom.transform.y = iTranslate(it)[1];

          // Setting Identity updates zoom event
          zoomIdentity.k = zoom.transform.k;
          zoomIdentity.x = zoom.transform.x;
          zoomIdentity.y = zoom.transform.y;
          zoomed();
        };
      });
    }

    let width = svgWidth;
    let height = svgHeight;

    let targetZoom = scale;
    let center = [width / 2, height / 2];
    let extent = zoom.scaleExtent();

    let translate0 = [];
    let line = [];
    let view = { xco: zoom.transform.x, yco: zoom.transform.y, scale: zoom.transform.k };

    if (targetZoom < extent[0] || targetZoom > extent[1]) { return false; }

    translate0 = [(center[0] - view.xco) / view.scale, (center[1] - view.yco) / view.scale];
    view.scale = targetZoom;
    line = [translate0[0] * view.scale + view.xco, translate0[1] * view.scale + view.yco];

    view.xco += (center[0] - line[0]);
    view.yco += center[1] - line[1];

    interpolateZoom([view.xco, view.yco], view.scale);
    return this;
  },

  // * `Object` options
  // * `Object` options.height
  // * `Object` options.width
  _init: function _init(config) {
    let options = config;
    options = options || {};

    let defaults = {
      width: this.width,
      height: this.height
    };

    options = { ...defaults, ...options };

    this.svgRoot = select(this.wrapper).append('svg')
      .attr('width', options.width)
      .attr('height', options.height)
      .attr('title', 'Store layout for ' + this.json.version.storeNumber['#text']);

    this.svg = this.svgRoot
      .append('g')
      .attr('class', 'outer')
      .append('g')
      .attr('class', 'storemap-wrapper')
      .attr('data-testid', 'test-attribute')
      .attr('width', this.buildingWidth)
      .attr('height', this.buildingHeight);

    this.overlay = this.svg.append('rect')
      .attr('class', 'overlay')
      .attr('fill', 'none')
      .attr('width', this.buildingWidth)
      .attr('height', this.buildingHeight);

    this._updateOverlay(options.height, options.width);

    this._bindEvents();
    this.defs = this.svg.append('svg:defs');
  },
  // Called from `._init` to expose events to listen to via `on`.
  // `touchstart`, `touch` ,`touchend`, `zoom`, `end`, `start`, `zoomin`, `zoomout`

  // ***Note that `zoomin` and `zoomout` are triggered by the buttons not pinches.***
  _bindEvents: function bindEvents() {

    let storemapWrapper = this.svg;
    let self = this;

    let dispatch = d3Dispatch('touchstart', 'touch', 'touchend', 'zoom', 'end', 'start', 'zoomin', 'zoomout');
    // `on` relies on dispatch
    this._dispatch = dispatch;
    // var svgHeight =
    let eventsHandlers = {
      zoom: function zoom(event) {
        dispatch.call('zoom', event);
        storemapWrapper.attr(
          'transform', event.transform
          + 'rotate(' + self.rotation.join(',') + ')'
        );

      },
      end: function end(event) {
        self._zoom.transform = event.transform;
        self.zoomLevel = event.transform.k;
        dispatch.call('end', event);
      },
      start: function start(event) {
        dispatch.call('start', event);
      },
      touchstart: function touchStart(event) {
        dispatch.call('touchstart', pointer(event));
      },
      touchmove: function touchMove(event) {
        dispatch.call('touch', pointer(event));
      },
      touchend: function touchEnd(event) {
        dispatch.call('touchend', pointer(event));
      }
    };

    this._zoom = d3Zoom().scaleExtent([1, 8])
      .on('zoom', (event) => eventsHandlers.zoom(event))
      .on('end', (event) => eventsHandlers.end(event))
      .on('start', (event) => eventsHandlers.start(event));

    this.svgRoot.select('g.outer')
      .call(this._zoom);

    select('body')
      .on('touchstart', (event) => eventsHandlers.touchstart(event))
      .on('touchmove', (event) => eventsHandlers.touchmove(event))
      .on('touchend', (event) => eventsHandlers.touchend(event));
  },
  _unbindEvents: function unbindEvents() {
    select('body')
      .on('touchstart', null)
      .on('touch', null)
      .on('touchend', null);

    select(this.wrapper)
      .on('zoom', null)
      .on('end', null)
      .on('start', null);
  },
  // Figures out the transform values for aisle numbers.
  // This is kludgy and should probably be refactored to be more dynamic

  // * param `Object` aisle
  // * param `Object` rack
  _getAisleNumbersTransform: function _getAisleNumbersTransform(aisle, rack) {

    let self = this;
    let rackX = parseFloat(rack.x) * self.scale;
    let rackY = parseFloat(rack.y) * self.scale;
    let rackPos = (rack.dir === '270') ? 'horizontal' : 'vertical';
    let layoutPosition = self.getOrientation();
    let text = [];

    if (layoutPosition === 'horizontal') {

      if (rackPos === 'horizontal') {
        if (aisle.position === 'right') {
          text.push('translate(5,4)');
        } else {
          text.push('translate(5,-4)');
        }
        text.push('rotate(180,' + (rackX) + ',' + (rackY) + ')');
      } else {
        if (aisle.position === 'right') {
          text.push('translate(0,-5)');
        } else {
          text.push('translate(0,5)');
        }
        text.push('rotate(90,' + rackX + ',' + rackY + ')');
      }
    } else if (rackPos === 'horizontal') {

      if (aisle.position === 'right') {
        text.push('translate(0,3.7)');
      } else {
        text.push('translate(0,-5)');
      }
      text.push('rotate(90,' + rackX + ',' + rackY + ')');

    } else if (aisle.position === 'right') {
      text.push('translate(0,-1)');
    } else {
      text.push('translate(0,7)');
    }

    return text.join(' ');
  },
  // figures out the transform values for the aisle text
  // This is kludgy and should probably be refactored to be more dynamic

  // * param `Object` aisle
  // * param `Object` rack
  _getTextTransform: function _getTextTransform(aisle, rack) {

    let self = this;
    let rackX = parseFloat(rack.x) * self.scale;
    let rackY = parseFloat(rack.y) * self.scale;
    let rackPos = (rack.dir === '270') ? 'horizontal' : 'vertical';
    let layoutPosition = self.getOrientation();

    let text = [];
    if (layoutPosition === 'horizontal') {

      if (rackPos === 'horizontal') {
        if (aisle.position === 'right') {
          text.push('translate(' + rack._width + ',4)');
        } else {
          text.push('translate(' + rack._width + ',-4)');
        }
        text.push('rotate(180,' + (rackX) + ',' + (rackY) + ')');
      } else if (aisle.position === 'right') {
        text.push('translate(4,-2)');
      } else {
        text.push('translate(4,7)');
      }
    } else if (rackPos === 'horizontal') {

      if (aisle.position === 'right') {
        text.push('translate(4,7)');
      } else {
        text.push('translate(4,-2)');
      }
    } else if (aisle.position === 'right') {
      text.push('translate(5,-1)');
    } else {
      text.push('translate(5,7)');
    }

    return text.join(' ');
  },
  // update the rect on top of the svg.
  // this rect is required so that you can properly pinch/drag without issues
  _updateOverlay: function _updateOverlay() {

    let rotation = this.rotation;
    if (rotation[0] !== 0) {
      let width = this.svg.attr('width');
      let height = this.svg.attr('height');
      if (height > width) {
        width = height;
      }
      this.overlay.attr('height', width);
      this.overlay.attr('width', width);
    }

    this.overlay.style('stroke', 'none');
  }
};
export { MapGenerator };
