import {
  bool as boolType,
  string as stringType
} from '@thd-nucleus/data-sources';

export const LIST_SHAPE = {
  id: stringType(),
  name: stringType(),
  listAccessType: stringType()
};

export const getValidationError = ({ value, externalError }) => {
  const validCharacters = /^[ [\]{}|a-z|A-Z|!@#$%^&*()0-9-_+,.?"':;\\]+$/;
  const blank = !(value || '').replace(/\s/g, '');
  let isValid = validCharacters.test(value);
  let error = externalError;
  if (!isValid) {
    error = 'Invalid List Name: Only a-z, 0-9, ! @ # $ % ^ & * ( ) - _ + , . ? " \' : ; \\ are allowed.';
  } else if (blank) {
    error = 'List name cannot be empty';
  }
  return { error };
};

export const isNameFieldValid = ({ value, externalError }) => {
  const { error } = getValidationError({ value, externalError });
  return !!value && !error;
};

export const triggerAnalytics = (event, payload) => {
  LIFE_CYCLE_EVENT_BUS.trigger(event, payload);
};
