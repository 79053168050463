import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from '../private/components/button/ButtonBase';
import useEnhancedEffect from '../private/hooks/useEnhancedEffect';
import useForkRef from '../private/hooks/useForkRef';

const ListItemButton = React.forwardRef((props, ref) => {

  const {
    autoFocus = false,
    component = 'div',
    children,
    disableGutters = false,
    selected = false,
    ...other
  } = props;

  const listItemRef = React.useRef(null);
  useEnhancedEffect(() => {
    if (autoFocus) {
      if (listItemRef.current) {
        listItemRef.current.focus();
      }
    }
  }, [autoFocus]);

  const classes = classNames('sui-flex sui-grow sui-justify-start sui-items-center sui-relative suitext-left sui-py-2', {
    'sui-px-4': !disableGutters,
    'sui-bg-strong hover:sui-bg-strong/[.75] focus-visible:sui-bg-focus': selected,
    'hover:sui-bg-subtle focus-visible:sui-bg-focus': !selected
  });

  const handleRef = useForkRef(listItemRef, ref);

  return (
    <ButtonBase
      ref={handleRef}
      href={other.href || other.to}
      component={(other.href || other.to) && component === 'div' ? 'a' : component}
      className={classes}
      {...other}
    >
      {children}
    </ButtonBase>
  );
});

ListItemButton.displayName = 'ListItemButton';

ListItemButton.propTypes = {
  /**
   * If `true`, the list item is focused during the first mount.
   * Focus will also be triggered if the value changes from false to true.
   * @default false
   */
  autoFocus: PropTypes.bool,
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the left and right padding is removed.
   * @default false
   */
  disableGutters: PropTypes.bool,
  /**
   * @ignore
   */
  href: PropTypes.string,
  /**
   * Use to apply selected styling.
   * @default false
   */
  selected: PropTypes.bool
};

ListItemButton.defaultProps = {};

export { ListItemButton };
