import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { useImpression } from '@thd-olt-component-react/impression';
import { initSurvey } from '../qualtricsHelper';
import './search-feedback.scss';

const SearchFeedback = (props) => {
  const { channel } = useContext(ExperienceContext);
  const { type, impressionID } = props;

  const { ref, clickID } = useImpression({
    data: {
      id: impressionID || 'SearchFeedbackID',
      name: 'SearchFeedback',
      component: 'SearchFeedback',
      position: 1,
      type: 'content'
    }
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('search-feedback.ready');
    initSurvey();
  }, []);

  if (channel === 'mobile') return null;

  const rowClasses = classNames({
    collectionFeedbackWrapper: type === 'collection'
  });

  const columnClasses = classNames({
    'article-feedback-wrapper': type === 'articles',
    'search-feedback-wrapper': type !== 'articles'
  });

  return (
    <div
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <Row
        className={rowClasses}
        data-component="SearchFeedback"
      >
        <Col className={columnClasses}>
          <div id={`${type}Feedback`} />
        </Col>
      </Row>
    </div>
  );
};

SearchFeedback.displayName = 'SearchFeedback';

SearchFeedback.propTypes = {
  type: PropTypes.oneOf([
    'articles',
    'search',
    'collection'
  ]),
  impressionID: PropTypes.string
};

SearchFeedback.defaultProps = {
  type: 'search',
  impressionID: null
};

export { SearchFeedback };
